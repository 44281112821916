@if (isLoading) {
  <div class="overlay" @container>
    <div id="loading-component" class="spinner">
      <div class="loading-flex">
        <img src="/assets/images/spinner.gif" width="50" alt="ConEdison Loading" />
        <div class="spinner-text font-weight-bold" [@row]="messages"><h4>{{messageList[messageList?.length - 1]}}</h4></div>
      </div>
    </div>
  </div>
}
