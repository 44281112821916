import { Injectable, inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private platform = inject(Platform);

  private positionWatch: any;
  private promptEvent: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    this.promptEvent.promote();
  }

  public async enableGeolocation(): Promise<GeolocationPosition | GeolocationPositionError> {
    const options = { enableHighAccuracy: true };
    return this.handlePermission("geolocation").then(state => {
      if (state === 'prompt' || state === 'granted') {
        return this.getCurrentLocation(options);
      } else {
        return null;
      } 
    })
  }
  public getCurrentLocation(options) {
    return new Promise<GeolocationPosition | GeolocationPositionError>((resolve,reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos: GeolocationPosition) => {resolve(pos);}, 
        (err: GeolocationPositionError) => {reject(err)},
        options
      );
    }); 
  }

  public handlePermission(perm: PermissionName) {
    return navigator.permissions.query({
      name: perm
    }).then(result => result.state)
    .catch(err => `Permission not available or does not exist: ${JSON.stringify(err)}`)  
  }
}
