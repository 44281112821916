import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { flushEndpoints } from 'src/environments/flush-endpoints';
import { Store } from '@ngxs/store';
import { DeleteUserInfo } from '../app-state/actions/user-info.actions';
import { BannerService, HttpErrorBanner } from '../services/banner/banner.service';
import { LoggingService } from '../services/logging/logging.service';

@Injectable()
export class CrewCodeInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private store = inject(Store);
  private bannerService = inject(BannerService);
  private logger = inject(LoggingService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((evt) => {
        // Exclude the email Url paths from checking if Crew code is assigned to users
        const isEmailUrlPath = window.location.href.includes('/flush-job/') || window.location.href.includes('/flush-request/');
        if (!isEmailUrlPath && evt instanceof HttpResponse && evt.url.endsWith(flushEndpoints.users)) {
          // Check if the crew code is not assigned to the signed in user
          const role = evt.body.user.flushRoleType;
          if (evt.body && !/^[A-Z]_[A-Z]+_([0-9]+).*/.test(evt.body.user.crewCode)) {
            // If user is Flush User or Contractor, perform the check. If unprovisioned or a supervisor, do not perform check
            if ( role != 'cc' && role != 'CA' && role != '' ) {
              this.store.dispatch(DeleteUserInfo);
              this.bannerService.createBanner('error', {
                header: 'Can not connect to WMS',
                body: [`Sorry, we are unable to log you into ERA. Please go to the Support Page to learn how to request access to ERA.`],
                httpStatus: evt.status,
                originalError: `User ${evt.body?.email} does not have a Crew Code assigned.`,
                originalErrorName: 'Null crew code',
                originalUrl: evt.url,
                originalServerMessage: '',
              });
              this.logger.logEvent({
                name: 'User information invalid for login'
              }, {
                action: 'POST /users result',
                eventDate: new Date(),
                userName: evt.body.user.name,
                userDepartment:  evt.body.user.department,
                userEmail: evt.body.user.email,
                userFlushRoleType: evt.body.user.flushRoleType,
                userCrewCode: evt.body.user.crewCode,
                refUri: document.URL
              })
              this.router.navigate(['sign-in'], {
                queryParams: { error: 'no_crew_code_assigned' },
              });
            }
          }
        }
      })
    );
  }
}
