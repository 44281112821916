import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnChanges, OnDestroy, HostListener, DoCheck, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { BaseComponent } from '../base/base.component';
import * as _ from 'lodash';
import { GridService } from 'src/app/services/grid/grid.service';
import { StylerService } from 'src/app/services/styler/styler.service';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';

export interface Action {
  caption: string;
  type: string;
  header?: string;
  caption2?: string;
  action?: string;
  content?: string;
}
@Component({
  selector: 'app-flush-grid',
  templateUrl: './flush-grid.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FlushGridComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  private grid = inject(GridService);
  private styler: StylerService;
  private formBuilder = inject(FormBuilder);
  private internet = inject(InternetCheckerService);

  gridData: any[];
  gridColumns: any[];
  checkboxFormGroup: FormGroup;
  selections: any[] = [];
  allowCheckboxEmit = false;
  isOnline;
  @Input() data: any[];
  @Input() page: string = '';
  @Input() displayActionsHeader: false;
  @Input() columns: any[];
  @Input() actions: Action[] = [{ caption: '', type: 'data' }];
  @Input() isSelectable = true;
  @Input() isFlushGridSimple = false;
  @Input() rowKey = ''; // primary key that identifies each row
  @Output() actionClicked = new EventEmitter();
  @Input() allowVerticalDataTable: boolean = false;
  @Input() defaultCheckValue = false;
  @Input() removeCheckboxControlAt = -1;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const target = event.target;
    this.arrangeDataTable(target.innerWidth);
  }

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

    this.checkboxFormGroup = this.formBuilder.group({
      checkboxArray: this.formBuilder.array([]),
    });
  }
  get checkboxes(): FormArray {
    return this.checkboxFormGroup.get('checkboxArray') as FormArray;
  }
  //vertical or horizontal based on width
  arrangeDataTable(width) {
    if (width < 770 && this.allowVerticalDataTable) {
      /*
        Transposes data
        DO NOT CHANGE this.data or this.columns, these are our single source of truth (unmodified) data and column sets. 
      */
      this.gridData = this.transposeArrayOfObjects(this.data, this.columns);
      this.gridColumns = [
        { field: 'Title', title: 'Title' },
        { field: 'Value', title: 'Value' },
      ];
    } else {
      // Set data and columns to entered values from parent
      this.gridData = this.data;
      this.gridColumns = this.columns;
    }
  }

  ngOnInit(): void {
    this.arrangeDataTable(window.innerWidth);
    this.flushSubscriptions.push(
      this.grid.$clearSelections.subscribe((clear) => {
        if (clear) {
          this.actionClicked.emit({
            action: 'remove',
            value: _.cloneDeep(this.selections),
          });
          this.selections = [];
        }
      })
    );
    if (this.actions.some((o) => o.type === 'checkbox')) {
      this.data.forEach((val) => {
        this.checkboxes.push(this.createCheckboxControl(val?.isCustomerOwned));
      });
      this.flushSubscriptions.push(
        this.checkboxFormGroup.valueChanges.subscribe((val) => {
          this.actionClicked.emit({
            action: 'customerOwned',
            value: val.checkboxArray.map((val) => (typeof val === 'string' ? false : true)),
          });
        })
      );
    }
    this.internet.getStatus.subscribe((isOnline) => {
      this.isOnline = isOnline;
    })
  }
  ngOnChanges() {
    // Check 'data' array that feeds table
    this.arrangeDataTable(window.innerWidth);
  }
  ngDoCheck() {
    // Monitor changes to @Input() data that Angular might not catch on its own
    // Note: This is an expensive cycle to use, as this hook is called very frequently
    if (this.actions.some((o) => o.type === 'checkbox')) {
      if (this.checkboxFormGroup != undefined) {
        const faLength = this.checkboxes.length;
        if (this.data.length > faLength && faLength > 0) {
          // New item added, so just insert new formcontrol into formarray
          this.checkboxes.push(this.createCheckboxControl());
          this.allowCheckboxEmit = false;
        } else if (this.data.length < faLength) {
          // We removed an item, so remove formcontrol at index (passed in from parent component)
          this.checkboxes.removeAt(this.removeCheckboxControlAt);
          this.allowCheckboxEmit = false;
        }
        // Conditions not met to add/delete
        else {
        }
      }
    }
  }
  createCheckboxControl(val = false) {
    return new FormControl(val ? true : '');
  }
  getCheckboxFormControl(row) {
    if ((this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row) != undefined) {
      return (this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row);
    } else {
      (this.checkboxFormGroup.get('checkboxArray') as FormArray).push(this.createCheckboxControl());
      return (this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row);
    }
  }
  evauluateBoolean(row) {
    if ((this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row) != undefined) {
      return typeof (this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row).value === 'string'
        ? false
        : true;
    } else {
      (this.checkboxFormGroup.get('checkboxArray') as FormArray).push(this.createCheckboxControl());
      return typeof (this.checkboxFormGroup.get('checkboxArray') as FormArray).at(row).value === 'string'
        ? false
        : true;
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  rowCallback = (row: RowClassArgs) => {
    if (!this.isSelectable) {
      return 'no-highlight';
    }    
    if (this.selections.filter((selection) => selection.key === row.dataItem[this.rowKey]).length > 0) {
      return 'highlight';
    } else {
      return 'no-highlight';
    }
  };

  transposeArrayOfObjects(data : Array<any>, columns : Array<any>) : Array<any> {
    let tempArray = [];

    if (columns && data) {
      for (var dIndex = 0; dIndex < data.length; dIndex++) {
        let currentItem = data[dIndex];
        let currentLength = tempArray.length;
        for (let colIndex = 0; colIndex < columns.length; colIndex++) {
          const { field, title, actionType = '', action = '' } = columns[colIndex];
          const tempIndex = currentLength + colIndex;
        
          tempArray[tempIndex] = {
            Title: title,
            Value: currentItem[field],
            ActionType: actionType,
            Action: action,
            //Key names are case-sensitive. Changes will cause the map functionality to fail.
            structureId: currentItem[field],   
            longitude: currentItem?.longitude,
            latitude: currentItem?.latitude,
          };
        }
        
        if (dIndex + 1 < data.length) {
          const { length: currentLength } = tempArray;
          tempArray[currentLength] = {
            Title: '',
            Value: '',
            ActionType: '',
            Action: '',
            structureId: '',
            latitude: '',
            longitude: '',
          };
        }
      }
    }
    return tempArray;
  }

  handleAnchorClick(rowVal, dataItem) {
    // Handle selections being made on the table
    if (this.selections.filter((row) => row.key === dataItem[this.rowKey]).length < 1) {
      this.selections.push({ row: rowVal, key: dataItem[this.rowKey] });
      this.actionClicked.emit({ action: 'add', value: dataItem });
    } else {
      this.selections.splice(
        this.selections.findIndex((row) => row.key === dataItem[this.rowKey]),
        1
      );
      this.actionClicked.emit({ action: 'delete', value: dataItem });
    }
  }

  customAnchorClick(dataItem: any, action: string) {
    this.actionClicked.emit({ action: action, value: dataItem });
  }
}
