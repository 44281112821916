import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
/*
 * Formats timestamp on the fly
 *
 * Usage:
 *   date | timestamp:<format>
 *
 * <format> is a momentJS string format
 * Example:
 *   {{ moment().format() | timestamp:"MM-DD-YYYY" }}
 *
 */
@Pipe({ name: 'timestamp' })
export class TimestampPipe implements PipeTransform {
  transform(timestamp: string, format?: string): string {
    if (timestamp == undefined || timestamp.length < 2) {
      return '';
    }
    return format.length > 0 ? moment(timestamp).format(format) : moment(timestamp).format();
  }
}
