import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy, inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { BaseService } from 'src/app/services/base/base.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { filter } from 'rxjs/operators';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
@Component({
  selector: 'app-start-job',
  templateUrl: './start-job.component.html',
})
export class StartJobComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private service = inject(BaseService);
  private styler: StylerService;
  private formValidationService = inject(FormValidationService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    const router = this.router;
    this.styler = styler;

    this.flushSubscriptions.push(
      this.styler.isPhotoReview.subscribe((val) => {
        this.isPhotoReview = val;
      }),
      router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((e: ActivationEnd) => {
        if (e.snapshot.routeConfig.path === 'photos') {
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = true;
          this.isShowingReviewAndSubmit = false;
          this.formValidationService.$flushInformationValid.next(true);
          this.showing = 'photos';
        } else if (e.snapshot.routeConfig.path === 'job-details') {
          this.isShowingJobDetails = true;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = false;
          this.showing = 'job-details';
        } else if (e.snapshot.routeConfig.path === 'review') {
          this.isShowingReviewAndSubmit = true;
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = false;
          this.formValidationService.$flushInformationValid.next(true);
          this.showing = 'review';
        }
      })
    );
  }
  isSummary = false;
  isPhotoReview = false;
  isShowingJobDetails = true;
  isShowingStructurePhotos = false;
  isShowingReviewAndSubmit = false;
  showing = 'job-details';
  isMobile = false;
  showNavItems = false;
  sideNavHeight = 0;
  $router: Subscription;
  isJobDetailsValid = false;
  isStructurePhotosValid = false;
  showingConfirmSubmit = false;
  showingSubmitResult = false;
  submitResult = false;
  wrNumber: string;
  sourceWrNumber: string;
  workRequest: WorkRequestDetail;
  @ViewChild('sideNav') sideNav: ElementRef;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768 ? true : false;
    this.getWorkRequestDetail$.subscribe((wr) => {  
      this.wrNumber = wr.workRequestNo;
      this.sourceWrNumber = wr.sourceWrNumber;
      this.workRequest = wr;
     });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  handleModalClose() {}
  submitJob() {}
  closeJob() {}
}
