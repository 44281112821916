import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LeadsAndCrewCodes } from 'src/app/interfaces/leads-and-crew-codes';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { BaseService } from '../base/base.service';

export interface CloseTabItem {
  appointmentId?: string;
  wrNo: string;
  sourceWrNumber: string;
  crewCode: string;
  address: string;
  structureId: string;
  priorityLevel: string;
  status: string;
  itemType: string;
  crewLead: string;
  workRequest: WorkRequest;
}

@Injectable({
  providedIn: 'root'
})
export class SupervisorHubService {
  private baseService = inject(BaseService);


  closeTabItem: BehaviorSubject<CloseTabItem> = new BehaviorSubject<CloseTabItem>(null);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }

  getOverrideRequests() {
    return this.baseService.getOverrides();
  }

  getLeadNamesAndCrewCodes(appointmentID): Observable<LeadsAndCrewCodes[]> {
    return this.baseService.getLeadNamesAndCrewCodes(appointmentID);
  }

  putApproveOverride(appointmentID, crewCode): Observable<any> {
    return this.baseService.putApproveOverride(appointmentID, crewCode);
  }

  putChangeCrew(appointmentID, crewCode): Observable<any> {
    return this.baseService.putChangeCrew(appointmentID, crewCode);
  }

  putRejectOverride(appointmentID, rejectReason): Observable<any> {
    return this.baseService.putRejectOverride(appointmentID, rejectReason);
  }

  putCloseAppointment(appointmentID): Observable<any> {
    return this.baseService.putCloseAppointment(appointmentID);
  }
}
