<div class="ftux-page">
  <div class="carousel-wrapper-ftux">
    <div #slider id="slider" class="slider">
      @for (slide of slides; track slide; let i = $index) {
        <section class="slide" [ngClass]="{hide: i != currentSlide}" (swipeleft)="onSwipeLeft($event)" (swiperight)="onSwipeRight($event)">
          <h2 class="slide-heading">{{ slide.heading }}</h2>
          <div class="slide-img">
            <img [src]="slide.src" />
          </div>
          <div class="slide-description"><p>{{ slide.description }}</p></div>
        </section>
      }
      <i class="icon icon-arrow left" [ngClass]="{'disabled': (currentSlide - 1) < 0 }" (click)="showSlide(currentSlide-1)" role="button"></i>
      <i class="icon icon-arrow right" [ngClass]="{'disabled': !((currentSlide + 1) < slides.length)}" (click)="showSlide(currentSlide+1)" role="button"></i>
    </div>
    <div class="carousel-dot-container">
      @for (dot of slides; track dot; let i = $index) {
        <span class="dot" [ngClass]="{'dot-active': currentSlide === i}"
        (click)="showSlide(i)"></span>
      }
    </div>
    <!-- <i class="control icon icon-arrow prev-control" (click)="showSlide(i)"></i>
    <i class="control icon icon-arrow next-control" (click)="showSlide(i)"></i> -->
    <ce-button
      type="primary"
      display="stack"
      styleClass="skip-button"
      size="large"
      [label]="currentSlide < slides?.length - 1 ? 'Skip' : 'Next'"
      (clickEvent)="skipFTUX()">
    </ce-button>
  </div>
</div>