<div class="container-fluid form-group" [ngStyle]="{'padding-top': '1rem'}">
  <app-banner [(banner)]="banner$">
    @if ((banner$ | async)?.details?.header === config.DASHBOARD_ERROR_MESSAGES.NOT_PROVISIONED.HEADER) {
      <a
        class="table-links"
        (click)="openLinkInNewTab('https://consolidatededison.sharepoint.com/sites/EO-Flush/Shared%20Documents/General/09_Transition%20Material/Troubleshooting/WMS%20Provisioning%20Set%20Up.pdf')">(Please
      refer to this document)</a>
    }
  </app-banner>
  @if (!newRequest) {
    <div class="requests-wrapper">
      <div class="d-flex justify-content-between">
        <h3 class="requests-my-requests-header">My Requests</h3>
        <i class="request-refresh icon icon-refresh" (click)="refreshDashboard($event);"></i>
      </div>
      <div class="row justify-content-center align-items-baseline">
        <div class="col-lg-7 col-12">
          @if ((isConstructionCrew && this.topCrewTracker[0].isActive)) {
            <p>
              To request a flush, select a work component from below
            </p>
          }
          @if (isEnvironmentalOperations && topTracker && topTracker.length > 0 && topTracker[0].isActive) {
            <p>
              To complete work in ERA, select a flush WR below
              <br>Can't find a job? <strong>Note:</strong> Jobs that are closed out from WMS will not appear in ERA.
            </p>
          }
        </div>
        <ng-container *ngTemplateOutlet="searchField"></ng-container>
      </div>
      @if (flushData | async; as flushDataEntities) {
        <div>
          <div class="row ">
            @if (isEnvironmentalOperations) {
              <div>
                <div class="row">
                  <div class="col">
                    <app-top-tracker [trackerArr]="topTracker" isDashboard="true"
                    (trackerClicked)="onTrackerClicked($event)"></app-top-tracker>
                  </div>
                </div>
                <!-- My jobs  -->
                @if (this.topTracker[0].isActive) {
                  <div>
                    @if (flushDataEntities?.entities?.length !== 0 && flushDataEntities?.entities) {
                      <div>
                        <div class="display-panel-view">
                          <app-flush-crew-panel-bar  [userTypeRole]="userRoleType" (structureClicked)="onStructureClicked($event)" [data]="flushDataEntities.entities"></app-flush-crew-panel-bar>
                        </div>
                        <div class="display-grid-view">
                          <app-flush-grid [data]="flushDataEntities.entities" (actionClicked)="onOpen($event)"
                          [displayActionsHeader]="true" [columns]="flushEoColumns" [isSelectable]="false"></app-flush-grid>
                        </div>
                      </div>
                    } @else {
                      <div>
                        <div class="row p-2" style="justify-content: center;">
                          <div class="col-lg-2 col-12" style="align-self: center;text-align: -webkit-center;">
                            <img class="icon" src="assets/icons/glass-glare-emoji.svg" alt="con ed">
                          </div>
                          <div class="col-lg-8 col-12">
                            <div class="row">
                              <div class="col-12">
                                @if (isConstructionCrew) {
                                  <h1 class="mb-0 center-text-lg">Oops! It looks like you don't have any jobs submitted.</h1>
                                }
                                @if (isEnvironmentalOperations) {
                                  <h1 class="mb-0 center-text-lg">Oops! It looks like you don't have any jobs assigned to you.</h1>
                                }
                              </div>
                              <div class="col-12 center-text-lg">
                                @if (isConstructionCrew) {
                                  <p>
                                    If you're supposed to have a submitted job, refresh the page. If that doesn't work, please create a new flush request above.
                                  </p>
                                }
                                @if (isEnvironmentalOperations) {
                                  <p>
                                    If you're supposed to have a job assigned to you, refresh the page. If that doesn't work, please search by flush WR above.
                                  </p>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
                <!-- My History  -->
                @if (this.topTracker[1].isActive) {
                  <div>
                    <ng-container *ngTemplateOutlet="requestHistory"></ng-container>
                  </div>
                }
              </div>
            }
            @if (isConstructionCrew) {
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <app-top-tracker [trackerArr]="topCrewTracker" isDashboard="true"
                    (trackerClicked)="onTrackerClicked($event)"></app-top-tracker>
                  </div>
                </div>
                <!-- My Requests  -->
                @if (this.topCrewTracker[0].isActive) {
                  <div>
                    @if (flushDataEntities?.entities?.length !== 0 && flushDataEntities?.entities) {
                      <div>
                        <div class="display-panel-view col">
                          <app-construction-crew-panel-bar (structureClicked)="onStructureClicked($event)" [data]="flushDataEntities.entities"></app-construction-crew-panel-bar>
                        </div>
                        <div class="display-grid-view col">
                          <app-flush-grid [data]="flushDataEntities.entities" [displayActionsHeader]="true"
                            [actions]="[{caption: '', text: '', type: 'data', caption2: ''}]"
                            [columns]="flushConstructionCrewColumns" (actionClicked)="onOpen($event)" [isSelectable]="false">
                          </app-flush-grid>
                        </div>
                      </div>
                    } @else {
                      <div>
                        <div class="row p-2" style="justify-content: center;">
                          <div class="col-lg-2 col-12" style="align-self: center;text-align: -webkit-center;">
                            <img class="icon" src="assets/icons/glass-glare-emoji.svg" alt="con ed">
                          </div>
                          <div class="col-lg-8 col-12">
                            <div class="row">
                              <div class="col-12">
                                @if (isConstructionCrew) {
                                  <h1 class="mb-0 center-text-lg">Oops! It looks like you don't have any jobs submitted.</h1>
                                }
                                @if (isEnvironmentalOperations) {
                                  <h1 class="mb-0 center-text-lg">Oops! It looks like you don't have any jobs assigned to you.</h1>
                                }
                              </div>
                              <div class="col-12 center-text-lg">
                                @if (isConstructionCrew) {
                                  <p>
                                    If you're supposed to have a submitted job, refresh the page. If that doesn't work, please create a new flush request above.
                                  </p>
                                }
                                @if (isEnvironmentalOperations) {
                                  <p>
                                    If you're supposed to have a job assigned to you, refresh the page. If that doesn't work, please search by flush WR above.
                                  </p>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
                @if (this.topCrewTracker[1].isActive) {
                  <div>
                    <ng-container *ngTemplateOutlet="requestHistory"></ng-container>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
</div>

<ng-template #requestHistory>
  @if (requestHistoryData | async; as requestHistoryDataEntities) {
    <div>
      @if (requestHistoryDataEntities?.entities?.length !== 0 && requestHistoryDataEntities?.entities) {
        <div>
          <div class="row">
            <div class="display-panel-view col">
              <app-construction-crew-panel-bar [userTypeRole]="userRoleType"  (structureClicked)="onStructureClicked($event)"
              [data]="requestHistoryDataEntities.entities"></app-construction-crew-panel-bar>
            </div>
            <div class="display-grid-view col">
              <app-flush-grid [data]="requestHistoryDataEntities.entities" [displayActionsHeader]="true"
                [actions]="[{caption: '', text: '', type: 'data', caption2: ''}]"
                [columns]="isConstructionCrew ? flushConstructionCrewColumns : flushEoColumns"
                (actionClicked)="onOpen($event)" [isSelectable]="false">
              </app-flush-grid>
            </div>
          </div>
          <div>
            <ce-pager [type]="'input'" [pageSize]="pageSize" [skip]="skip" [total]="totalRows" [buttonCount]="buttonCount"
              [pageSizeValues]="pageSizeValues" [pageSizeArray]="pageSizeArray" [prevNext]="prevNext" [info]="info"
            (pageChange)="pageChange($event)"> </ce-pager>
          </div>
        </div>
      } @else {
        <div>
          <div class="row p-2" style="justify-content: center;">
            <div class="col-lg-2 col-12" style="align-self: center;text-align: -webkit-center;">
              <img class="icon" src="assets/icons/glass-glare-emoji.svg" alt="con ed">
            </div>
            <div class="col-lg-8 col-12">
              <div class="row">
                <div class="col-12">
                  <h1 class="mb-0 center-text-lg">Oops! It looks like you don't have any jobs in your history.</h1>
                </div>
                <div class="col-12 center-text-lg">
                  <p>If you're supposed to have a job, refresh the page.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</ng-template>


<ng-template #searchField>
  <!-- TO DO: make the wr search input functional, on submit showbutton -->
  <div class="col-lg-5 col-12 create-wr-search-wrapper" [formGroup]="dashboardFormGroup">
    @if ((isConstructionCrew || isEnvironmentalOperations) && !showCreateTicketInput) {
      <ce-button class="mt-2"
        size="large" type="primary" [label]="isEnvironmentalOperations ? 'Search by Flush WR' : 'Create Request by WR'"
        icon="icon-commercial" iconDirection="left" (clickEvent)="showCreateTicketInput = !showCreateTicketInput">
      </ce-button>
    }
    @if (showCreateTicketInput) {
      <div class="col-auto search-form-field">
        <ce-input size="large" label="Search by WR"
          [showError]="dashboardFormGroup.get('createWR').invalid && dashboardFormGroup.get('createWR').dirty"
          [errorMessage]="searchWRErrorMessage" formControlName="createWR" maxLength="7" minLength="7"
          (keyup.enter)="startWRSearch($event)" style="max-width: 18rem;">
        </ce-input>
        @if (!isClearIcon) {
          <div class="search-icon"
            [ngClass]="{ 'inactive-icon' : !dashboardFormGroup.get('createWR').dirty }">
            <i class="icon icon-search" (click)="startWRSearch($event)"></i>
          </div>
        } @else {
          <div class="search-icon">
            <i class="icon icon-close" (click)="resetWRSearch()"></i>
          </div>
        }
      </div>
    }
  </div>
</ng-template>

<app-modal [showModal]="showModal" modalHeading="" (closeDialogClick)="showModal = false" [showClose]='true'>
  <div class="map-container" style="width: 80vw;"  [ngStyle]="{'padding-top': isMobile ? '40px' : '20px' }">
    <app-esri-map  [isSupervisor]="false" (mapLoaded)="mapLoadedEvent($event)">
    </app-esri-map>
  </div>
  <div class="row" style="padding-top: 20px;">
    <div class="col">
      <p><strong>Asset Tag - <span style="color:#0078cf;">{{this.assetTag}}</span></strong></p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ce-button type="secondary" label="Close" size="large" (clickEvent)="showModal = false">
      </ce-button>
    </div>
  </div>
</app-modal>