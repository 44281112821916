<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
  <symbol id="checkmark" viewBox="0 0 26 34">
    <path stroke-linecap="round" stroke-miterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1">
    </path>
  </symbol>
</svg>
<div class="flush-grid-wrapper" [ngClass]="{ 'flush-grid-simple' : isFlushGridSimple, 'vertical-data-style-mobile' : allowVerticalDataTable }" [formGroup]="checkboxFormGroup">
  <kendo-grid [style.maxHeight.px]="700" [data]="gridData" [rowClass]="rowCallback" [autoSize]="true" formArrayName="checkboxArray">
    @for (column of gridColumns; track column) {
      <kendo-grid-column field="{{column.field}}" title="{{column.title}}"
        hidden="{{column.hidden}}">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <!-- For Desktop Version: Validation against the column attributes -->
          <!-- For Mobile Version: validation against the dataItem properties.
          Reason: Capturing and retain the required data for map coordinates during transpose of existing data objects. Check the function transposeArrayOfObjects for more clarity -->
          @if ((column?.actionType === 'anchor' || (dataItem['ActionType'] === 'anchor' && column?.field ==='Value')) && (dataItem[column?.field])) {
            <a class="flush-grid-default-action-anchor"
              data-custom-id="DashboardPage" data-custom-bhvr="STARTPROCESS"  (click)="customAnchorClick(dataItem, column?.action ?? dataItem['Action'])">
              <b>{{dataItem[column?.field] | dash }}</b>
            </a>
          }
          @if (!(column?.actionType === 'anchor' || (dataItem['ActionType'] === 'anchor' && column?.field ==='Value')) || !(dataItem[column?.field])) {
            <p class="flush-grid-cell"
              [ngClass]="{ 'dash-text' : (dataItem[column?.field] == undefined), 'center-blue-check': (dataItem[column?.field] === '\u2714')}">
            {{dataItem[column?.field] | dash }}</p>
          }
        </ng-template>
      </kendo-grid-column>
    }
    @for (action of actions; track action; let i = $index) {
      <kendo-grid-column title="">
        <ng-template kendoGridHeaderTemplate>
          @if (displayActionsHeader) {
            <span>{{action?.header ? action.header: 'Actions'}}</span>
          }
        </ng-template>
        <!-- Mock Template for start job, view job..to be replaced by actual actions -->
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="displayActionsHeader">
          @if (dataItem.status === 'Ready') {
            <div class="flush-grid-action-header">
              <a href="" (click)="handleAnchorClick(rowIndex, dataItem)"><b>{{dataItem['action']}}</b></a>
              <i class="material-icons flush-grid-right-arrow-icon">chevron_right</i>
            </div>
          } @else {
            <!-- {{dataItem.status}} {{i}}  -->
            @if (page === 'StrucutreInfo' && dataItem.status === 'Submitted' && i === 1;) {
              <div class="flush-grid-action-header">
                <a href="" style="pointer-events: none;color: grey;" (click)="handleAnchorClick(rowIndex, dataItem)"><b>Flush Pending</b></a>
              </div>
            }
            @if (page !== 'StrucutreInfo' || (dataItem.status !== 'Submitted' && page === 'StrucutreInfo')) {
              @if (action.type === 'anchor') {
                <a class="flush-grid-default-action-anchor" data-custom-id="DashboardPage"
                  data-custom-bhvr="STARTPROCESS" (click)="handleAnchorClick(rowIndex, dataItem)">
                  <b>{{this.selections.indexOf(rowIndex) < 0 ? action.caption : action.caption2}}</b>
                  @if (this.selections.indexOf(rowIndex) < 0) {
                    <i
                    class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
                  }
                  @if (this.selections.indexOf(rowIndex) > -1) {
                    <svg class="flush-grid-checkmark-action">
                      <use xlink:href="#checkmark" />
                    </svg>
                  }
                </a>
              }
              @if (action.type === 'data' && dataItem.status != 'Pending CRMS') {
                <a class="flush-grid-default-action-anchor" data-custom-id="DashboardPage"
                  data-custom-bhvr="STARTPROCESS" (click)="handleAnchorClick(rowIndex, dataItem)">
                  <b>{{dataItem.action.text}}</b>
                  @if (this.selections.indexOf(rowIndex) < 0) {
                    <i
                    class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
                  }
                  @if (this.selections.indexOf(rowIndex) > -1) {
                    <svg class="flush-grid-checkmark-action">
                      <use xlink:href="#checkmark" />
                    </svg>
                  }
                </a>
              }
              @if (action.type === 'data' && dataItem.status == 'Pending CRMS') {
                <a class="flush-grid-disabled-custom-action" data-custom-id="DashboardPage"
                  data-custom-bhvr="STARTPROCESS">
                  <b>{{dataItem.action.text}}</b>
                  @if (this.selections.indexOf(rowIndex) < 0) {
                    <i
                    class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
                  }
                  @if (this.selections.indexOf(rowIndex) > -1) {
                    <svg class="flush-grid-checkmark-action">
                      <use xlink:href="#checkmark" />
                    </svg>
                  }
                </a>
              }
              @if (action.type === 'checkbox') {
                <div class="flush-grid-check-action">
                  <app-checkbox text="" [value]="true" [formControl]="getCheckboxFormControl(rowIndex)"
                    [isChecked]="evauluateBoolean(rowIndex)">
                  </app-checkbox>
                </div>
              }
              @if (action.type === 'edit') {
                <div class="flush-grid-edit-action">
                  <a class="btn-flex details-link-btn" [ngClass]="{'btn-flex-disabled': !isOnline}"
                    (click)="isOnline && actionClicked.emit({action: 'edit', value: dataItem})">
                    <div class="icon icon-pencil"></div>Edit
                  </a>
                </div>
              }
              @if (action.type === 'add') {
                <div class="flush-grid-edit-action">
                  <a class="details-box-header details-link-btn"
                    (click)="actionClicked.emit({action: 'add', value: dataItem})">
                    Select
                  </a>
                </div>
              }
              @if (action.type === 'delete') {
                <a class="btn-flex btn-danger-link i-text"
                  (click)="actionClicked.emit({action: 'delete', value: dataItem})">
                  <div class="icon icon-trash"></div>Remove
                </a>
              }
              @if (action.type === 'custom') {
                <div>
                  @if (!(dataItem?.action?.noAppointmentId === true)) {
                    <a
                      [ngClass]="{ 'flush-grid-disabled-custom-action': dataItem?.action?.disabled === true}"
                      class="flush-grid-default-action-anchor" (click)="customAnchorClick(dataItem, action?.action)">
                      <div innerHTML="{{action?.content}}"></div>
                    </a>
                  } @else {
                    <p>No Appointment ID</p>
                  }
                  <ng-template #noAppointmentId>
                    <p>No Appointment ID</p>
                  </ng-template>
                </div>
              }
              @if (action.type === 'structure') {
                <div class="flush-grid-edit-action">
                  @if (!(dataItem?.isFacilityRequestInProgress)) {
                    <a
                      class="details-box-header details-link-btn"
                      (click)="actionClicked.emit({action: 'add', value: dataItem})">
                      Select
                    </a>
                  } @else {
                    <p class="disabled btn-flex btn-flex-disabled">Flush Pending</p>
                  }
                </div>
              }
            }
          }
        </ng-template>
      </kendo-grid-column>
    }
  </kendo-grid>
</div>
