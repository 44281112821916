import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() text: string;
  @Input() value: string = '';
  @Input() isChecked: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() errorMsg: string = '';
  @Input() formMsg: string = '';
  @Output() clickEvent = new EventEmitter();

  id;
  constructor() {
    this.id = self.crypto.randomUUID();
  }

  // Function to call when the value of the checkbox changes
  onChange(event) {}
  // Function to call when the input box is touched
  onTouched() {}

  // Implementation of ControlValueAccessor
  // Allows ng to update the input box
  writeValue(value: any): void {}
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  //On toggle writeValue and send onChange
  clickChange(e) {
    this.clickEvent.emit();
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.writeValue(this.value);
      this.onChange(this.value);
    } else {
      this.writeValue('');
      this.onChange('');
    }
  }
}
