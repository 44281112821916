<div class="side-nav-mobile" [ngClass]="{'offline-offset': !isAppOnline}">
  <div class="wrapper" [ngClass]="{'offline-banner-offset': !isAppOnline}" [@openClose]= "showNavItems ? true : false">
    <div class="side-nav-button" [ngClass]="{'show': showNavItems}"  (click)="handleMobileNav()">
      @if (showNavItems) {
        <span><b class="heading">Flush Request</b></span>
      }
      @if (!isFlushRequest) {
        <span>{{showNavItems ? 'Source WR#' : 'Flush WR #'}} <b>{{showNavItems ? sourceWrNo : wrNum}} </b></span>
      }
      @if (isFlushRequest && showNavItems) {
        <span>Source WR# <b>{{ wrNum }}</b></span>
      }
      @if (isFlushRequest) {
        <span>WC: <span>{{wcDesc}}</span></span>
      }
      @if (showNavItems && bTicket) {
        <span>B-Ticket: <b>{{bTicket}}</b></span>
      }
      <div class="close-wrapper">
        <span class="icon icon-caret-button"></span>
      </div>
    </div>
    <div class="side-nav-items-wrapper">
      @if (showNavItems) {
        <ul #sideNav class="side-nav-items" @fade>
          @if (isFlushRequestBTicket) {
            <li class="side-nav-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])">Environment Photo</a>@if (isStructurePhotosValid) {
            <span class="icon icon-check-circle"></span>
          }</li>
          <li class="side-nav-item flex" [ngClass]="{'active': isShowingJobDetails, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['job-environment'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(['job-environment'])">Job Environment</a>@if (isJobDetailsValid) {
          <span class="icon icon-check-circle"></span>
        }</li>
      } @else {
        <li class="side-nav-item flex" [ngClass]="{'active': isShowingJobDetails, 'disableElement' : disableAll}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])">Flush Details</a>@if (isJobDetailsValid) {
        <span class="icon icon-check-circle"></span>
      }</li>
      <li class="side-nav-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])">Structure Photos</a>@if (isStructurePhotosValid) {
      <span class="icon icon-check-circle"></span>
    }</li>
  }
  <li class="side-nav-item flex" [ngClass]="{'active': isShowingReviewAndSubmit, 'disabled': !(isJobDetailsValid && isStructurePhotosValid)}" (click)="handleNavigation(['review'])"><a [routerLink]="" class="router-link" (click)="handleNavigation(['review'])">Review and Submit</a>@if (isFlushRequestValid) {
  <span class="icon icon-check-circle"></span>
}</li>
</ul>
}
</div>
</div>
</div>
