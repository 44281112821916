<div class="container-fluid card">
  <h2>Flush App Test links</h2>
  @for (link of links; track link) {
    <div class="flush-test-links">
      <h3>{{link.mainTitle}}</h3>
      @for (category of link.categories; track category) {
        <div>
          @if (category.name) {
            <strong class="flush-test-link-subcategory">{{category.name}}</strong>
          }
          @for (sublink of category.sublinks; track sublink) {
            <div>
              <a [routerLink]='[sublink.refPath]' [queryParams]="sublink.queryParams">
                {{sublink.name}}
              </a>
              @if (sublink.subtext) {
                <span>({{sublink.subtext}})</span>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
