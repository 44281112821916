import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, ElementRef, AfterViewInit, QueryList, ViewChildren, OnDestroy, inject } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { BaseComponent } from '../base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  animations: [
    trigger('modalTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private changeDetector = inject(ChangeDetectorRef);
  private styler: StylerService;

  @Output() closeDialogClick = new EventEmitter();
  @Input() showErrorIcon = false;
  @Input() showSuccessIcon = false;
  @Input() showBellIcon = false;
  @Input() modalHeading = 'Heading';
  @Input() modalContent: 'Body';
  @Input() showModal = false;
  @Input() showClose = true;
  @ViewChildren('modal') modal: QueryList<ElementRef>;
  modalHeight;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

  }
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.flushSubscriptions.push(
      this.modal.changes.subscribe((els) => {
        this.modalHeight = this.modal.first?.nativeElement.getBoundingClientRect().height;
        this.changeDetector.detectChanges();
      })
    );
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  closeDialog() {
    this.closeDialogClick.emit();
  }
  getTopStyle() {
    if (window.innerWidth > 767) {
      return { top: 'calc((100% - ' + this.modalHeight + 'px) / 2)' };
    } else {
      return { top: '0px' };
    }
  }
}
