import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { DeleteStartJobPhoto } from 'src/app/app-state/actions/start-job.actions';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AccessInfo } from 'src/app/models/construction-crew/access-info';
import { FlushInfo } from 'src/app/models/construction-crew/flush-info';
import { JobInfo } from 'src/app/models/construction-crew/job-info';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { DataServiceWorkRequestResponse } from 'src/app/models/data-service-workrequest-response';
import { ParkingRestriction } from 'src/app/models/parking-restriction.model';
import { Structure } from 'src/app/models/structure.model';
import { WorkRequestEntity } from 'src/app/models/work-request-entity';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseService } from 'src/app/services/base/base.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { MapperService } from 'src/app/services/mapper/mapper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-flush-request',
  templateUrl: './flush-request.component.html',
})
export class FlushRequestComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private cc = inject(CcRequestsService);
  private store = inject(Store);
  private baseService = inject(BaseService);
  private employeeauth = inject(AuthService);
  private mapperService = inject(MapperService);

  sourceWr = '-';
  structureNumber = '-';
  requestId = '-';
  facilityId = '-';
  structureInfoCols: { field: string; title: string }[];
  accessInfoCols: { field: string; title: string }[];
  accessInfoData: ParkingRestriction[] = [];
  structureInfoData: Structure[] = [];
  photos: any = [];
  jobDetails: JobInfo;
  crmsAppointmentId: string;
  isSupervisorApproved: boolean;
  accessInfo: AccessInfo;
  flushInfo: FlushInfo;
  wmsInfo: WorkRequestEntity;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  
  constructor() {
    const route = this.route;

    this.sourceWr = route.snapshot.paramMap.get('id');
    this.structureNumber = route.snapshot.queryParams['structurenumber'];
    this.requestId = route.snapshot.queryParams['requestId'] ? route.snapshot.queryParams['requestId'] : '0';
  }

  ngOnInit(): void {
    this.structureInfoCols = [
      // { field: 'facilityId', title: 'Facility ID' },
      { field: 'type', title: 'Type' },
      { field: 'structureId', title: 'Asset Tag' },
      { field: 'frontAddress', title: 'Front Address' },
    ];
    this.accessInfoCols = [
      { field: 'assetTagFacilities', title: 'Asset Tag(s)' },
      { field: 'restriction', title: 'Restriction' },
      { field: 'days', title: 'Days' },
      { field: 'start_time', title: 'Start time' },
      { field: 'end_time', title: 'End time' },
    ];
    this.store.select(store => store.AppState.userInfo as UserInfo)
      .pipe(
        filter(userInfo => (!!userInfo && this.employeeauth.isAuthenticated())),
        switchMap(user => this.baseService.getFlushRequest(this.sourceWr, this.structureNumber,this.requestId)),
        tap(details => {
          this.store.dispatch(DeleteStartJobPhoto);
          this.cc.setImages(details?.ccImages, details.flushJobDetails.wrNumber)

          let structures: StructureInfo[] = [];
          if (details?.structureInformation) {
            structures.push(details.structureInformation);
          }
          this.cc.setStructureInformation(structures);
        }),
        take(1)
      )
      .subscribe((details) => {
        this.accessInfo = details.accessInfoModel;
        this.photos = details.ccImages;
        this.flushInfo = details.flushInfoModel;
        this.jobDetails = details.flushJobDetails;
        this.crmsAppointmentId = details.crmsAppointmentId;
        this.isSupervisorApproved = details.isSupervisorApproved;
        if (details?.structureInformation) {
          this.structureInfoData.push(this.mapperService.mapStructureInfoToStructure(details.structureInformation));
          this.facilityId = details.structureInformation.facilityId;
        }
        if (details.accessInfoModel?.parkingRestrictions) {
          const restrictions = details.accessInfoModel?.parkingRestrictions.map((p) => p);
          this.accessInfoData = restrictions.map((restriction) => {
            const days = Object.keys(restriction)
              .map((v) =>
                typeof restriction[v] === 'boolean' && restriction[v] ? v.charAt(0).toUpperCase() + v.slice(1) + ' ' : ''
              )
              .join('')
              .trim();
            return new ParkingRestriction(
              (restriction as any)?.assetTag,
              restriction.restriction,
              days,
              moment(restriction.parkingRestrictionStartTime, 'HHmm').format('h mm A'),
              moment(restriction.parkingRestrictionEndTime, 'HHmm').format('h mm A')
            );
          });

          this.baseService.getWRInfo(this.jobDetails?.workRequestId).subscribe((jobDetails: DataServiceWorkRequestResponse) => {
            jobDetails?.entity?.workRequestComments?.map((comment) => {
              comment.createdOn = moment(comment.createdOn).format("MM/DD/YYYY HH:mm");
              return comment;
            });
            this.wmsInfo = jobDetails.entity;
          });
        }
      });
  }

  goToMIAStructure(structureNumber: string) {
    let url = `https://myinspectionapplication${environment.apimEnvironment === 'prod' ? '' : environment.apimEnvironment}.coned.com/photo-library/era/structureId/${structureNumber}?autoLogin=true`;
    window.open(url, '_blank')
  }
}
