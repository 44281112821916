import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TileComponent),
      multi: true,
    }
  ]
})
export class TileComponent implements OnInit {
  @Output() clickEvent = new EventEmitter();
  @Input() isChecked: boolean = false;
  @Input() value: string = '';
  @Input() isDisabled: boolean = false;
  id;
  
  constructor() { 
    this.id = self.crypto.randomUUID();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.propagateFormChanges(this.isChecked, this.value);
  }

  // Function to call when the value of the checkbox changes
  onChange(event) {}
  // Function to call when the input box is touched
  onTouched() {}

  // Implementation of ControlValueAccessor
  // Allows ng to update the input box
  writeValue(value: any): void {}
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  //On toggle writeValue and send onChange
  clickChange(e) {
    this.clickEvent.emit();
    this.isChecked = !this.isChecked;
    this.propagateFormChanges(this.isChecked, this.value);
  }

  propagateFormChanges(isChecked : boolean, value : string) {
    if (isChecked) {
      this.writeValue(value);
      this.onChange(value);
    } else {
      this.writeValue('');
      this.onChange('');
    }
  }
}
