import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngxs/store';
import { DeleteUserInfo } from '../app-state/actions/user-info.actions';
import { UserInfo } from '../interfaces/user-info';

// Auth Guard to check if the user is either an employee or contractor
@Injectable({
  providedIn: 'root',
})
export class FlushAuthGuard {
  private authService = inject(AuthService);
  private router = inject(Router);
  private store = inject(Store);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const userInfo = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo);
    if (!isAuthenticated) {
      // if neither of the employee or contractor is authenticated, redirect user to sign-in page to choose their role
      const isEmployee = !!userInfo ? userInfo?.user?.email?.endsWith('@coned.com') : false;
      if (!!userInfo && isEmployee) {
        // Stay on page, allow app component to display sign in modal if the session is no longer valid
        return true;
      } else {
        this.store.dispatch(DeleteUserInfo);
        localStorage.clear();
        this.router.navigateByUrl('sign-in');
      }
    }
    return isAuthenticated;
  }
}
