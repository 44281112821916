/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../core/Collection.js";
import { deprecatedProperty as i } from "../core/deprecate.js";
import { IdentifiableMixin as o } from "../core/Identifiable.js";
import s from "../core/Logger.js";
import l from "../core/ReactiveMap.js";
import { watch as a } from "../core/reactiveUtils.js";
import { property as n } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import { getActionIcon as c, getActionStyles as d } from "../support/actions/actionUtils.js";
import m from "./Widget.js";
import { css as p } from "./LayerList/css.js";
import h from "./LayerList/LayerListViewModel.js";
import u from "./LayerList/LayerListVisibleElements.js";
import g from "./LayerList/ListItem.js";
import { minDragEnabledItems as y, minFilterItems as _, getItem as b, getLayerType as f, listItemHasChildren as v, areChildrenHidden as I, canSortSublayers as L, getStatusIcon as w, sortLayersToIds as C, sortChildLayersToIds as E, getVisibilityIcon as F } from "./LayerList/support/layerListUtils.js";
import { loadCalciteComponents as T } from "./support/componentsUtils.js";
import { globalCss as A } from "./support/globalCss.js";
import "./support/widgetUtils.js";
import { messageBundle as M } from "./support/decorators/messageBundle.js";
import { vmEvent as k } from "./support/decorators/vmEvent.js";
import { tsx as j } from "./support/jsxFactory.js";
const S = t.ofType(g);
let P = class extends o(m) {
  constructor(e, t) {
    super(e, t), this._activeItem = null, this._tooltipReferenceMap = new l(), this._focusRootFlowItem = !1, this._focusPanelFlowItem = !1, this._focusTableListFlowItem = !1, this._focusCatalogFlowItem = !1, this._lastDragDetail = null, this._selectedDragItemLayerUid = null, this._rootGroupUid = `operational-${this.uid}`, this.catalogLayerList = null, this.catalogOptions = null, this.collapsed = !1, this.dragEnabled = !1, this.filterPlaceholder = "", this.filterText = "", this.headingLevel = 2, this.knowledgeGraphOptions = null, this.listItemCanGiveFunction = null, this.listItemCanReceiveFunction = null, this.messages = null, this.messagesCommon = null, this.minDragEnabledItems = y, this.minFilterItems = _, this.openCatalogItem = null, this.openTableListItem = null, this.selectedItems = new S(), this.selectionMode = "none", this.tableList = null, this.viewModel = new h(), this.visibilityAppearance = "default", this.visibleElements = new u(), this._canMove = ({
      dragEl: e,
      fromEl: t,
      toEl: i
    }, o) => {
      const s = "pull" === o ? this.listItemCanGiveFunction : this.listItemCanReceiveFunction,
        l = b(e);
      if (!l?.sortable) return !1;
      const a = b(t),
        n = f(t),
        r = b(i),
        c = f(i),
        d = !!n && !!c && n === c,
        m = {
          selected: l,
          from: a,
          to: r
        },
        p = t.group,
        h = i.group,
        u = a?.layer?.type ?? "",
        g = r?.layer?.type ?? "",
        y = new Set(["map-image", "catalog", "knowledge-graph"]),
        _ = "sublayer";
      return p && h && "function" == typeof s ? s.call(null, m) : d && !y.has(u) && !y.has(g) && l?.layer?.type !== _;
    }, this._clearActiveItem = () => {
      this._activeItem = null;
    }, this._setActiveItem = e => {
      if ("default" !== this.visibilityAppearance) return;
      const t = Array.from(e.composedPath()).find(e => e.classList?.contains(p.item));
      this._activeItem = b(t);
    }, this._onCalciteListOrderChange = e => {
      const {
          _lastDragDetail: t
        } = this,
        {
          toEl: i,
          fromEl: o,
          dragEl: s,
          newIndex: l
        } = e;
      if (o && i && !(t?.newIndex === l && t?.dragEl === s && t?.toEl === i && t?.fromEl === o)) if (this._lastDragDetail = e, o !== i) this._moveLayerFromChildList({
        toEl: i,
        fromEl: o,
        dragEl: s,
        newIndex: l
      });else {
        const e = Array.from(o.children).filter(e => e?.matches("calcite-list-item")).map(e => e.value);
        this._sortLayers(o, e);
      }
    };
  }
  initialize() {
    this.addHandles([a(() => this.openCatalogItem, e => e ? this._createCatalog() : this._destroyCatalog()), a(() => this.openTableListItem, e => e ? this._createTableList() : this._destroyTableList())]);
  }
  loadDependencies() {
    return T({
      action: () => import("@esri/calcite-components/dist/components/calcite-action.js"),
      "action-group": () => import("@esri/calcite-components/dist/components/calcite-action-group.js"),
      "action-menu": () => import("@esri/calcite-components/dist/components/calcite-action-menu.js"),
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js"),
      flow: () => import("@esri/calcite-components/dist/components/calcite-flow.js"),
      "flow-item": () => import("@esri/calcite-components/dist/components/calcite-flow-item.js"),
      icon: () => import("@esri/calcite-components/dist/components/calcite-icon.js"),
      list: () => import("@esri/calcite-components/dist/components/calcite-list.js"),
      "list-item": () => import("@esri/calcite-components/dist/components/calcite-list-item.js"),
      notice: () => import("@esri/calcite-components/dist/components/calcite-notice.js"),
      tooltip: () => import("@esri/calcite-components/dist/components/calcite-tooltip.js")
    });
  }
  destroy() {
    this._destroyTableList(), this._tooltipReferenceMap.clear();
  }
  get _totalItems() {
    return this.viewModel.operationalItems.flatten(e => e.children.filter(e => "catalog-dynamic-group" !== e.layer.type)).length;
  }
  get _dragEnabled() {
    return this._totalItems >= this.minDragEnabledItems && this.dragEnabled;
  }
  get _filterEnabled() {
    return this._totalItems >= this.minFilterItems && this.visibleElements.filter;
  }
  get icon() {
    return "layers";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get listItemCreatedFunction() {
    return this.viewModel.listItemCreatedFunction;
  }
  set listItemCreatedFunction(e) {
    this.viewModel.listItemCreatedFunction = e;
  }
  get multipleSelectionEnabled() {
    return i(s.getLogger(this), "multipleSelectionEnabled", {
      replacement: "selectionMode",
      version: "4.29",
      warnOnce: !0
    }), "multiple" === this.selectionMode;
  }
  set multipleSelectionEnabled(e) {
    i(s.getLogger(this), "multipleSelectionEnabled", {
      replacement: "selectionMode",
      version: "4.29",
      warnOnce: !0
    }), this.selectionMode = e ? "multiple" : this.selectionEnabled ? "single" : "none";
  }
  get operationalItems() {
    return this.viewModel.operationalItems;
  }
  set operationalItems(e) {
    this.viewModel.operationalItems = e;
  }
  get selectionEnabled() {
    return i(s.getLogger(this), "selectionEnabled", {
      replacement: "selectionMode, dragEnabled",
      version: "4.29",
      warnOnce: !0
    }), "none" !== this.selectionMode && this.dragEnabled;
  }
  set selectionEnabled(e) {
    i(s.getLogger(this), "selectionEnabled", {
      replacement: "selectionMode, dragEnabled",
      version: "4.29",
      warnOnce: !0
    }), this.dragEnabled = e, this.selectionMode = e ? this.multipleSelectionEnabled ? "multiple" : "single" : "none";
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  triggerAction(e, t) {
    return this.viewModel.triggerAction(e, t);
  }
  render() {
    const e = this.viewModel?.state,
      t = {
        [A.hidden]: "loading" === e,
        [A.disabled]: "disabled" === e
      },
      i = this.operationalItems?.filter(e => !e.hidden && (this.visibleElements.errors || !e.error));
    return j("div", {
      class: this.classes(p.base, A.widget, A.panel, t)
    }, i?.length ? [this._renderItemTooltips(i), this._renderItems(i)] : this._renderNoItems());
  }
  _renderItemTooltip(e) {
    const {
      _tooltipReferenceMap: t,
      messages: i
    } = this;
    return e ? j("calcite-tooltip", {
      key: `tooltip-${e.layer.uid}`,
      referenceElement: t.get(e.layer.uid)
    }, i.layerIncompatibleTooltip) : null;
  }
  _renderItemTooltipNodes(e) {
    return e.incompatible ? this._renderItemTooltip(e) : e.children?.filter(e => !e.hidden).toArray().map(e => this._renderItemTooltipNodes(e));
  }
  _renderItemTooltips(e) {
    return e?.toArray().map(e => this._renderItemTooltipNodes(e));
  }
  _renderNoItemsMessage() {
    return j("div", {
      slot: "message"
    }, this.messages.noItemsToDisplay);
  }
  _renderNoItems() {
    return j("div", {
      class: p.itemMessage,
      key: "esri-layer-list__no-items"
    }, j("calcite-notice", {
      icon: "information",
      kind: "info",
      open: !0,
      width: "full"
    }, this._renderNoItemsMessage()));
  }
  _destroyCatalog() {
    this.catalogLayerList?.destroy(), this._set("catalogLayerList", null);
  }
  _destroyTableList() {
    this.tableList?.destroy(), this._set("tableList", null);
  }
  async _createCatalog() {
    this._destroyCatalog();
    const {
        default: e
      } = await import("./CatalogLayerList.js"),
      {
        headingLevel: t,
        catalogOptions: i,
        view: o,
        filterPlaceholder: s,
        listItemCreatedFunction: l,
        minFilterItems: a,
        selectionMode: n,
        visibilityAppearance: r
      } = this,
      c = this.openCatalogItem?.layer;
    this._set("catalogLayerList", new e({
      headingLevel: t,
      view: o,
      filterPlaceholder: s,
      listItemCreatedFunction: l,
      minFilterItems: a,
      selectionMode: n,
      visibilityAppearance: r,
      ...i,
      catalogLayer: c
    }));
  }
  _getTableListParams(e) {
    const t = e?.layer;
    return "knowledge-graph" === t?.type ? {
      ...this.knowledgeGraphOptions,
      tables: t?.tables
    } : null;
  }
  async _createTableList() {
    this._destroyTableList();
    const {
        default: e
      } = await import("./TableList.js"),
      t = this._getTableListParams(this.openTableListItem);
    if (!t?.tables?.length) return;
    const {
      headingLevel: i,
      selectionMode: o,
      dragEnabled: s
    } = this;
    this._set("tableList", new e({
      headingLevel: i,
      selectionMode: o,
      dragEnabled: s,
      ...t
    }));
  }
  _renderCatalogFlowItem() {
    const {
      openCatalogItem: e,
      messages: t
    } = this;
    if (!e) return null;
    const i = this._getLayerTitle(e);
    return j("calcite-flow-item", {
      afterCreate: this._focusCatalogFlowItemNode,
      afterUpdate: this._focusCatalogFlowItemNode,
      bind: this,
      description: i,
      heading: t.catalogLayers,
      headingLevel: this.headingLevel,
      key: `flow-catalog-${e.uid}`,
      onCalciteFlowItemBack: e => {
        e.preventDefault(), this.openCatalogItem = null, this._focusRootFlowItem = !0;
      }
    }, this.catalogLayerList?.render());
  }
  _renderTableListFlowItem() {
    const {
      openTableListItem: e,
      messages: t
    } = this;
    if (!e) return null;
    const i = this._getLayerTitle(e);
    return j("calcite-flow-item", {
      afterCreate: this._focusTableListFlowItemNode,
      afterUpdate: this._focusTableListFlowItemNode,
      bind: this,
      description: i,
      heading: t.tables,
      headingLevel: this.headingLevel,
      key: `flow-table-list-${e.uid}`,
      onCalciteFlowItemBack: e => {
        e.preventDefault(), this.openTableListItem = null, this._focusRootFlowItem = !0;
      }
    }, this.tableList?.render());
  }
  _renderPanelFlowItems(e) {
    return e ? e.flatten(e => e.children).filter(({
      hidden: e,
      panel: t
    }) => !e && t?.open && !t.disabled && t.flowEnabled).toArray().map(({
      title: e,
      panel: t
    }) => {
      const i = () => this._handlePanelFlowItemBack(t);
      return j("calcite-flow-item", {
        afterCreate: this._focusPanelFlowItemNode,
        afterUpdate: this._focusPanelFlowItemNode,
        bind: this,
        description: e,
        heading: t.title,
        headingLevel: this.headingLevel,
        key: `flow-panel-${t.uid}`,
        onCalciteFlowItemBack: e => {
          e.preventDefault(), i();
        }
      }, t.render(), j("calcite-button", {
        appearance: "transparent",
        onclick: i,
        slot: "footer-actions",
        width: "full"
      }, this.messagesCommon.back));
    }) : [];
  }
  _handlePanelFlowItemBack(e) {
    e.open = !1, this._focusRootFlowItem = !0;
  }
  _focusRootFlowItemNode(e) {
    this._focusRootFlowItem && (this._focusRootFlowItem = !1, requestAnimationFrame(() => e?.setFocus()));
  }
  _focusCatalogFlowItemNode(e) {
    this._focusCatalogFlowItem && (this._focusCatalogFlowItem = !1, requestAnimationFrame(() => e?.setFocus()));
  }
  _focusTableListFlowItemNode(e) {
    this._focusTableListFlowItem && (this._focusTableListFlowItem = !1, requestAnimationFrame(() => e?.setFocus()));
  }
  _focusPanelFlowItemNode(e) {
    this._focusPanelFlowItem && (this._focusPanelFlowItem = !1, requestAnimationFrame(() => e?.setFocus()));
  }
  _renderItems(e) {
    const {
        visible: t,
        collapsed: i,
        _filterEnabled: o,
        _rootGroupUid: s,
        visibleElements: {
          closeButton: l,
          collapseButton: a,
          heading: n,
          flow: r
        },
        _dragEnabled: c,
        selectionMode: d,
        filterText: m,
        filterPlaceholder: h,
        messages: u
      } = this,
      g = [j("calcite-flow-item", {
        afterCreate: this._focusRootFlowItemNode,
        afterUpdate: this._focusRootFlowItemNode,
        bind: this,
        closable: l,
        closed: !t,
        collapsed: i,
        collapsible: a,
        heading: n ? u.widgetLabel : void 0,
        headingLevel: this.headingLevel,
        key: "root-flow-item",
        onCalciteFlowItemClose: () => this.visible = !1
      }, j("calcite-list", {
        afterCreate: e => {
          e.addEventListener("focusin", this._setActiveItem), e.addEventListener("focusout", this._clearActiveItem);
        },
        afterRemoved: e => {
          e.removeEventListener("focusin", this._setActiveItem), e.removeEventListener("focusout", this._clearActiveItem);
        },
        canPull: e => this._canMove(e, "pull"),
        canPut: e => this._canMove(e, "put"),
        "data-layer-type": s,
        dragEnabled: c,
        filterEnabled: o,
        filterPlaceholder: h,
        filterText: o ? m : "",
        group: s,
        key: "root-list",
        label: u.widgetLabel,
        onmouseleave: this._clearActiveItem,
        onmouseover: this._setActiveItem,
        selectionAppearance: "border",
        selectionMode: d,
        onCalciteListChange: e => this._handleCalciteListChange(e),
        onCalciteListDragEnd: e => this._handleCalciteListDragEnd(e.detail),
        onCalciteListFilter: e => this.filterText = e.target?.filterText ?? "",
        onCalciteListOrderChange: e => this._onCalciteListOrderChange(e.detail)
      }, e?.toArray().map(e => this._renderItem(e)), e?.length && o ? j("div", {
        class: p.filterNoResults,
        slot: "filter-no-results"
      }, j("calcite-notice", {
        kind: "info",
        open: !0,
        width: "full"
      }, this._renderNoItemsMessage())) : null)), this._renderPanelFlowItems(e), this._renderTableListFlowItem(), this._renderCatalogFlowItem()];
    return t ? r ? j("calcite-flow", {
      key: "root-flow"
    }, g) : g : null;
  }
  _renderChildList(e, t = []) {
    const {
        _dragEnabled: i,
        _rootGroupUid: o,
        viewModel: {
          listModeDisabled: s
        },
        messages: l,
        selectionMode: a
      } = this,
      {
        children: n
      } = e,
      r = "catalog-dynamic-group" !== e.layer?.type && v(e),
      c = !(!s && I(e.layer)) && !r && i && "group" === e.layer?.type;
    return r || c ? j("calcite-list", {
      canPull: e => this._canMove(e, "pull"),
      canPut: e => this._canMove(e, "put"),
      "data-item": e,
      "data-layer-type": o,
      dragEnabled: !!i && e.childrenSortable && L(e),
      group: `${o}-${e.layer.uid}`,
      key: `child-list-${e.layer.uid}`,
      selectionAppearance: "border",
      selectionMode: a
    }, n?.filter(e => !e.hidden).toArray().map(i => this._renderItem(i, e, [...t, e.title])), this._getTableListParams(e)?.tables?.length ? j("calcite-list-item", {
      class: p.item,
      "data-layer-id": e.layer.id,
      dragDisabled: !0,
      key: `list-item-table-list-tables-${e.layer.uid}`,
      title: l.tables,
      onCalciteListItemSelect: () => {
        this.openTableListItem = e, this._focusTableListFlowItem = !0;
      }
    }, j("div", {
      class: p.itemContent,
      slot: "content"
    }, l.tables), j("calcite-icon", {
      class: p.itemTableIcon,
      icon: "table",
      scale: "s",
      slot: "content-start"
    }), j("calcite-icon", {
      flipRtl: !0,
      icon: "chevron-right",
      scale: "s",
      slot: "content-end"
    })) : null) : null;
  }
  _renderItemMessage(e) {
    return e.error ? j("div", {
      class: p.itemMessage,
      key: "esri-layer-list__error",
      slot: "content-bottom"
    }, j("calcite-notice", {
      icon: "exclamation-mark-triangle",
      kind: "warning",
      open: !0,
      scale: "s",
      width: "full"
    }, j("div", {
      slot: "message"
    }, this.messages.layerError))) : e.incompatible ? j("div", {
      class: p.itemMessage,
      key: "esri-layer-list__incompatible",
      slot: "content-bottom"
    }, j("calcite-notice", {
      afterCreate: t => this._setTooltipReference(t, e),
      afterRemoved: () => this._removeTooltipReference(e),
      bind: this,
      icon: "exclamation-mark-triangle",
      kind: "warning",
      open: !0,
      scale: "s",
      tabIndex: 0,
      width: "full"
    }, j("div", {
      slot: "message"
    }, this.messages.layerIncompatible))) : null;
  }
  _getLayerTitle(e) {
    return e.title || this.messages.untitledLayer;
  }
  _renderItem(e, t, i) {
    const {
        _activeItem: o,
        _selectedDragItemLayerUid: s,
        selectionMode: l,
        selectedItems: a,
        messages: n,
        visibleElements: r
      } = this,
      c = this._getLayerTitle(e),
      d = e.visibleAtCurrentTimeExtent ? e.visibleAtCurrentScale ? c : `${c} (${n.layerInvisibleAtScale})` : `${c} (${n.layerInvisibleAtTime})`,
      m = this._filterActions(e.actionsSections),
      h = this._countActions(m),
      u = "catalog" === e.parent?.layer?.type,
      g = "catalog-footprint" === e.layer?.type,
      y = "catalog-dynamic-group" === e.layer?.type,
      _ = g ? j("calcite-icon", {
        class: p.itemCatalogIcon,
        icon: "footprint",
        key: "footprint",
        scale: "s",
        slot: "content-start"
      }) : null,
      b = y ? j("calcite-icon", {
        class: p.itemCatalogIcon,
        icon: "catalog-dataset",
        key: "catalog-dataset",
        scale: "s",
        slot: "content-start"
      }) : null,
      f = r.catalogLayerList && y ? j("calcite-action", {
        appearance: "transparent",
        disabled: !e.layer.visible,
        icon: "chevron-right",
        iconFlipRtl: !0,
        onclick: () => {
          this.openCatalogItem = t, this._focusCatalogFlowItem = !0;
        },
        scale: "s",
        slot: "actions-end",
        text: c
      }) : null;
    return j("calcite-list-item", {
      afterCreate: t => this._focusSelectedDragEl(t, e),
      afterUpdate: t => this._focusSelectedDragEl(t, e),
      class: this.classes(p.item, {
        [p.itemActive]: o === e
      }),
      "data-item": e,
      "data-layer-id": e.layer.id,
      dragDisabled: !e.sortable || u,
      dragSelected: e.layer.uid === s,
      id: e.layer.uid,
      key: `list-item-${e.layer.uid}`,
      label: c,
      metadata: {
        parentTitles: i,
        title: c
      },
      open: e.open,
      selected: "none" !== l && a.includes(e),
      title: d,
      value: e.layer.uid,
      onCalciteListItemDragHandleChange: t => this._handleCalciteListItemDragHandleChange(t, e),
      onCalciteListItemSelect: i => this._handleCalciteListItemSelect(i, e, t),
      onCalciteListItemToggle: t => this._handleCalciteListItemToggle(t, e)
    }, _, b, j("div", {
      class: this.classes({
        [p.itemContent]: !0,
        [p.itemInvisible]: !e.visibleAtCurrentScale || !e.visibleAtCurrentTimeExtent
      }),
      slot: "content"
    }, c), this._renderItemStatus(e, t), this._renderItemToggle(e, t), f, this._renderItemTemporaryIcon(e), this._renderChildList(e, i), this._renderItemMessage(e), this._renderPanel(e.panel), this._renderPanelAction(e.panel), h > 1 ? this._renderActionsSections(e, m) : 1 === h ? this._renderAction({
      item: e,
      action: this._getSingleAction(m),
      textEnabled: !1
    }) : null);
  }
  _renderItemStatus(e, t) {
    const {
      visibleElements: i
    } = this;
    if (!i.statusIndicators) return null;
    const {
        connectionStatus: o,
        publishing: s,
        updating: l
      } = e,
      a = l && !t,
      n = !!o;
    return j("calcite-icon", {
      class: this.classes({
        [p.statusIndicator]: !0,
        [p.publishing]: s,
        [p.updating]: a,
        [p.connectionStatus]: n,
        [p.connectionStatusConnected]: n && "connected" === o
      }),
      icon: w({
        connectionStatus: o,
        publishing: s
      }),
      key: "layer-item-status",
      scale: "s",
      slot: "actions-end"
    });
  }
  _renderItemTemporaryIcon(e) {
    return this.visibleElements.temporaryLayerIndicators && "persistenceEnabled" in e.layer && !e.layer.persistenceEnabled ? j("calcite-icon", {
      class: p.itemTemporaryIcon,
      icon: "temporary",
      key: "temporary-icon",
      scale: "s",
      slot: "actions-start",
      title: this.messages.temporary
    }) : null;
  }
  _renderItemToggle(e, t) {
    const {
        messages: i,
        visibilityAppearance: o
      } = this,
      {
        visible: s
      } = e,
      l = t?.visibilityMode;
    if ("inherited" === l) return null;
    const a = F({
        visible: s,
        exclusive: "exclusive" === l,
        visibilityAppearance: o
      }),
      n = "checkbox" === o,
      r = i.layerVisibility;
    return j("calcite-action", {
      appearance: "transparent",
      class: p.visibleToggle,
      icon: n ? a : void 0,
      key: "visibility-toggle",
      onclick: () => this._toggleVisibility(e, t),
      scale: "s",
      slot: n ? "actions-start" : "actions-end",
      text: r,
      title: r
    }, n ? null : j("calcite-icon", {
      class: this.classes({
        [p.visibleIcon]: "exclusive" !== l && s
      }),
      icon: a,
      scale: "s"
    }));
  }
  _renderPanel(e) {
    return !e?.open || e.disabled || e.flowEnabled ? null : j("div", {
      class: p.itemContentBottom,
      key: `content-panel-${e.uid}`,
      slot: "content-bottom"
    }, e.render());
  }
  _renderPanelAction(e) {
    if (!e?.visible) return null;
    const {
      open: t,
      title: i,
      disabled: o
    } = e;
    return j("calcite-action", {
      active: t,
      appearance: "transparent",
      disabled: o,
      icon: c(e),
      key: `action-${e.uid}`,
      onclick: () => this._togglePanel(e),
      scale: "s",
      slot: "actions-end",
      text: i ?? "",
      title: i ?? void 0
    }, this._renderFallbackIcon(e));
  }
  _renderActionsSections(e, t) {
    const i = this.messagesCommon.options;
    return j("calcite-action-menu", {
      appearance: "transparent",
      class: p.actionMenu,
      key: "item-action-menu",
      label: this.messagesCommon.menu,
      open: e.actionsOpen,
      overlayPositioning: "fixed",
      placement: "bottom-end",
      scale: "s",
      slot: "actions-end",
      onCalciteActionMenuOpen: t => e.actionsOpen = t.target.open
    }, j("calcite-action", {
      appearance: "transparent",
      icon: "ellipsis",
      scale: "s",
      slot: "trigger",
      text: i,
      title: i
    }), t.toArray().map((t, i) => this._renderActionSection(e, t, i)));
  }
  _renderActionSection(e, t, i) {
    return j("calcite-action-group", {
      class: p.actionGroup,
      key: `action-section-${i}`
    }, t.toArray().map(t => this._renderAction({
      item: e,
      action: t,
      textEnabled: !0
    })));
  }
  _renderFallbackIcon(e) {
    const {
      className: t,
      icon: i
    } = e;
    if (i) return null;
    const o = "image" in e ? e.image : void 0,
      s = {
        [p.itemActionIcon]: !!t,
        [p.itemActionImage]: !!o
      };
    return t && (s[t] = !0), o || t ? j("span", {
      "aria-hidden": "true",
      class: this.classes(p.itemActionIcon, s),
      key: "icon",
      styles: d(o)
    }) : null;
  }
  _renderAction(e) {
    const {
      item: t,
      action: i,
      textEnabled: o
    } = e;
    if (!i) return null;
    const {
      active: s,
      disabled: l,
      title: a,
      type: n,
      indicator: r
    } = i;
    return j("calcite-action", {
      active: "toggle" === n && i.value,
      appearance: "transparent",
      disabled: l,
      icon: c(i),
      indicator: r,
      key: `action-${i.uid}`,
      loading: s,
      onclick: () => this._triggerAction(t, i),
      scale: "s",
      slot: o ? void 0 : "actions-end",
      text: a ?? "",
      textEnabled: o,
      title: a ?? void 0
    }, this._renderFallbackIcon(i));
  }
  _setTooltipReference(e, t) {
    this._tooltipReferenceMap.set(t.layer.uid, e);
  }
  _removeTooltipReference(e) {
    this._tooltipReferenceMap.delete(e.layer.uid);
  }
  _moveLayerFromChildList({
    toEl: e,
    fromEl: t,
    dragEl: i,
    newIndex: o
  }) {
    const s = b(i),
      l = b(e),
      a = b(t);
    this.viewModel.moveListItem(s, a, l, o);
  }
  _handleCalciteListDragEnd(e) {
    const {
      fromEl: t,
      dragEl: i,
      oldIndex: o
    } = e;
    t.insertBefore(i, t.children[o]);
  }
  _sortLayers(e, t) {
    if (e) if (e.group === this._rootGroupUid) C(this.view?.map?.layers, t);else {
      const i = b(e);
      if (!i) return;
      E(i, t);
    }
  }
  _getSingleAction(e) {
    return e.reduce(e => e).find(e => e && ("button" === e.type || "toggle" === e.type));
  }
  _filterActions(e) {
    return e.map(e => e.filter(e => e.visible));
  }
  _countActions(e) {
    return e.reduce((e, t) => e + t.length, 0);
  }
  _triggerAction(e, t) {
    t && e && ("toggle" === t.type && (t.value = !t.value), this.triggerAction(t, e));
  }
  _handleCalciteListChange(e) {
    const {
      selectionMode: t,
      selectedItems: i
    } = this;
    if ("none" === t) return;
    const o = e.target.selectedItems.map(e => b(e)).filter(Boolean);
    i.removeAll(), i.addMany(o);
  }
  _handleCalciteListItemToggle(e, t) {
    e.stopPropagation(), t.open = e.target.open;
  }
  _focusSelectedDragEl(e, t) {
    this._selectedDragItemLayerUid === t.layer.uid && requestAnimationFrame(() => e?.setFocus());
  }
  _handleCalciteListItemDragHandleChange(e, t) {
    e.stopPropagation();
    const i = e.target.dragSelected;
    this._selectedDragItemLayerUid = i ? t.layer.uid : null;
  }
  _handleCalciteListItemSelect(e, t, i) {
    b(e.target) === t && "none" === this.selectionMode && "inherited" !== i?.visibilityMode && this._toggleVisibility(t, i);
  }
  _togglePanel(e) {
    e.open = !e.open, e.open && (this._focusPanelFlowItem = !0);
  }
  _toggleVisibility(e, t) {
    if (!e) return;
    const i = t?.visibilityMode;
    "exclusive" === i && e.visible || (e.visible = !e.visible);
  }
};
e([n()], P.prototype, "_activeItem", void 0), e([n()], P.prototype, "_tooltipReferenceMap", void 0), e([n()], P.prototype, "_focusRootFlowItem", void 0), e([n()], P.prototype, "_focusPanelFlowItem", void 0), e([n()], P.prototype, "_focusTableListFlowItem", void 0), e([n()], P.prototype, "_focusCatalogFlowItem", void 0), e([n()], P.prototype, "_totalItems", null), e([n()], P.prototype, "_dragEnabled", null), e([n()], P.prototype, "_filterEnabled", null), e([n({
  readOnly: !0
})], P.prototype, "catalogLayerList", void 0), e([n()], P.prototype, "catalogOptions", void 0), e([n()], P.prototype, "collapsed", void 0), e([n()], P.prototype, "dragEnabled", void 0), e([n()], P.prototype, "filterPlaceholder", void 0), e([n()], P.prototype, "filterText", void 0), e([n()], P.prototype, "headingLevel", void 0), e([n()], P.prototype, "icon", null), e([n()], P.prototype, "knowledgeGraphOptions", void 0), e([n()], P.prototype, "label", null), e([n()], P.prototype, "listItemCanGiveFunction", void 0), e([n()], P.prototype, "listItemCanReceiveFunction", void 0), e([n()], P.prototype, "listItemCreatedFunction", null), e([n(), M("esri/widgets/LayerList/t9n/LayerList")], P.prototype, "messages", void 0), e([n(), M("esri/t9n/common")], P.prototype, "messagesCommon", void 0), e([n()], P.prototype, "minDragEnabledItems", void 0), e([n()], P.prototype, "minFilterItems", void 0), e([n()], P.prototype, "multipleSelectionEnabled", null), e([n()], P.prototype, "openCatalogItem", void 0), e([n()], P.prototype, "openTableListItem", void 0), e([n()], P.prototype, "operationalItems", null), e([n()], P.prototype, "selectionEnabled", null), e([n()], P.prototype, "selectedItems", void 0), e([n()], P.prototype, "selectionMode", void 0), e([n({
  readOnly: !0
})], P.prototype, "tableList", void 0), e([n()], P.prototype, "view", null), e([k("trigger-action"), n({
  type: h
})], P.prototype, "viewModel", void 0), e([n()], P.prototype, "visibilityAppearance", void 0), e([n({
  type: u,
  nonNullable: !0
})], P.prototype, "visibleElements", void 0), P = e([r("esri.widgets.LayerList")], P);
const x = P;
export { x as default };