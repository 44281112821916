import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from 'src/app/services/base/base.service';
import * as _ from 'lodash';
import { catchError, concatMap, filter, switchMap, tap } from 'rxjs/operators';
import { StructureHistoryResponse } from 'src/app/models/structure-history';
import { FlushComments } from 'src/app/models/edit-history';
import { throwError } from 'rxjs';
import moment from 'moment';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { Store } from '@ngxs/store';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WorkRequestEntity } from 'src/app/models/work-request-entity';
import { environment } from 'src/environments/environment';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
@Component({
  selector: 'app-flush-job',
  templateUrl: './flush-job.component.html',
})
export class FlushJobComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private baseService = inject(BaseService);
  private ccService = inject(CcRequestsService);
  private store = inject(Store);
  private employeeauth = inject(AuthService);

  allPhotos = [];
  wrData: WorkRequestEntity;
  structureNumber: string;
  addressHistoryData: StructureHistoryResponse[] = [];
  addressHistoryCols: { field: string; title: string }[];
  commentHistoryData: FlushComments[] = [];
  commentHistoryCols: { field: string; title: string }[];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.addressHistoryCols = [
      { field: 'dateSaved', title: 'Date Saved' },
      { field: 'crewName', title: 'Crew Member' },
      { field: 'address', title: 'Front Address' },
    ]
    this.commentHistoryCols = [
      { field: 'createdOn', title: 'Date Saved' },
      { field: 'userName', title: 'Crew Member' },
      { field: 'description', title: 'Description of Work' },
    ]
  }

  ngOnInit(): void {
    const workRequestId = this.route.snapshot.paramMap.get('id');
    this.store.select(store => store.AppState.userInfo as UserInfo)
    .pipe(
      filter(userInfo => (!!userInfo && this.employeeauth.isAuthenticated())),
      switchMap(user => this.baseService.getWRInfo(workRequestId)),
      tap(details => {
        this.wrData = _.cloneDeep(details.entity);
        this.commentHistoryData = (details.entity.workRequestComments as FlushComments[] ?? [])
          .map((c: FlushComments) => { return {...c, createdOn: moment(c.createdOn).format('MM/DD/YYYY')} });
        if (this.wrData?.images) {
          this.allPhotos = this.wrData?.images;
          this.ccService.setImages([...this.wrData?.images], this.wrData.workRequestNo);
        }
      }),
      switchMap(details => this.baseService.getWorkRequestDetails(workRequestId, details.entity.facilityID, '', -1)),
      tap((wrDetails: WorkRequestDetail) => this.structureNumber = (wrDetails?.facility as any)?.assetTag ?? wrDetails?.sourceWRInformation?.structureInformation?.structureNumber),
      concatMap((wrDetails: any) => 
        this.baseService
          .getStructureHistory(
            this.wrData.sourceWrNumber, wrDetails?.sourceWRInformation?.structureInformation?.structureNumber 
              ?? (wrDetails?.facility?.assetTag ?? ''))),
      catchError(err => throwError(err))
    )
    .subscribe((history) => {
      this.addressHistoryData = history.map(struct => {
        return {
          ...struct,
          dateSaved: moment(struct.dateSaved).format('MM/DD/YYYY')
        }
      }).slice(-3);
    }, 
    err => {
      console.error(err)
    });
  }

  goToMIAStructure(structureNumber: string) {
    let url = `https://myinspectionapplication${environment.apimEnvironment === 'prod' ? '' : environment.apimEnvironment}.coned.com/photo-library/era/structureId/${structureNumber}?autoLogin=true`;
    window.open(url, '_blank')
  }
}
