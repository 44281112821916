<div class="panelbar-wrapper">
  <kendo-panelbar>
    <kendo-panelbar-item [disabled]="true" class="panel-title" title="">
      <ng-template kendoPanelBarItemTitle>
        <div class="container-fluid">
          <div class="row">
            <div class="col-4 pr-0"><b>Crew Code</b></div>
            <div class="col-8"><b>Address</b></div>
          </div>
        </div>
      </ng-template>
    </kendo-panelbar-item>
    @for (dataItem of workRequestRows; track dataItem) {
      <kendo-panelbar-item title="">
        <ng-template kendoPanelBarItemTitle>
          <div class="container-fluid">
            <div class="row panel-bar-content">
              <div class="col-4">{{dataItem.crewCode}}</div>
              <div class="col-8">{{dataItem.address | dash}}</div>
            </div>
          </div>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <div class="custom-template">
            <div class="mobile-border-style">
            </div>
            <div class="container-fluid">
              <div class="row panel-bar-content-title">
                <div class="col-6">
                  Flush WR#
                </div>
                <div class="col-6">
                  Asset Tag
                </div>
              </div>
              <div class="row panel-bar-content">
                <div class="col-6">
                  {{dataItem.wrNo}}
                </div>
                <div class="col-6">
                  {{dataItem.structureId | dash}}
                </div>
              </div>
              <br />
              <div class="row panel-bar-content-title">
                <div class="col-6">
                  <strong>Crew Lead</strong>
                </div>
                <div class="col-6">
                </div>
              </div>
              <div class="row panel-bar-content">
                <div class="col-6 align-self-center">
                  {{dataItem.crewLead}}
                </div>
                <!-- <div class="col-6">
                <i class="icon icon-language close-icons"></i>
                <i class="icon icon-contact-us close-icons"></i>
              </div> -->
            </div>
            <br />
            <div class="row panel-bar-content-title">
              <div class="col-6">
                Crew Code
              </div>
              <div class="col-6">
                Completion Status
              </div>
            </div>
            <div class="row panel-bar-content">
              <div class="col-6">
                {{dataItem.crewCode}}
              </div>
              <div class="col-6">
                {{dataItem.status}}
              </div>
            </div>
            <br />
            <div class="row panel-bar-content-title">
              <div class="col-12">
                Address
              </div>
            </div>
            <div class="row panel-bar-content">
              <a class="col-12" href="geo:124.028582,-29.201930" target="_blank">
                <p>
                  {{dataItem.address}}
                </p>
              </a>
              <!-- <a class="col-12" href="http://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003" target="_blank">
              <p>
                {{dataItem.address}}
              </p>
            </a> -->
          </div>
          <br />
          <div class="row panel-bar-content-title">
            <div class="col-12">
              Source WR
            </div>
          </div>
          <div class="row panel-bar-content">
            <div class="col-12">
              {{dataItem.sourceWrNumber}}
            </div>
          </div>
          <br />
          @if (!disableActionItems) {
            <div class="row">
              @if (dataItem?.appointmentId?.length > 4) {
                <div class="col-6 col-sm-3">
                  <ce-button style="width: 50%; display: inline-grid;" label='Close' type="primary"
                  (clickEvent)="closeBtnClicked(dataItem)" size='large'></ce-button>
                </div>
                <div class="col-6 col-sm-3">
                  <ce-button style="width: 50%; display: inline-grid;" label='Edit' type="secondary"
                  (clickEvent)="editBtnClicked(dataItem)" size='large'></ce-button>
                </div>
              } @else {
                <p>No Appointment ID</p>
              }
            </div>
          }
        </div>
      </div>
    </ng-template>
  </kendo-panelbar-item>
}
</kendo-panelbar>
</div>