<div class="padding-around">
  <div class="row">
    <div class="col">
      <h3 class="mb-4">Flush Request</h3>
    </div>
  </div>
  <div class="mb-1 row">
    <div class="col-md-2">
      <b>Source WR#</b>
      <p>{{sourceWr}}</p>
    </div>
    <div class="col-md-2">
      <b>Facility ID:</b>
      <p [ngClass]="{'dash-text': !facilityId}">{{facilityId | dash}}</p>
    </div>
    <div class="col-md-2">
      <b>Asset Tag:</b>
      <p [ngClass]="{'dash-text': !structureNumber}">{{structureNumber | dash}}</p>
    </div>
  </div>
  <div class="mb-1 row">
    <div class="col-4">
      <b>Address</b>
      <p>{{wmsInfo?.concatenatedWRAddress | dash }}</p>
    </div>
  </div>
  <div class="form-group row separate">
    <div class="col-md-2">
      <b>Created</b>
      <p [ngClass]="{'dash-text': !jobDetails?.createdOn}">{{jobDetails?.createdOn | timestamp:"MM-DD-YYYY" | dash }}</p>
    </div>
    <div class="col-auto">
      <b>Appointment ID</b>
      <p [ngClass]="{'dash-text': !crmsAppointmentId}">{{crmsAppointmentId | dash}}</p>
    </div>
  </div>
  <div class="form-group row separate">
    <div class="col-12">
      <div class="form-group row">
        <div class="col">
          <b>Job Details: </b>
        </div>
      </div>
      <!-- <br /> -->
      <div class="row mb-1">
        <div class="col-md-3">
          <b>Job: </b>
          <p>{{jobDetails?.job}}</p>
        </div>
        <div class="col-md-2">
          <b>Priority:</b>
          <p [ngClass]="{'dash-text': !jobDetails?.jobPriority}">{{jobDetails?.jobPriority | dash}}</p>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-10">
          <b>Description:</b>
          <p [ngClass]="{'dash-text': !jobDetails?.jobDescription}">{{jobDetails?.jobDescription | dash}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-12">
      <b>Structure Info:</b>
      @if (structureInfoData?.length > 0) {
        <div class="form-group">
          @if (structureInfoData?.length > 0) {
            <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass]="{'no-highlight' : true}"
              [allowVerticalDataTable]="true" [data]="structureInfoData"
              [columns]="structureInfoCols" [actions]="[]">
            </app-flush-grid>
          }
        </div>
      }
      @if (structureInfoData?.length==0) {
        <p>There is no structure information available for this job.</p>
      }
    </div>
  </div>
  <div class="mb-3 row separate">
    <div class="col-12">
      <b>Access Info:</b>
      <div class="access-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-user"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Point of contact for this location.</b>
          @for (contact of accessInfo?.pointOfContact; track contact) {
            <div class="customer-contact">
              <p>{{contact?.name}}</p>
              <p>{{contact?.email}}</p>
              <p>{{contact?.phone}}</p>
            </div>
          }
          <!--
          THIS IS THE SHAPE OF THE RESPONSE, NEEDS TO BE LIKE ABOVE
          <div class="customer-contact" *ngIf="pointOfContactName">
            <p>{{accessInfo?.pointOfContactName}</p>
            <p>{{accessInfo?.pointOfContactEmail}}</p>
            <p>{{accessInfo?.pointOfContactPhone}}</p>
          </div> -->
          @if (accessInfo?.pointOfContact == undefined || accessInfo?.pointOfContact.length  < 1) {
            <p>No point of contact(s) available for this job.</p>
          }
        </div>
      </div>
      <div class="access-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-cone"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Critical roadway</b>
          <p>{{accessInfo?.isStructureOnCriticalRoadway ? 'Yes' : 'No' }}</p>
          <b>Cone hives</b>
          <p>{{ accessInfo?.areConeHivesRequired ? 'Yes' : 'No' }}</p>
          <b>Flaggers</b>
          <p>{{ accessInfo?.flaggersWorkSafety ? 'Yes' : 'No' }}</p>
          <b>Customer access</b>
          <p>{{ accessInfo?.customerRequiredForAccess ? 'Required' : 'Not Required' }}</p>
        </div>
        <div class="col-12 col-lg-1 offset-lg-1">
          <i class="icon icon-truck"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Vactor truck access</b>
          <p>{{accessInfo?.truckHasSufficientAccess ? 'Yes': 'No'}}</p>
        </div>
      </div>
      <div class="access-info row mb-0 pb-0" style="border-bottom: none;">
        <div class="col-12 col-lg-1">
          <i class="icon icon-car"></i>
        </div>
        <div class="col-12 col-lg-11">
          <b>Parking restrictions</b>
          @if (accessInfo?.parkingRestrictions.length > 0) {
            <p>Parking restriction(s) documented for this job.</p>
          }
        </div>
      </div>
      <div class="access-info row pt-1" style="border-bottom: none;">
        <div class="col-12 pr-3 pl-3">
          @if (accessInfo?.parkingRestrictions?.length > 0) {
            <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [allowVerticalDataTable]="true" [data]="accessInfoData" [columns]="accessInfoCols" [actions]="[]">
            </app-flush-grid>
          }
          @if (accessInfo?.parkingRestrictions.length < 1 || accessInfo?.parkingRestrictions == undefined) {
            <p>No parking restriction(s) documented for this job.</p>
          }
        </div>
      </div>
      <div class="last-row row">
        <div class="col-12 col-lg-4">
          <b>Additional Details</b>
          <p>
            {{accessInfo?.additionalAccessDetails?.length > 0 ? accessInfo?.additionalAccessDetails : 'No additional details.'}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-12">
      <p><b>Flush Info:</b></p>
      <div class="flush-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-eye"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Equipment visibility</b>
          <p>{{flushInfo?.canSeeAllEquipment ? 'Visible' : 'Not visible' }}</p>
        </div>
      </div>
      <div class="flush-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-water"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Water</b>
          <p>{{flushInfo?.isWaterFoundOnStructure ? 'Yes' : 'None' }}</p>
          @if (flushInfo?.isWaterFoundOnStructure  && flushInfo?.canDewaterStructureUsingPump) {
            <p>
              Can be Dewatered
              using a pump.
            </p>
          }
          @if (flushInfo?.structurePumpRestriction) {
            <p></p>
          }
          @if (flushInfo?.isWaterFoundOnStructure) {
            <p> {{flushInfo?.structureWaterDescription}}</p>
          }
          @if (flushInfo?.isWaterFoundOnStructure) {
            <p> {{flushInfo?.structureWaterQuantity}}</p>
          }
        </div>
        <div class="col-12 col-lg-1 offset-lg-1">
          <i class="icon icon-shovel"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Debris</b>
          <p>{{flushInfo?.isDebrisEnvironmentConditionFound ? 'Yes': 'None'}}</p>
          @if (flushInfo?.isDebrisEnvironmentConditionFound) {
            <p>@for (debrisType of flushInfo?.structureDebrisType?.split(','); track debrisType; let i = $index) {
              <span>{{debrisType}}{{i === flushInfo?.structureDebrisType?.split(',')?.length - 1 ? '' : ', '}}</span>
            }</p>
          }
          @if (flushInfo?.isDebrisEnvironmentConditionFound) {
            <p><b>Amount</b></p>
          }
          @if (flushInfo?.isDebrisEnvironmentConditionFound) {
            <p>{{flushInfo?.structureDebrisQuantity}}</p>
          }
        </div>
      </div>
      <div class="flush-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-insect"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Infestation</b>
          <p>{{ flushInfo?.structureInfestation }}</p>
        </div>
        <div class="col-12 col-lg-1 offset-lg-1">
          <i class="icon icon-explosion"></i>
        </div>
        <div class="col-12 col-lg-4">
          <b>Perlite bags</b>
          <p>{{flushInfo?.arePearliteBagsFound ? 'Yes': 'None'}}</p>
          @if (flushInfo?.arePearliteBagsFound) {
            <p>
              {{flushInfo?.arePearliteBagsCompromised ? 'Compromised' : 'Not compromised'}}
            </p>
          }
        </div>
      </div>
      <div class="last-row row separate">
        <div class="col-12 col-lg-4">
          <b>Additional flush information</b>
          <p>{{flushInfo?.additionalDetails?.length > 0 ? flushInfo?.additionalDetails : 'No additional details.'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 last-row row separate">
    <div class="col-12">
      <b>Request Photos:</b>
      <div class="form-group row">
        <div class="col-lg-6 col-12">
          <p><b>Media</b></p>
        </div>
        <div class="col-lg-6 col-12" style="place-content: end; display: flex;">
          <ce-button label="View Associated Photos" type='primary' (clickEvent)="goToMIAStructure(structureNumber)"
          size='medium' icon="icon-images-multi" iconDirection="left"></ce-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          @if (photos?.length > 0) {
            <app-photos [cameraEnabled]="false" [isSummary]="true"></app-photos>
          }
        </div>
      </div>
      @if (photos?.length == 0) {
        <p>There are no photos available for this job.</p>
      }
    </div>
  </div>
  <div class="mb-3 row">
    @if (isSupervisorApproved) {
      <div class="col-12">
        <div class="row">
          <b>Supervisor Communication:</b>
        </div>
        <div class="mb-3 last-row row">
          Construction crew member who requested Flush notified his/her supervisor about this request.
        </div>
        @if (wmsInfo?.workRequestComments?.length > 0) {
          <div class="row">
            <b>Comments History:</b>
          </div>
        }
        @if (wmsInfo?.workRequestComments?.length > 0) {
          <div class="form-group">
            <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass]="{'no-highlight' : true}"
              [allowVerticalDataTable]="true" [data]="wmsInfo?.workRequestComments"
                        [columns]="[
                        { field: 'createdOn', title: 'Date' },
                        { field: 'description', title: 'Comment' },
                        { field: 'userName', title: 'User'}]" [actions]="[]">
            </app-flush-grid>
          </div>
        }
      </div>
    }
  </div>
</div>