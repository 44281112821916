<div class="row">
  <div class="col">
    <h3 class="mb-3">Flush Review</h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="body2">{{today | dash}}</p>
  </div>
</div>
<div class="container-fluid" [ngStyle]="isPhotoReview && {'display': 'none'}">
  <div class="row">
    <div class="col-auto">
      <b>Source WR#</b>
      <p [ngClass]="{'dash-text': !workRequest?.sourceWrNumber}">{{workRequest?.sourceWrNumber | dash}}</p>
    </div>
    <div class="col-auto"


      >
      <b>Flush WR#</b>
      <p [ngClass]="{'dash-text': !workRequest?.workRequestNo}">{{workRequest?.workRequestNo | dash}}</p>
    </div>
    @if (!isSummary) {
      <div class="col-auto">
        <b>Flush Description</b>
        <p [ngClass]="{'dash-text': !workRequest?.workRequestName}">{{workRequest?.workRequestName | dash}}</p>
      </div>
    }
  </div>
  <div class="row">
    <h4>Flush Information</h4>
  </div>
  @if (isSummary) {
    <div class="form-group row">
      <div class="col-12 col-md-6">
        <p><b>Description of the work that was performed</b></p>
        <p>{{workRequest?.workRequestName}}</p>
      </div>
    </div>
  }
  <!-- Work History demo -->
  @if (flushComments.length > 0) {
    <div>
      <div class="row">
        <h5>Work History</h5>
      </div>
      <div class="row mb-5">
        <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{'no-highlight' : true}" [allowVerticalDataTable]="true"
          [data]="flushComments | dash" [columns]="flushCommentsCols"
        [actions]="[]"></app-flush-grid>
      </div>
      @if (!isSummary) {
        <div class="row">
          <h4>Update Description of Work</h4>
        </div>
      }
    </div>
  }
  @if (!isSummary) {
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <p>Please provide a description of the work that was performed</p>
      </div>
    </div>
  }
  <form [formGroup]="flushReviewForm">
    @if (!isSummary) {
      <div class="mb-5 row no-gutters">
        <div class="col-12 col-md-6" [formGroup]="flushReviewForm">
          <ce-input inputType="textarea" label="Description of work" formControlName="comments" class="form-control" [showError]="flushReviewForm.get('comments').errors"></ce-input>
        </div>
      </div>
    }
    @if (!!userInfo && ((userInfo?.flushRoleType == 'CN' && !showSaveProgress) || (isSummary && !!this.flushReviewForm.controls['hasStrayVoltage'].value))) {
      <div class="form-group row">
        <div class="col-lg-5">
          <p><b>Has Stray Voltage been found?</b>@if (!this.flushReviewForm.controls['hasStrayVoltage'].valid) {
          <a class="flush-process-error"> *</a>
        }</p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="hasStrayVoltage" (selectionChange)="hasStrayVoltageSelectionChange($event)">
              <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="hasStrayVoltageGroup" [isChecked]="this.flushReviewForm.controls['hasStrayVoltage'].value == 'false' || this.flushReviewForm.controls['hasStrayVoltage'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="hasStrayVoltageGroup" [isChecked]="this.flushReviewForm.controls['hasStrayVoltage'].value == 'true' || this.flushReviewForm.controls['hasStrayVoltage'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    @if (showStrayVoltageQuestions) {
      <div>
        <div class="form-group row">
          <div class="col-lg-5">
            <p><b>Will the crew be conducting repairs?</b>@if (!this.flushReviewForm.controls['isCrewConductingRepairs'].valid) {
            <a class="flush-process-error"> *</a>
          }</p>
          <div class="row">
            <div class="col-12">
              <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="isCrewConductingRepairs">
                <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="isCrewConductingRepairsGroup" [isChecked]="this.flushReviewForm.controls['isCrewConductingRepairs'].value == 'false' || this.flushReviewForm.controls['isCrewConductingRepairs'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
                <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="isCrewConductingRepairsGroup" [isChecked]="this.flushReviewForm.controls['isCrewConductingRepairs'].value == 'true' || this.flushReviewForm.controls['isCrewConductingRepairs'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
              </ce-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-5">
          <p><b>Can the original work be performed?</b>@if (!this.flushReviewForm.controls['canOriginalWorkContinue'].valid) {
          <a class="flush-process-error"> *</a>
        }</p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="canOriginalWorkContinue">
              <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="canOriginalWorkContinueGroup" [isChecked]="this.flushReviewForm.controls['canOriginalWorkContinue'].value == 'false' || this.flushReviewForm.controls['canOriginalWorkContinue'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="canOriginalWorkContinueGroup" [isChecked]="this.flushReviewForm.controls['canOriginalWorkContinue'].value == 'true' || this.flushReviewForm.controls['canOriginalWorkContinue'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
}
<div class="form-group row">
  <div class="col-lg-5">
    <p><b>Was a lead stabilizer used?</b>@if (!this.flushReviewForm.controls['hasLeadStabilizerUsed'].valid) {
    <a class="flush-process-error"> *</a>
  }</p>
  <div class="row">
    <div class="col-12">
      <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="hasLeadStabilizerUsed" (selectionChange)="hasLeadStabilizerUsedSelectionChange($event)">
        <ce-radio-button size="large" label="No" id="NoLeadStabilizerUsed" value=false groupName="hasLeadStabilizerUsedGroup" [isChecked]="this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == 'false' || this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
        <ce-radio-button size="large" label="Yes" id="ThereAreLeadStabilizerUsed" value=true groupName="hasLeadStabilizerUsedGroup" [isChecked]="this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == 'true' || this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
      </ce-radio-group>
    </div>
  </div>
</div>
</div>
@if (showLeadStabilizerUsedQuestions) {
  <div>
    <div class="col-lg-5">
      <p><b>How many lead stabilizer were used?</b>@if (!this.flushReviewForm.controls['noOfStabilizers'].valid) {
      <a class="flush-process-error"> *</a>
    }</p>
    <div class="row">
      <ce-select [disabled]="isSummary" formControlName="noOfStabilizers" class="scope-select" errorMessage='*' placeholder="Select" >
        @for (option of noOfStabilizersOptions; track option) {
          <ce-option [value]="option">
            {{ option }}
          </ce-option>
        }
      </ce-select>
    </div>
  </div>
</div>
}
}
</form>
</div>
<div class="mb-5 row">
  <div class="col-12">
    @if (!isSummary) {
      <app-details-box [header]="'Photos ('+photos.length+')'" [link]="{url: '/start-job/photos', text:'Edit', params: {}, icon:'icon-edit'}" #flushCrewSummary>
        <div>
          <app-photos [cameraEnabled]="false" [isSummary]="isSummary"></app-photos>
        </div>
      </app-details-box>
    }
    @if (isSummary) {
      <app-details-box [header]="'Photos ('+photos.length+')'" #flushCrewSummary>
        <div>
          <app-photos [cameraEnabled]="false" [isSummary]="isSummary"></app-photos>
        </div>
      </app-details-box>
    }
  </div>
</div>
@if (!isSummary) {
  <div class="review-btns row">
    @if (showSaveProgress) {
      <div class="col-12 col-md-6 col-lg-4">
        <ce-button label="Save My Progress"
          [disabled]="!isOnline"
          type="secondary"
          [disabled]="!(isJobDetailsValid && isStructurePhotosValid && this.flushReviewForm.valid) || !isOnline || !showSaveProgress"
          [override]="true"
        (clickEvent)="handleSave()"></ce-button>
      </div>
    }
    <div class="col-12 col-md-3 col-lg-1">
      <ce-button label="Submit"
        [disabled]="!(isJobDetailsValid && isStructurePhotosValid && this.flushReviewForm.valid) || !isOnline || showSaveProgress"
        (clickEvent)="handleSubmit()"
        [override]="true"
      ></ce-button>
    </div>
  </div>
}
<!-- Summary close button  -->
@if (isSummary) {
  <div class="form-group row ">
    <div class="col-auto">
      <ce-button label="Close" (clickEvent)="closeJob()" type="secondary"></ce-button>
    </div>
  </div>
}
<app-modal [showModal]="showingConfirmSubmit" modalHeading="Are you ready to submit this job?"
  (closeDialogClick)="handleModalClose()">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Confirm all the information is correct before submitting your job.</span><br>
        <span>Once you submit your job, a notification will be sent to your management dl with the information and photos taken.</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button type="secondary" label="Cancel" (clickEvent)="handleModalClose()"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button label="Confirm" data-custom-id="FMReviewPage" data-custom-bhvr="COMPLETEPROCESS" (clickEvent)="submitJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showSuccessIcon]="true" [showModal]="showingSubmitResult" modalHeading="Job successfully submitted" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Your job was successfully submitted</span><br>
        <span>The customer will be alerted with the details of the job.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-md-3 col-lg-2">
        <ce-button label="Close" (clickEvent)="closeJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showErrorIcon]="true" [showModal]="showErrorModal" modalHeading="Job Not submitted" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>An error has occurred. Please try submitting your job again.</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3 col-lg-2">
        <ce-button label="Close" (clickEvent)="closeErrorModal()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showModal]="showConfirmSaveProgress" modalHeading="Are you ready to save this Job?"
  (closeDialogClick)="handleModalClose()">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Confirm all the information is correct before saving your job.</span><br>
        <span>Once you submit your job a notification will be sent to the customer.</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button type="secondary" label="Cancel" (clickEvent)="handleModalClose()"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button label="Confirm" data-custom-id="FMReviewPage" data-custom-bhvr="COMPLETEPROCESS" (clickEvent)="submitJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>