<div class="row">
  <div class="col">
    <h3 class="mb-3">Flush Review</h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="body2">{{today | dash}}</p>
  </div>
</div>
@if ((workRequestDetails$ | async); as workRequest) {
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto">
        <b>Source WR#</b>
        <p [ngClass]="{'dash-text': !workRequest?.sourceWrNumber}">{{workRequest?.sourceWrNumber | dash}}</p>
      </div>
      <div class="col-auto">
        <b>Flush WR#</b>
        <p [ngClass]="{'dash-text': !workRequest?.workRequestNo}">{{workRequest?.workRequestNo | dash}}</p>
      </div>
      <!-- <div class="col-auto">
      <b>Flush Description</b>
      <p [ngClass]="{'dash-text': !workRequest?.workRequestName}">{{workRequest?.workRequestName | dash}}</p>
    </div> -->
  </div>
  <div class="row">
    <h4>Flush Information</h4>
  </div>
  <div class="form-group row">
    @if (workRequest?.crmsAppointmentId) {
      <div class="col-12 col-md-6">
        <p>Please provide a description of the work that was performed.</p>
        <!-- <p>{{workRequest?.workRequestName}}</p> -->
      </div>
    }
  </div>
  @if (workRequest?.wmsComments?.length > 0) {
    <div>
      <div class="row">
        <h5>Work History</h5>
      </div>
      <div class="row mb-5">
        <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass]="{'no-highlight' : true}"
          [allowVerticalDataTable]="true" [data]="workRequest?.wmsComments | dash" [columns]="flushCommentsCols"
        [actions]="[]"></app-flush-grid>
      </div>
      <div class="row">
        <h4>Update Description of Work</h4>
      </div>
    </div>
  }
  <div class="row">
    <h5 class="col-12">Update Description of Work</h5>
    @if (workRequest?.appointmentId) {
      <p class="col-12">Description of work</p>
    } @else {
      <p class="col-12">Since this job does not have a CRMS appointment, the option to add/edit the comment is
      disabled</p>
    }
  </div>
  <form [formGroup]="flushReviewForm">
    <div class="mb-5 row no-gutters">
      <div class="col-12 col-md-6" [formGroup]="flushReviewForm">
        <ce-input inputType="textarea" label="Description of work" formControlName="comments" class="form-control"
        [showError]="flushReviewForm?.touched && flushReviewForm?.get('comments')?.errors" [disabled]="!workRequest?.appointmentId || workRequest?.appointmentId == 'NONE'"></ce-input>
      </div>
    </div>
  </form>
</div>
} @else {
  <div class="blank-space"></div>
}
<div class="mb-5 row">
  <div class="col-12">
    <app-details-box [header]="'Flush Media ('+ (photosLength$ | async) +')'"
      #flushCrewSummary>
      <div>
        <app-photos [enableAfterPhotos]="true"></app-photos>
      </div>
    </app-details-box>
  </div>
</div>

<div class="mb-3 row">
  <div class="col-auto style-guide-close-modal-btn">
    <ce-button style="color: #0581C1; text-decoration: underline;" size='large' label="Back" type="link" (clickEvent)="backClick();">
    </ce-button>
  </div>
  <div class="col-auto">
    <ce-button size='large' label="Save" type="primary" (clickEvent)="saveClick();"
    [disabled]="!flushReviewForm.valid"></ce-button>
  </div>
</div>