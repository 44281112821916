import { Component, OnInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, HostListener, ViewChild, inject } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ValidationErrors } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ParkingRestriction } from 'src/app/models/parking-restriction.model';
import { ParkingRestriction as IParkingRestriction} from 'src/app/interfaces/parking-restriction'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { AccessInformation } from 'src/app/models/accessInformation';
import { PointOfContact } from 'src/app/models/pointOfContact';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { AddAccessInformation, AddEmergencyInformation } from 'src/app/app-state/actions/access-info.actions';
import { AccessInfoContact } from 'src/app/models/access-info-contact.model';
import { KendoGrid } from '../structure-info/structure-info.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { CONFIG } from 'src/app/global/config';
import { Location } from '@angular/common';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { BaseService } from 'src/app/services/base/base.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { OptionModel } from 'src/app/common/selectbox/selectbox.component';
import { StructureInformation } from 'src/app/models/structureInformation';
import { AtomicAssetTagsForParkingResPipe } from 'src/app/common/pipes/atomic-asset-tags-for-parking-res.pipe';
import { Structure } from 'src/app/models/structure.model';
import { Banner } from 'src/app/services/banner/banner.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { Feature } from 'src/app/interfaces/feature';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { FlushInformation } from 'src/app/models/flushInformation';
import { FlushInfo } from 'src/app/models/construction-crew/flush-info';
import { AddFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { LaunchCameraModalComponent } from 'src/app/common/launch-camera-modal/launch-camera-modal.component';
import { LoggingService } from 'src/app/services/logging/logging.service';

@Component({
  selector: 'app-access-info',
  templateUrl: './access-info.component.html',
  styleUrls: ['./access-info.component.scss'],
})
export class AccessInfoComponent extends BaseComponent implements OnInit {
  private baseService = inject(BaseService);
  private ccService = inject(CcRequestsService);
  private flushReviewService = inject(FlushReviewService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private formBuilder = inject(FormBuilder);
  private store = inject(Store);
  private changeDetector = inject(ChangeDetectorRef);
  private location = inject(Location);
  private formValidationService = inject(FormValidationService);
  private atomicAssetTagPipe = inject(AtomicAssetTagsForParkingResPipe);
  private masterData = inject(MasterDataService);
  private logger = inject(LoggingService);

  workRequestGlobalId: string;
  accessInfoFormGroup: FormGroup;
  emergencyFormGroup: FormGroup;
  assetTag: string;
  accessInfoFormIsValid = false;
  parkingRestrictionsIsValid = false;
  openParkingDialog = false;
  openEmgRequestDialog = false;
  allCheckbox = false;
  modalHeight;
  isEdit = false;
  defaultStartMinute = -1;
  defaultEndMinute = -1;
  parkingResOpts: BehaviorSubject<any[]>;
  assetTagOpts: Array<Structure>;
  @ViewChildren('modal') modal: QueryList<ElementRef>;
  @ViewChild('launchCameraModalComponent') launchCameraModal: LaunchCameraModalComponent;

  isMobile: boolean;
  parkingResDataGroup: ParkingRestriction[] = [];

  parkingHeader: KendoGrid[] = [];
  errorBanner$ : BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);

  @Select(AppState.getAccessInformation)
  getAccessInfo$: Observable<AccessInformation>;
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  openContactDialog = false;
  addContactIsValid = false;
  isAssetTag = false;
  contactsDataGroup: PointOfContact[] = [];
  contactsDataColumns = [];
  showEmergencyQuestions = false;
  showAccessQuestions = true;
  structuresFound = true;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);

    // In constructor so it runs before top-tracker
    this.checkIsEdit();
  }
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }
  ngOnInit(): void {
    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.FEATURES)
      .subscribe((res: Feature[]) => {
        res.forEach((toggleObj) => {
          if (toggleObj.feature == CONFIG.API.FEATURE_TOGGLES.ACCESS_INFORMATION) {
            if (!toggleObj.isActive) {
              // when it is not active
              this.location.back();
            }
          }
        });
      })
      .unsubscribe();
      
    this.adjustToScreenSize(window.innerWidth);
    const isBTicket = ( this.store
      .selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket ?? '').length > 4

    // Set columns for contacts and parking table
    this.contactsDataColumns = [
      { field: 'name', title: 'Name' },
      { field: 'email', title: 'Email' },
      { field: 'phoneNumber', title: 'Phone Number' },
    ];

    // Default empty banner
    this.errorBanner$.next({
      type: 'error-notification',
      show: false,
      showIcon: false,
      details: {
        header: null,
        body: null
      }
    });

    // Need to get structure to populate flush-proccess nav
    this.getStructureInfo$.pipe(
      map((structureInfo : StructureInformation) : Structure[] => {
        return structureInfo.structures
      }), 
      tap((structures : Structure[]) => {
        if(!structures || structures?.length <= 0) {
          // Falsy or no structures, show error banner pointing to structure-information
          this.errorBanner$.next({
            type: 'error-notification',
            show: true,
            showIcon: true,
            details: {
              header: null,
              body: ["This step requires a structure to be associated to the job."]
            }
          });
        }
      })
    ).subscribe((structures) => {
      if (structures?.length > 0) {
        this.assetTag = structures[0].structureId;
      } else {
        this.structuresFound = false;
      }
    }).unsubscribe();

    if (isBTicket && this.structuresFound) {
      this.showEmergencyQuestions = true;
      this.showAccessQuestions = false;
      this.emergencyFormGroup = new FormGroup({
        isOnLocation: new FormControl('', Validators.required),
        isAccessObtained: new FormControl('', Validators.required)
      });
      this.flushSubscriptions.push(
        this.emergencyFormGroup.valueChanges.subscribe(val => {
          this.openEmgRequestDialog = this.ccService.stringToBoolean(val.isOnLocation) 
            && this.ccService.stringToBoolean(val.isAccessObtained);
          this.showAccessQuestions = (val.isOnLocation == "true" && val.isAccessObtained == "false") || 
                                    (val.isOnLocation == "false" && val.isAccessObtained == "true") || 
                                    (val.isOnLocation == "false" && val.isAccessObtained == "false");
        })
      )
    }
    // Initialize form
    this.accessInfoFormGroup = new FormGroup({
      isSufficientAccess: new FormControl('', Validators.required),
      isOnCriticalRoadway: new FormControl('', Validators.required),
      isConeHivesRequired: new FormControl('', Validators.required),
      isFlagSafetyRequired: new FormControl('', Validators.required),
      isCustomerRequired: new FormControl('', Validators.required),
      isParkingRestrictions: new FormControl('', Validators.required),
      parkingRestrictions: this.formBuilder.array(this.parkingResDataGroup),
      additionalAccessDetails: new FormControl(''),
      contacts: this.formBuilder.array(this.contactsDataGroup),
    });
   
    this.dynamicFormPaths().then(() => {
      this.getDataFromState();
    });
    this.storeStateWhenNavigate();
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }
  
  getDataFromState() {
    this.flushSubscriptions.push(
      this.getAccessInfo$.subscribe((val) => {
        // Initialize data groups
        this.parkingResDataGroup = [];
        for (let i = 0; i < val?.parkingRestrictions?.length; i++) {
          const form = {
            assetTagFacilities: val?.parkingRestrictions[i].assetTagFacilities,
            restriction: val?.parkingRestrictions[i].restriction,
            days: val?.parkingRestrictions[i].days,
            start_time: val?.parkingRestrictions[i].start_time,
            end_time: val?.parkingRestrictions[i].end_time,
          } as ParkingRestriction;

          this.parkingResDataGroup.push(form);
        }

        this.contactsDataGroup = [];
        for (let i = 0; i < val?.contactsDataGroup?.length; i++) {
          const form = {
            name: val?.contactsDataGroup[i].name,
            email: val?.contactsDataGroup[i].email,
            phoneNumber: val?.contactsDataGroup[i].phoneNumber,
          } as PointOfContact;

          this.contactsDataGroup.push(form);
        }

        // Patch state data to form
        this.accessInfoFormGroup.patchValue({
          isSufficientAccess: this.ccService.valueToString(val?.hasSufficientAccess),
          isOnCriticalRoadway: this.ccService.valueToString(val?.isStructureOncriticalRoadway),
          isConeHivesRequired: this.ccService.valueToString(val?.areConeHiveRequired),
          isFlagSafetyRequired: this.ccService.valueToString(val?.areFlaggersRequired),
          isCustomerRequired: this.ccService.valueToString(val?.isCustomerRequired),
          isParkingRestrictions: this.ccService.valueToString(val?.areParkingRestriction),
          additionalAccessDetails: this.ccService.valueToString(val?.additionalAccessDetails),
        });

        this.accessInfoFormGroup.setControl('parkingRestrictions', this.formBuilder.array(this.parkingResDataGroup));
        this.accessInfoFormGroup.setControl('contacts', this.formBuilder.array(this.contactsDataGroup));
      }),
      this.store.select(store => store.AppState.emergencyInfo).subscribe(val => {
        if (!!val) {
          this.emergencyFormGroup.patchValue(val)
        }
      })
    );
    this.formValidationService.setValidity(
      true,
      CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.ACCESS,
      CONFIG.FORMS.PARENT_FORMS.INFORMATION
    );
  }

  storeStateWhenNavigate() {
    this.flushSubscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        // Fires empty store objects without conditional. (items have not loaded yet)
        if (!event.url.includes('access-information')) { 
          // get accessInfo details, then save details to state
          const contactArr: PointOfContact[] = [];
          for (let i = 0; i < this.contactsDataGroup.length; i++) {
            const contact = new PointOfContact();
            contact.name = this.contactsDataGroup[i].name;
            contact.email = this.contactsDataGroup[i].email;
            contact.phoneNumber = this.contactsDataGroup[i].phoneNumber;
            contactArr.push(contact);
          }
          const parkingResArr: ParkingRestriction[] = [];
          for (let i = 0; i < this.parkingResDataGroup.length; i++) {
            const parkingRes = new ParkingRestriction(
              this.parkingResDataGroup[i].assetTagFacilities,
              this.parkingResDataGroup[i].restriction,
              this.parkingResDataGroup[i].days,
              this.parkingResDataGroup[i].start_time,
              this.parkingResDataGroup[i].end_time
            );
            parkingResArr.push(parkingRes);
          }
          const photos: FlushPhoto[] = [];
          const form = new AccessInformation(
            photos,
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isSufficientAccess.value),
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isOnCriticalRoadway.value),
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isConeHivesRequired.value),
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isFlagSafetyRequired.value),
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isCustomerRequired.value),
            this.ccService.stringToBoolean(this.accessInfoFormGroup.controls.isParkingRestrictions.value),
            parkingResArr,
            this.accessInfoFormGroup.controls.additionalAccessDetails.value,
            contactArr,
            null, 
            !this.showEmergencyQuestions ?? this.ccService.stringToBoolean(this.emergencyFormGroup.controls.isOnLocation.value),
            !this.showEmergencyQuestions ?? this.ccService.stringToBoolean(this.emergencyFormGroup.controls.isAccessObtained.value)
          );
          form.isValid = this.accessInfoFormGroup.valid;
          this.store.dispatch(new AddAccessInformation(form));
          if(this.showEmergencyQuestions) {
            const val = this.emergencyFormGroup.value
            this.store.dispatch(new AddEmergencyInformation(val));
          }

          // Set isEdit to false if navigating away
          this.flushReviewService.$isEdit.next(false);
        }
      })
    );

    // Validation
    this.flushSubscriptions.push(
      this.accessInfoFormGroup.valueChanges.subscribe((res) => {
        this.accessInfoFormIsValid = this.accessInfoFormGroup.valid;
        this.parkingRestrictionsIsValid = this.accessInfoFormGroup.controls.parkingRestrictions.valid;
        this.addContactIsValid = this.accessInfoFormGroup.controls.contacts.valid;
        this.defaultToZero();
        this.formValidationService.setValidity(
          this.accessInfoFormGroup.valid,
          CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.ACCESS,
          CONFIG.FORMS.PARENT_FORMS.INFORMATION
        );
      })
    );

    // Add parking restriction header
    this.parkingHeader = [
      { field: 'assetTagFacilities', title: 'Asset Tag(s)' },
      { field: 'restriction', title: 'Restriction' },
      { field: 'days', title: 'Days' },
      { field: 'start_time', title: 'Start Time' },
      { field: 'end_time', title: 'End Time' },
    ];
  }

  async dynamicFormPaths() {
    this.flushSubscriptions.push(
      this.accessInfoFormGroup.valueChanges.subscribe(async (res) => {
        // dynamic path update
        this.modalHeight = this.modal.first?.nativeElement.getBoundingClientRect().height;
        this.changeDetector.detectChanges();

        if (res.isParkingRestrictions == 'true') {
          this.accessInfoFormGroup.controls.parkingRestrictions.setValidators(Validators.required);
          this.accessInfoFormGroup.controls.parkingRestrictions.updateValueAndValidity({ emitEvent: false });
        } else {
          this.accessInfoFormGroup.controls.parkingRestrictions.setValidators(null);
          this.accessInfoFormGroup.controls.parkingRestrictions.updateValueAndValidity({ emitEvent: false });
        }

        if (res.isCustomerRequired == 'true') {
          this.accessInfoFormGroup.controls.contacts.setValidators(Validators.required);
          this.accessInfoFormGroup.controls.contacts.updateValueAndValidity({
            emitEvent: false,
          });
        } else {
          this.accessInfoFormGroup.controls.contacts.setValidators(null);
          this.accessInfoFormGroup.controls.contacts.updateValueAndValidity({
            emitEvent: false,
          });
        }

        await Promise.resolve(1);
      })
    );
  }

  nextClick() {
    this.ccService
      .saveAccessInfo(this.accessInfoFormGroup, this.emergencyFormGroup, this.parkingResDataGroup, this.contactsDataGroup, this.assetTag)
      .subscribe(() => {
        const isEmergencyRequest = (this.emergencyFormGroup?.controls?.isOnLocation?.value == "true") 
          && (this.emergencyFormGroup?.controls?.isAccessObtained?.value == "true")
        if (isEmergencyRequest) {
          this.router.navigateByUrl('/flush-information/review');

        } else {
          this.router.navigateByUrl('/flush-information/flush-process-information');
        }
      });
  }

  previousClick() {
    this.router.navigateByUrl('/flush-information/structure-information');
  }

  takePhoto() {
    this.launchCameraModal.takePhoto();
  }

  // Needed since html does not recognize length as a property
  getCurrentParkingRestrictionIndex() {
    const parkingArrRef = this.accessInfoFormGroup.controls.parkingRestrictions as FormArray;
    return parkingArrRef.length - 1;
  }
  // All parking associated functions
  addParkingRestriction() {
    this.flushSubscriptions.push(
      this.masterData.getCacheItem(CONFIG.MASTER_DATA.PARKING_RULES)
      .subscribe((res: IParkingRestriction[]) => {
        // BehaviorSubject listens to optionArr which sends the options and associative values to the selectbox
        const optionArr = new Array<OptionModel>();
        this.parkingResOpts = new BehaviorSubject<any[]>(optionArr);
        for (const opt of res) {
          const tempOption = new OptionModel();
          tempOption.option = opt.restriction;
          tempOption.value = opt.restriction;
          optionArr.push(tempOption);
        }
      })
    );

    this.getStructureInfo$
      .subscribe((structureInfo) => {
        //Get asset tags from state to be used as options
        this.assetTagOpts = new Array<Structure>();
        structureInfo?.structures?.forEach((structure) => {
          if (this.assetTagOpts.findIndex((ele) => ele.structureId === structure?.structureId) < 0) this.assetTagOpts.push(structure);
        });
      })
      .unsubscribe();

    const parkingGroup = this.formBuilder.group(
      new ParkingRestriction(
        this.formBuilder.array(
          this.assetTagOpts.map(values =>{
            return this.formBuilder.control('')
          })),
        new FormControl('', Validators.required),
        new FormGroup({
          mon: new FormControl(''),
          tue: new FormControl(''),
          wed: new FormControl(''),
          thur: new FormControl(''),
          fri: new FormControl(''),
          sat: new FormControl(''),
          sun: new FormControl(''),
        }),
        new FormGroup({
          hour: new FormControl('', Validators.required),
          minute: new FormControl('', Validators.required),
          ampm: new FormControl('', Validators.required),
        }),
        new FormGroup({
          hour: new FormControl('', Validators.required),
          minute: new FormControl('', Validators.required),
          ampm: new FormControl('', Validators.required),
        })
      )
    );
    const parkingArrRef = this.accessInfoFormGroup.get('parkingRestrictions') as FormArray;
    parkingArrRef.push(parkingGroup);
    this.isAssetTag = (this.assetTagOpts?.length == 1);
    this.defaultStartMinute = -1;
    this.defaultEndMinute = -1;
  }
  popParkingRestriction() {
    const parkingArrRef = this.accessInfoFormGroup.controls.parkingRestrictions as FormArray;
    const r = parkingArrRef.controls as Array<FormGroup>;
    r.pop();
    this.accessInfoFormGroup.controls.parkingRestrictions.updateValueAndValidity({ emitEvent: false });
  }
  addParkingRestrictionsBtn() {
    // Create new formControl for parking restrictions array
    this.addParkingRestriction();
    this.allCheckbox = false;
    this.openParkingDialog = true;
  }
  closeParkingDialog() {
    // Pop last element, the one that has not been saved
    this.popParkingRestriction();
    // close dialog
    this.openParkingDialog = false;
    this.isAssetTag = false;
    this.defaultStartMinute = -1;
    this.defaultEndMinute = -1;
  }
  isAssetTagSelected(arr: Array<string>) : boolean{
    return (arr) ? arr.filter(item => (item)? true : false).length > 0 : false;
  }
  saveParkingDialog() {
    // Update parking restrictions table
    // Ensure atomicAssetTags on save and update accessInfo
    
    const parkingResArr = this.accessInfoFormGroup.controls.parkingRestrictions as FormArray;
    for (let i = this.parkingResDataGroup.length; i < parkingResArr.length; i++) {
      const parkingFormGroup = parkingResArr.controls[i] as FormGroup;
      const days = parkingFormGroup.controls.days as FormGroup;
      const restriction = parkingFormGroup.controls.restriction as FormControl;
      const assetTagFacilities = parkingFormGroup.controls.assetTagFacilities as FormControl;
      let start_time = parkingFormGroup.controls.start_time as FormGroup;
      let end_time = parkingFormGroup.controls.end_time as FormGroup;

      let d = '';
      if (this.allCheckbox) {
        d = 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday';
      } else {
        Object.keys(days.value).forEach((element) => {
          d += days.controls[element].value + ' ';
        });
        d = d.trim();
      }

      let st = '';
      Object.keys(start_time.value).forEach((element) => {
        st += start_time.controls[element].value + ' ';
      });

      let et = '';
      Object.keys(end_time.value).forEach((element) => {
        et += end_time.controls[element].value + ' ';
      });

      const r = restriction.value;
      const atf = (assetTagFacilities.value as Array<string>).filter((val) => {
        return (val) ? true : false;
      });
      const PR = new ParkingRestriction(atf, r, d, st, et);

      this.parkingResDataGroup.push(PR);
    }
    this.parkingResDataGroup = this.atomicAssetTagPipe.transform(this.parkingResDataGroup);
    this.accessInfoFormGroup.setControl('parkingRestrictions', this.formBuilder.array(this.parkingResDataGroup || []));
    // close dialog
    this.openParkingDialog = false;
    this.isAssetTag = false;
    this.defaultStartMinute = -1;
    this.defaultEndMinute = -1;
  }
  // Toggle the all checkbox
  toggleAll() {
    this.allCheckbox = !this.allCheckbox;
  }

  getTopStyle() {
    if (window.innerWidth > 767) {
      return { top: 'calc((100% - ' + this.modalHeight + 'px) / 2)' };
    } else {
      return { top: '0px' };
    }
  }
  get contacts_FA(): FormArray {
    return this.accessInfoFormGroup.get('contacts') as FormArray;
  }
  // Needed since html does not recognize length as a property
  getCurrentContactsIndex() {
    const contactsArrRef = this.accessInfoFormGroup.controls.contacts as FormArray;
    return contactsArrRef.length - 1;
  }
  // All contacts associated functions
  addContact() {
    const contactGroup = this.formBuilder.group(
      new AccessInfoContact(
        new FormControl('', [Validators.required, this.nameValidator]),
        new FormControl('', [Validators.email]),
        new FormControl('', [this.phoneValidator])
      ),
      { validators: this.eitherEmailOrPhone }
    );
    const contactArrRef = this.accessInfoFormGroup.get('contacts') as FormArray;
    contactArrRef.push(contactGroup);
  }

  eitherEmailOrPhone(group: FormGroup): ValidationErrors | null {
    if (group?.controls?.email?.value || group?.controls?.phoneNum?.value) {
      return null;
    } else {
      return {
        eitherEmailOrPhone: 'Either phone or email has to be provided.',
      } as ValidationErrors;
    }
  }

  nameValidator(control: FormControl) {
    // Trim both ends of control value and match any chars in the set
    if (/^[a-zA-Z ,.'-]+$/.test(control.value.trim())) {
      return null;
    } else {
      return {
        nonEmptyValidName: {
          actualName: control.value,
        },
      };
    }
  }
  phoneValidator(control: FormControl) {
    if (control.value == '') {
      return null;
    }

    if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(control.value)) {
      return null;
    } else {
      return {
        phone: {
          actualPhone: control.value,
        },
      };
    }
  }
  popContact() {
    const contactArrRef = this.accessInfoFormGroup.controls.contacts as FormArray;
    const r = contactArrRef.controls as Array<FormGroup>;
    r.pop();
    this.accessInfoFormGroup.controls.contacts.updateValueAndValidity({
      emitEvent: false,
    });
  }
  addContactsBtn() {
    // Create new formControl for parking restrictions array
    this.addContact();
    this.openContactDialog = true;
  }
  closeContactDialog() {
    // Pop last element, the one that has not been saved
    this.popContact();
    // close dialog
    this.openContactDialog = false;
  }
  saveContactDialog() {
    // this.parkingRestrictions

    // Update parking restrictions table
    const contactArr = this.accessInfoFormGroup.controls.contacts as FormArray;
    for (let i = this.contactsDataGroup.length; i < contactArr.length; i++) {
      const contactFormGroup = contactArr.controls[i] as FormGroup;
      const name = contactFormGroup?.controls.name as FormControl;
      const email = contactFormGroup?.controls.email as FormControl;
      const phoneNum = contactFormGroup?.controls.phoneNum as FormControl;

      const POC = new PointOfContact();
      POC.name = name.value;
      POC.email = email.value;
      POC.phoneNumber = phoneNum.value;
      this.contactsDataGroup.push(POC);
    }
    // close dialog
    this.openContactDialog = false;
  }
  deleteContactBtn(item) {
    switch (item.action) {
      case 'delete':
        const index = this.contactsDataGroup.findIndex(
          (element) =>
            element.name == item.value.name &&
            element.email == item.value.email &&
            element.phoneNumber == item.value.phoneNumber
        );
        this.contactsDataGroup.splice(index, 1);

        const contactArrRef = this.accessInfoFormGroup.controls.contacts as FormArray;
        const r = contactArrRef.controls as Array<FormGroup>;
        r.splice(index, 1);
        this.accessInfoFormGroup.controls.contacts.updateValueAndValidity({
          emitEvent: false,
        });
    }
  }

  relayAction(item) {
    // this.popParkingRestriction();
    switch (item.action) {
      case 'delete':
        const index = this.parkingResDataGroup.findIndex(
          (element) =>
            element.days == item.value.days &&
            element.start_time == item.value.start_time &&
            element.restriction == item.value.restriction &&
            element.assetTagFacilities == item.value.assetTagFacilities
        );
        this.parkingResDataGroup.splice(index, 1);
        const parkingArrRef = this.accessInfoFormGroup.controls.parkingRestrictions as FormArray;
        const r = parkingArrRef.controls as Array<FormGroup>;
        r.splice(index, 1);
        this.accessInfoFormGroup.controls.parkingRestrictions.updateValueAndValidity({ emitEvent: false });
    }
  }

  checkIsEdit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.isEdit == 'true') {
        this.flushReviewService.$isEdit.next(true);
        this.isEdit = true;
      } else {
        this.flushReviewService.$isEdit.next(false);
        this.isEdit = false;
      }
    });
  }

  saveClick() {
    if (this.showEmergencyQuestions && this.emergencyFormGroup?.controls?.isAccessObtained?.value == "false") {
      this.nextClick();
    } else {
      this.ccService
      .saveAccessInfo(this.accessInfoFormGroup, this.emergencyFormGroup,this.parkingResDataGroup, this.contactsDataGroup, this.assetTag)
      .subscribe(() => {
        this.router.navigate(['/flush-information/review']);
      });
    }
  }

  goToLink(url: string) {
    this.router.navigate([url]);
  }

  assetTagTileClicked(event) {
    let arr = [...((this.accessInfoFormGroup?.controls?.parkingRestrictions as FormArray)?.controls[this.getCurrentParkingRestrictionIndex()] as FormGroup)?.controls?.assetTagFacilities?.value]
    this.isAssetTag = this.isAssetTagSelected(arr);
  }

  defaultToZero() {
    let end_time = ((this.accessInfoFormGroup?.controls?.parkingRestrictions as FormArray)?.controls[this.getCurrentParkingRestrictionIndex()] as FormGroup)?.controls?.end_time as FormGroup;
    let start_time = ((this.accessInfoFormGroup?.controls?.parkingRestrictions as FormArray)?.controls[this.getCurrentParkingRestrictionIndex()] as FormGroup)?.controls?.start_time as FormGroup;
    if(end_time?.controls?.hour?.valid && !end_time?.controls?.minute?.valid) {
      end_time?.controls?.minute?.setValue("00");
      this.defaultEndMinute = 0;
    }
    if(start_time?.controls?.hour?.valid && !start_time?.controls?.minute?.valid) {
      this.defaultStartMinute = 0;
      start_time?.controls?.minute?.setValue("00");
    }
  }
  modifyAccessInfoForm() {
    this.emergencyFormGroup.get('isAccessObtained').setValue('false');
    this.closeEmgRequestDialog();
  }

  confirmEmergencyForm() {
    this.accessInfoFormGroup.get('isSufficientAccess').setValue(this.ccService.valueToString(true))
    this.accessInfoFormGroup.get('isOnCriticalRoadway').setValue(this.ccService.valueToString(false));   
    this.accessInfoFormGroup.get('isConeHivesRequired').setValue(this.ccService.valueToString(false));   
    this.accessInfoFormGroup.get('isFlagSafetyRequired').setValue(this.ccService.valueToString(false));   
    this.accessInfoFormGroup.get('isCustomerRequired').setValue(this.ccService.valueToString(false));   
    this.accessInfoFormGroup.get('isParkingRestrictions').setValue(this.ccService.valueToString(false));   
    this.accessInfoFormGroup.get('additionalAccessDetails').setValue('REQUEST ASSOCIATED TO BTICKET');   
    let flushInfoForms = [];
    this.getStructureInfo$.pipe(
      map(structureInfo => {
        if(structureInfo.structures.length > 0) {
          flushInfoForms = structureInfo.structures.map(structure => {
            let form = new FlushInformation(false, false, null, null, null, null, false, [], 
              null, null, 'None', false, null, 'BTICKET WITH CREW ON LOCATION AND HAS ACCESS', structure.structureId, true)
            
            return {
              ...form,
              selectedOptionDefaultIndex: Object.assign({}, {
                debrisAmount: undefined,
                infestationType: 0,
                noDebrisPumpReason: undefined,
                noPumpReason: undefined,
                waterAmount: undefined,
                waterDesc: undefined,
              })   
            }
          });
        }        
        return flushInfoForms
      }),
      switchMap(flushInfoArr => 
          forkJoin(flushInfoArr.map(form => this.ccService.saveFlushInfo(form)))
          .pipe(map(res => res)) 
      ),
      catchError(err => {
        this.logger.logException(err);
        return of(null)
      }),
      tap((savedForms: FlushInfo[]) => {
        if (savedForms?.length > 0) {
          this.store.dispatch(new AddFlushInformation(flushInfoForms))
        }
      })
    )
    .subscribe( data => {
      this.closeEmgRequestDialog();
      if(data) {
        this.nextClick();
      }
    })
  }
  closeEmgRequestDialog() {
    this.openEmgRequestDialog = false;
  }
}
