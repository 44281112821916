@if (showBanner) {
  <div class="banner-component banner-{{bannerType}}">
    <div class="row no-gutters">
      <div class="col-3 col-sm-2 col-md-1">
        @if (showIcon) {
          <svg class="icon" width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>00E59AD3-F279-4F9A-AD7C-8B564EA258AC</title>
            <g id="alert" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="alert-inner" transform="translate(-88.000000, -199.000000)" [attr.fill]="iconColor">
                <g id="icon/System/alert" transform="translate(88.000000, 199.000000)">
                  <path d="M3.32481131,5.60010678 C3.89592522,5.93634398 4.08633042,6.67189739 3.75009322,7.24301131 C2.87079522,8.73653827 2.4,10.4359498 2.4,12.2022 C2.4,17.6074583 6.7987417,22.0062 12.204,22.0062 C14.1059229,22.0062 15.9296021,21.4596442 17.499903,20.4475504 C18.0569648,20.0885118 18.7996109,20.2490413 19.1586496,20.806103 C19.5176882,21.3631648 19.3571587,22.1058109 18.800097,22.4648496 C16.8455574,23.7245936 14.5712584,24.4062 12.204,24.4062 C5.4732583,24.4062 0,18.9329417 0,12.2022 C0,10.0042029 0.587101806,7.88496387 1.68190678,6.02538869 C2.01814398,5.45427478 2.75369739,5.26386958 3.32481131,5.60010678 Z M12.3004,17.2588 C12.9764,17.2588 13.5244,17.8068 13.5244,18.4828 C13.5244,19.1588 12.9764,19.7068 12.3004,19.7068 C11.6244,19.7068 11.0764,19.1588 11.0764,18.4828 C11.0764,17.8068 11.6244,17.2588 12.3004,17.2588 Z M12.2034,0 C18.9334851,0 24.4054,5.47260162 24.4054,12.202 C24.4054,14.6213889 23.7375241,16.8639086 22.4929024,18.7620144 C22.1294911,19.3162334 21.3856047,19.4709137 20.8313856,19.1075024 C20.2771666,18.7440911 20.1224863,18.0002047 20.4858976,17.4459856 C21.4737805,15.9394183 22.0054,14.1544054 22.0054,12.202 C22.0054,6.7980176 17.6079343,2.4 12.2034,2.4 C10.0595827,2.4 8.20925202,2.95338635 6.68374282,4.12861769 C6.15873116,4.53307946 5.40524407,4.43535447 5.00078231,3.91034282 C4.59632054,3.38533116 4.69404553,2.63184407 5.21905718,2.22738231 C7.19200254,0.707452303 9.55747599,0 12.2034,0 Z M12.3008,4.858 C12.8972675,4.858 13.3920935,5.29317922 13.485094,5.86335377 L13.5008,6.058 L13.5008,14.814 C13.5008,15.4767417 12.9635417,16.014 12.3008,16.014 C11.7043325,16.014 11.2095065,15.5788208 11.116506,15.0086462 L11.1008,14.814 L11.1008,6.058 C11.1008,5.3952583 11.6380583,4.858 12.3008,4.858 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </svg>
        }
      </div>
      <div class="col-9 col-sm-10 col-md-11">
        <h4 class="mb-1">{{captionHeader}}</h4>
        @for (msg of captionBody; track msg; let i = $index) {
          <p class="mb-0">
            @if (i > 0) {
              <small><i>{{msg}}</i></small>
            }
            @if (i === 0) {
              <strong>{{msg}}</strong>
            }
          </p>
        }
        <ng-content></ng-content>
      </div>
    </div>
  </div>
}