import { Component, OnInit, inject } from '@angular/core';
import { ContractorAuthService } from '../../services/auth/contractor-auth.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
@Component({
  selector: "app-okta-login",
  templateUrl: "./okta-login.component.html",
})
export class OktaLoginComponent implements OnInit {
  private contractorAuthService = inject(ContractorAuthService);
  private logger = inject(LoggingService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    // Call api login endpoint
    this.contractorAuthService.authenticate().subscribe(
      (res) => {
        window.location.replace(res.toString());
      },
      (err) => {
        this.logger.logException(err);
      }
    );
  }
}
