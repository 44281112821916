/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import a from "../../core/Accessor.js";
import { watch as t, initial as s, whenOnce as i } from "../../core/reactiveUtils.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import { cast as p } from "../../core/accessorSupport/decorators/cast.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { subclass as n } from "../../core/accessorSupport/decorators/subclass.js";
import { isLoaded as o, canProjectWithoutEngine as c, load as l } from "../../geometry/projection.js";
import { equals as m } from "../../geometry/support/spatialReferenceUtils.js";
import { ensureType as h, destroyCache as d, findSpatialReference as f, getBasemapThumbnailUrl as u } from "../../support/basemapUtils.js";
let g = class extends a {
  constructor(e) {
    super(e), this._basemapCache = {}, this._loadingProjectionEngine = !1, this.nextBasemap = h("hybrid", this._basemapCache), this.view = null;
  }
  initialize() {
    t(() => this.nextBasemap, e => {
      e && !e.loaded && e.load().catch(() => {});
    }, s);
  }
  destroy() {
    this.view = null, d(this._basemapCache), this._basemapCache = null;
  }
  get _nextBasemapSpatialReferenceTask() {
    return f(this.view, this.nextBasemap);
  }
  get _viewSpatialReferenceLocked() {
    const {
      view: e
    } = this;
    return !e || !("spatialReferenceLocked" in e) || e.spatialReferenceLocked;
  }
  get activeBasemap() {
    return h(this.view?.map?.basemap ?? "topo-vector", this._basemapCache);
  }
  castNextBasemap(e) {
    return h(e, this._basemapCache);
  }
  get state() {
    const {
      view: e
    } = this;
    if (!e?.ready) return "disabled";
    if (this._nextBasemapSpatialReferenceTask.updating) return "disabled";
    const {
      spatialReference: a
    } = this._nextBasemapSpatialReferenceTask;
    return this._viewSpatialReferenceLocked && null != a && !e.spatialReference.equals(a) ? "incompatible-next-basemap" : this._loadingProjectionEngine ? "loading" : "ready";
  }
  async toggle() {
    const {
      activeBasemap: e,
      nextBasemap: a,
      state: t,
      view: s
    } = this;
    if (!s || "disabled" === t || "incompatible-next-basemap" === t) return;
    const r = this._viewSpatialReferenceLocked;
    if (!r) {
      if (await i(() => !this._nextBasemapSpatialReferenceTask.updating), a !== this.nextBasemap || e !== this.activeBasemap) return;
      const {
        spatialReference: t
      } = this._nextBasemapSpatialReferenceTask;
      if (null == t || m(s.spatialReference, t) || o() || c(s.spatialReference, t) || (this._loadingProjectionEngine = !0, await l(), this._loadingProjectionEngine = !1), a !== this.nextBasemap || e !== this.activeBasemap) return;
    }
    s.map.basemap = a, r || null == this._nextBasemapSpatialReferenceTask.spatialReference || m(s.spatialReference, this._nextBasemapSpatialReferenceTask.spatialReference) || (s.spatialReference = this._nextBasemapSpatialReferenceTask.spatialReference), this.nextBasemap = e;
  }
  static getThumbnailUrl(e) {
    return u(e);
  }
};
e([r()], g.prototype, "_loadingProjectionEngine", void 0), e([r({
  readOnly: !0
})], g.prototype, "_nextBasemapSpatialReferenceTask", null), e([r({
  readOnly: !0
})], g.prototype, "_viewSpatialReferenceLocked", null), e([r({
  readOnly: !0
})], g.prototype, "activeBasemap", null), e([r()], g.prototype, "nextBasemap", void 0), e([p("nextBasemap")], g.prototype, "castNextBasemap", null), e([r({
  readOnly: !0
})], g.prototype, "state", null), e([r()], g.prototype, "view", void 0), e([r()], g.prototype, "toggle", null), g = e([n("esri.widgets.BasemapToggle.BasemapToggleViewModel")], g);
const B = g;
export { B as default };