import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';

export class tracker {
  text: string;
  isActive: boolean;
  url: string;
}

@Component({
  selector: 'app-top-tracker',
  templateUrl: './top-tracker.component.html',
})
export class TopTrackerComponent extends BaseComponent implements OnInit {
  private router = inject(Router);
  private styler: StylerService;

  //text
  //isActive
  @Input() isDashboard: boolean = false;
  @Input() trackerArr: Array<tracker>;
  @Output() trackerClicked = new EventEmitter<string>();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    const router = this.router;
    this.styler = styler;

    this.flushSubscriptions.push(
      router.events.subscribe((res: NavigationEnd) => {
        //Find matching url and Set rest to false
        for (let i = 0; i < this.trackerArr?.length; i++) {
          if (res.url?.toLowerCase().indexOf(this.trackerArr[i].url?.toLowerCase()) >= 0) {
            this.trackerArr[i].isActive = true;
          } else {
            this.trackerArr[i].isActive = false;
          }
        }
      })
    );
  }

  ngOnInit(): void {
    //route to active top tracker on initialization
    for (let i = 0; i < this.trackerArr?.length; i++) {
      if (this.trackerArr[i].isActive) {
        this.router.navigateByUrl(this.trackerArr[i].url);
        break;
      }
    }
  }

  selectTopCol(index) {
    this.trackerClicked.emit(this.trackerArr[index].url);
    //change to index url and activate/deactivate
    this.router.navigateByUrl(this.trackerArr[index].url);
    for (let i = 0; i < this.trackerArr.length; i++) {
      this.trackerArr[i].isActive = false;
    }
    this.trackerArr[index].isActive = true;
  }
}
