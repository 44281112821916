import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { trigger, state, style, transition, group, query, animateChild, animate } from '@angular/animations';
import { BaseComponent } from '../base/base.component';
import { filter } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { Observable } from 'rxjs';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { StylerService } from 'src/app/services/styler/styler.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';
import { BaseService } from 'src/app/services/base/base.service';

@Component({
  selector: 'app-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
  styleUrls: ['./sidebar-mobile.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ position: 'fixed', height: '100%', backgroundColor: '#EDEDED' })),
      state('false', style({ height: '0px' })),
      transition('false => true', [group([query('@fade', animateChild()), animate('300ms 75ms ease-in')])]),
      transition('true => false', [
        group([query('@fade', animateChild()), animate('300ms 200ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('500ms 200ms ease-out', style({ opacity: 1 }))]),
      transition('* => void', [animate('500ms 100ms ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SidebarMobileComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private styler: StylerService;
  private baseService = inject(BaseService);
  private store = inject(Store);
  private formValidationService = inject(FormValidationService);
  private route = inject(ActivatedRoute);
  private internetChecker = inject(InternetCheckerService);

  @Input() wrNum = '';
  @Input() wcDesc = '';
  @Input() sourceWrNo = '';
  @Input() bTicket = '';
  @Input() isFlushRequest = false; // if false, then the sidebar is tracking a flush JOB
  @Input() disableAll = false;
  @Input() showFirst = 'job-details';
  isShowingJobDetails = true;
  isShowingStructurePhotos = false;
  isShowingReviewAndSubmit = false;
  isJobDetailsValid = false;
  isStructurePhotosValid = false;
  isAppOnline = true;
  showingConfirmSubmit = false;
  showingSubmitResult = false;
  showNavItems = false;
  submitResult = false;
  isFlushRequestValid = false;
  isFlushRequestBTicket = false;
  isLoading: boolean;
  workRequestNo: string;
  workRequestName: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    const router = this.router;
    this.styler = styler;

    this.flushSubscriptions.push(
      router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((e: ActivationEnd) => {
        const path = e.snapshot.routeConfig.path;
        
        if (path === 'photos') {
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = true;
          this.isShowingReviewAndSubmit = false;
        } else if (path.includes('job-details') || path === 'job-environment') {
          this.isShowingJobDetails = true;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = false;
        } else if (path === 'review') {
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = true;
        }
      }),
      this.internetChecker.getStatus.subscribe((isOnline) => {
        this.isAppOnline = isOnline;
      }),
      this.formValidationService.listenToValidityOfRequest().subscribe(([infoValid, photosValid]) => {
        this.isJobDetailsValid = infoValid;
        this.isStructurePhotosValid = photosValid;
      }),
      this.formValidationService.$flushRequestValid.subscribe((isValid) => {
        this.isFlushRequestValid = isValid;
      }),
      this.formValidationService.$flushSummary.subscribe((isSummary) => {
        this.disableAll = isSummary;

        // If completed and on summary set all to valid (Submitted B-Tickets not returning value and can have 0 photos, handles for scenario and others)
        if (isSummary === true) {
          this.isJobDetailsValid = true;
          this.isFlushRequestValid = true;
          this.isStructurePhotosValid = true;
        }
      })
    );
  }

  ngOnInit(): void {
    // Check if request is bticket or regular flow 
    const isCorrectRole = this.store.selectSnapshot(store => store.AppState.flushUserRole) != 'eo';
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail).bTicket;
    this.isFlushRequestBTicket = isCorrectRole && !!bTicket && bTicket.length > 4;
    if (this.showFirst === 'photos') {
      this.isShowingJobDetails = false;
      this.isShowingStructurePhotos = true;
      this.isShowingReviewAndSubmit = false;
    } else if (this.showFirst === 'job-details') {
      this.isShowingJobDetails = true;
      this.isShowingStructurePhotos = false;
      this.isShowingReviewAndSubmit = false;
    } else if (this.showFirst === 'review') {
      this.isShowingJobDetails = false;
      this.isShowingStructurePhotos = false;
      this.isShowingReviewAndSubmit = true;
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  handleNavigation(route: string[]) {
    if (route[0].includes('review') && (!this.isJobDetailsValid || !this.isStructurePhotosValid)) {
      // if we try to go to review page without filling out the other two forms, dont go navigate
    } else {
      window.scrollTo(0, 0);
      this.router.navigate(route, { relativeTo: this.route });
      this.showNavItems = false;
    }
  }
  handleMobileNav() {
    this.showNavItems = !this.showNavItems;
  }
}
