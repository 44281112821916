<div class="parent">
  <div class="structure-list">
    <div class="form-group row">
      @if (structures?.length > 0) {
        <div class="col-md-6">
          <h3>Structure Information</h3>
          <p>Please confirm or add up to 4 structure locations.</p>
        </div>
      }
      @if (structures?.length < 1) {
        <div class="col-12 col-lg-7">
          <h3>Structure Information</h3>
          <div class="banner-component banner-error">
            <div class="row no-gutters">
              <div class="col-4 col-md-2 col-lg-1">
                <svg class="icon icon-free" width="15px" height="15px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>00E59AD3-F279-4F9A-AD7C-8B564EA258AC</title>
                  <g id="alert" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g style="fill:#FFFFFF" transform="translate(-88.000000, -199.000000)" fill="#000000">
                      <g id="icon/System/alert" transform="translate(88.000000, 199.000000)">
                        <path d="M3.32481131,5.60010678 C3.89592522,5.93634398 4.08633042,6.67189739 3.75009322,7.24301131 C2.87079522,8.73653827 2.4,10.4359498 2.4,12.2022 C2.4,17.6074583 6.7987417,22.0062 12.204,22.0062 C14.1059229,22.0062 15.9296021,21.4596442 17.499903,20.4475504 C18.0569648,20.0885118 18.7996109,20.2490413 19.1586496,20.806103 C19.5176882,21.3631648 19.3571587,22.1058109 18.800097,22.4648496 C16.8455574,23.7245936 14.5712584,24.4062 12.204,24.4062 C5.4732583,24.4062 0,18.9329417 0,12.2022 C0,10.0042029 0.587101806,7.88496387 1.68190678,6.02538869 C2.01814398,5.45427478 2.75369739,5.26386958 3.32481131,5.60010678 Z M12.3004,17.2588 C12.9764,17.2588 13.5244,17.8068 13.5244,18.4828 C13.5244,19.1588 12.9764,19.7068 12.3004,19.7068 C11.6244,19.7068 11.0764,19.1588 11.0764,18.4828 C11.0764,17.8068 11.6244,17.2588 12.3004,17.2588 Z M12.2034,0 C18.9334851,0 24.4054,5.47260162 24.4054,12.202 C24.4054,14.6213889 23.7375241,16.8639086 22.4929024,18.7620144 C22.1294911,19.3162334 21.3856047,19.4709137 20.8313856,19.1075024 C20.2771666,18.7440911 20.1224863,18.0002047 20.4858976,17.4459856 C21.4737805,15.9394183 22.0054,14.1544054 22.0054,12.202 C22.0054,6.7980176 17.6079343,2.4 12.2034,2.4 C10.0595827,2.4 8.20925202,2.95338635 6.68374282,4.12861769 C6.15873116,4.53307946 5.40524407,4.43535447 5.00078231,3.91034282 C4.59632054,3.38533116 4.69404553,2.63184407 5.21905718,2.22738231 C7.19200254,0.707452303 9.55747599,0 12.2034,0 Z M12.3008,4.858 C12.8972675,4.858 13.3920935,5.29317922 13.485094,5.86335377 L13.5008,6.058 L13.5008,14.814 C13.5008,15.4767417 12.9635417,16.014 12.3008,16.014 C11.7043325,16.014 11.2095065,15.5788208 11.116506,15.0086462 L11.1008,14.814 L11.1008,6.058 C11.1008,5.3952583 11.6380583,4.858 12.3008,4.858 Z" id="Shape"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              @if (structures?.length < 1) {
                <div class="col-8 col-md-10 col-lg-11">
                  <p class="mb-2">There is no structure information currently associated with this work component.</p>
                </div>
              }
            </div>
          </div>
          @if (structures?.length < 1) {
            <p>Please confirm or add the structure location. Multiple structures may be added.</p>
          }
        </div>
      }
    </div>
    @if (structures?.length > 0) {
      <div class="form-group row">
        <!-- Main Structure Table -->
        @if (!isMobile) {
          <div class="col">
            <app-flush-grid [isFlushGridSimple]="true" [page]="'StrucutreInfo'" [data]="structures" [displayActionsHeader]="true" [columns]="structuresHeader" [actions]="[{header: 'Customer Owned', caption: '', type: 'checkbox'}, {header: ' ', caption: '', type: 'edit'}, {header: ' ', caption: '', type: 'delete'}]" (actionClicked)="relayAction($event)" [isSelectable]="false" [defaultCheckValue]="false" [removeCheckboxControlAt]="structureIsCustomerOwnedIndex"></app-flush-grid>
          </div>
        }
        <!-- Structure Table in mobile view -->
        @if (isMobile) {
          <div class="col">
            <div class="structures-mobile">
              @for (struct of structures; track struct; let i = $index) {
                <div class="structure">
                  <div class="structure-options-row mt-2 mb-2">
                    @if (struct.status === 'Submitted' && i === 0;) {
                      <div class="flush-grid-action-header">
                        <a href="" style="pointer-events: none;color: grey;" (click)="emptyIfNull(null);"><b>Flush Pending</b></a>
                      </div>
                    }
                    @if (isOnline && struct.status !== 'Submitted') {
                      <a class="btn-flex" (click)="relayAction({action: 'edit', value: struct})"><div class="icon icon-pencil"></div>Edit</a>
                      <a class="btn-danger-link btn-flex" (click)="relayAction({action: 'delete', value: struct})"><div class="icon icon-waste"></div>Remove</a>
                    }
                    @if (!isOnline && struct.status !== 'Submitted') {
                      <a class="btn-flex btn-flex-disabled"><div class="icon icon-pencil"></div>Edit</a>
                      <a class="btn-danger-link btn-flex btn-flex-disabled" ><div class="icon icon-waste"></div>Remove</a>
                    }
                  </div>
                  <div class="structure-row">
                    <small class="body2 mb-0">Type</small>
                    <label>{{struct.type}}</label>
                  </div>
                  <div class="structure-row">
                    <small class="body2 mb-0">Number</small>
                    <label>{{struct.structureId}}</label>
                  </div>
                  <div class="structure-row">
                    <small class="body2 mb-0">Borough</small>
                    <label>{{struct.borough | dash}}</label>
                  </div>
                  <div class="structure-row mb-3">
                    <small class="body2 mb-0">Address</small>
                    <label [ngStyle]="{flex: 1}">{{struct.frontAddress}}</label>
                  </div>
                  <div class="structure-row mb-4">
                    <app-checkbox label="Customer Owned" [value]="true" [formControl]="getMobileStructureFormControl(i)" [isChecked]="getMobileStructureFormControl(i)?.value"></app-checkbox>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
    <div class="row" style="border-bottom: 5px;">
      <div class="col-lg-5 ">
        <ce-button size='large'  type="secondary" label="Add Structure" icon='icon-setup-service' iconDirection="left" textAlign='left' (clickEvent)="addStructure()" [disabled]='isAddStructureDisabled()'></ce-button>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label='Previous' type='secondary' (clickEvent)="previousClick()"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button size='large' label={{labelText}} data-custom-id="CCStructureInfoPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" [disabled]='isNextDisabled()'></ce-button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-4">
        <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
      </div>
    </div>
  </div>
  <!--modal for Add Structure -->
  <app-modal [showModal]="isAddStructure" modalHeading="Add Structure" (closeDialogClick)="closeAddStructure()" [showClose]='true'>
    <div class="container">
      <app-banner [(banner)]="banner$"></app-banner>
      <div class="row">
        <div class="col">
          <p> Search for a structure by asset tag</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-12 col-lg-12" style="max-width: 350px;">
          <ce-input label="Asset Tag" [formControl]="assetTagName" name="assetTagName"></ce-input>
          @if (!assetTagName.value) {
            <div class="search-icon">
              <i class="icon icon-search" [ngClass]="{ 'inactive-icon' : !assetTagName.value }"></i>
            </div>
          }
          @if (assetTagName.value) {
            <div class="search-icon">
              <i class="icon icon-close" (click)="resetAssetTagSearch()"></i>
            </div>
          }
        </div>
      </div>
      @if (multiAddStructureData?.length > 0 && !isMobile) {
        <div >
          <div class="row">
            @if (multiAddStructureData.length > 0) {
              <div class="col-12 col-md-12 col-lg-12">
                <app-flush-grid [isSelectable]="true" [isFlushGridSimple]="true" [data]="multiAddStructureData" [displayActionsHeader]="true" [columns]="multiStructuresHeader" [actions]="[ {header: ' ', caption: '', type: 'structure'}]"  (actionClicked)="relayAction($event)"></app-flush-grid>
              </div>
            }
          </div>
          <br>
            <div class="row">
              <div class="col-auto style-guide-close-modal-btn">
                <ce-button size='large' (clickEvent)="closeAddStructure()" label="Cancel" type="secondary"></ce-button>
              </div>
            </div>
          </div>
        }
        <!--mobile view for multiple Structure-->
        @if (isMobile) {
          <div class="form-group row">
            <div class="col">
              <div class="structure-search-mobile">
                @for (struct of multiAddStructureData; let i=$index; track struct) {
                  <div class="structure" [ngClass]="calculateMobileHighlight(struct)">
                    <div class="structure-row">
                      <small class="body2 mb-0">Type</small>
                      <label>{{struct.facilityType}}</label>
                    </div>
                    <div class="structure-row">
                      <small class="body2 mb-0">Number</small>
                      <label>{{struct.assetTag}}</label>
                    </div>
                    <div class="structure-row">
                      <small class="body2 mb-0">Borough</small>
                      <label>{{struct.borough | dash}}</label>
                    </div>
                    <div class="structure-row">
                      <small class="body2 mb-0">Address</small>
                      <label [ngStyle]="{flex: 1}">{{struct.address}}</label>
                    </div>
                    <div class="structure-row">
                      @if (!(struct?.isFacilityRequestInProgress)) {
                        <small   style="color:#069bd7;font-size: 18px; text-decoration: underline;"  (click)="relayAction({action: 'add', value: struct})">Select</small>
                      } @else {
                        <small class="disabled btn-flex btn-flex-disabled">Flush Pending</small>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </app-modal>
    <!--modal for Edit Structure -->
    <app-modal [showModal]="isEditBtnClicked" modalHeading="Edit Structure" (closeDialogClick)="closeEditStructure()" [showClose]='true'>
      <div class="container" [formGroup]="editStructureForm">
        <div class="row">
          <div class="col">
            <p>You may edit the address of this structure</p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-auto">
            <app-tile [isChecked]="true" [isDisabled]="true">
              <div class="p-4 pr-5">
                <p class="mb-1">{{ editStructureForm?.controls?.currentStructure?.value.type }}</p>
                <h4 class="mb-1 bold">{{ editStructureForm?.controls?.currentStructure?.value?.structureId }}</h4>
                <p class="mb-1" style="max-width: 200px;">{{ editStructureForm?.controls?.currentStructure?.value?.frontAddress }}</p>
              </div>
            </app-tile>
          </div>
        </div>
        @if (editStructureHistory?.length > 0) {
          <div class="row">
            <div class="col">
              <h4>Structure History</h4>
            </div>
          </div>
        }
        @if (editStructureHistory?.length > 0) {
          <div class="row mb-5">
            <div class="col">
              <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{'no-highlight' : true}" [allowVerticalDataTable]="true"
                [data]="editStructureHistory | dash" [columns]="editStructureHistoryCols"
              [actions]="[]"></app-flush-grid>
            </div>
          </div>
        }
        <div class="row">
          <div class="col">
            <h4>Edit Address</h4>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <ce-input formControlName="frontAddress" label="Front Address"></ce-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto style-guide-close-modal-btn">
          <ce-button (clickEvent)="closeEditStructure()" label="Cancel" type="secondary"></ce-button>
        </div>
        <div class="col-auto style-guide-close-modal-btn">
          <ce-button (clickEvent)="saveEditStructure()" label="Save"></ce-button>
        </div>
      </div>
    </app-modal>
  </div>
