import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AddJobDetails } from 'src/app/app-state/actions/job-details.actions';
import { BaseComponent } from 'src/app/common/base/base.component';
import { LaunchCameraModalComponent } from 'src/app/common/launch-camera-modal/launch-camera-modal.component';
import { OptionModel } from 'src/app/common/selectbox/selectbox.component';
import { CONFIG } from 'src/app/global/config';
import { PriorityItem } from 'src/app/interfaces/priority-item';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { JobDetail } from 'src/app/models/jobDetail';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { BaseService } from 'src/app/services/base/base.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { StylerService } from 'src/app/services/styler/styler.service';

@Component({
  selector: 'app-job-environment',
  templateUrl: './job-environment.component.html',
  styleUrls: ['./job-environment.component.scss']
})
export class JobEnvironmentComponent extends BaseComponent implements OnInit, OnDestroy {
  private baseService = inject(BaseService);
  private styler: StylerService;
  private router = inject(Router);
  private store = inject(Store);
  private masterData = inject(MasterDataService);
  private formValidationService = inject(FormValidationService);
  private ccService = inject(CcRequestsService);

  jobDetailsForm: FormGroup;
  electricJobs: BehaviorSubject<any>; // Dropdown options
  showLaunchCameraModal: boolean = false;
  @ViewChild('launchCameraModalComponent') launchCameraModal: LaunchCameraModalComponent;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  ngOnInit(): void {
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail).bTicket;
    // Initialize form
    this.jobDetailsForm = new FormGroup({
      electricConst: new FormControl('', Validators.required),
    });
    combineLatest([
      this.masterData.getCacheItem(CONFIG.MASTER_DATA.PRIORITY_ITEMS) as Observable<PriorityItem[]>,
      this.store.select(store => store.AppState.assignedWRDetails as WorkRequest[])
    ])
      .pipe(
        tap(([priorities, wrs]) => {
          const sortedPriorities = [...priorities]?.sort((a,b) => a.flushPriorityName.localeCompare(b.flushPriorityName));
          const eArr = new Array<OptionModel>();
          this.electricJobs = new BehaviorSubject<any>(eArr);
          for (const opt of sortedPriorities) {
            const tempOption = new OptionModel();
            tempOption.option = opt.flushPriorityName;
            tempOption.value = `${opt.priorityItemID}`;
            tempOption.key = `P${opt.flushPriorityID}`;
            eArr.push(tempOption);
          }
        }),
        switchMap(() => {
          return combineLatest([this.store.selectOnce(store => store.AppState.jobDetails as JobDetail), this.store.selectOnce(store => store.AppState.WorkRequestDetail as WorkRequestDetail)])
        }),
        tap(([val, wrDetails]) => {
          let jobOption = wrDetails?.priorityItem ?? val?.jobPriorityDesc ?? '';
          let eJob = this.electricJobs.getValue()?.find((job) => job.option == jobOption.toUpperCase());

          // Patch Form with current job details
          this.jobDetailsForm.patchValue({
            electricConst: eJob ?? null,
            areaOfWork: val?.jobDescription ?? '',
          });
        }),
        take(1)
      ).subscribe(jobDetails => {
        this.formValidationService.setValidity(
          this.jobDetailsForm.valid,
          CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB,
          CONFIG.FORMS.PARENT_FORMS.INFORMATION
        );
        this.flushSubscriptions.push(
          this.jobDetailsForm.valueChanges.subscribe(() => {
            this.formValidationService.setValidity(
              this.jobDetailsForm.valid,
              CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB,
              CONFIG.FORMS.PARENT_FORMS.INFORMATION
            );
          })
        );
      })
    // Store selected job when navigating away from page
    this.flushSubscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (!event.url.includes('job-environment')) {
            let eJob = this.jobDetailsForm.controls.electricConst.value

            this.store.dispatch(
              new AddJobDetails(
                new JobDetail(
                  eJob?.key,
                  this.jobDetailsForm.controls.electricConst.status === 'VALID'
                    ? eJob?.option
                    : null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  this.jobDetailsForm.valid,
                  0
                )
              )
            );
          }
        })
    );
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }

  nextClick() {
    if (this.jobDetailsForm.valid) {
      let eJob = this.jobDetailsForm.controls.electricConst.value;
      let searchwrflag = this.store.selectSnapshot((store) => store.AppState.searchwrflag);

      this.ccService
        .saveJobDetails('', eJob?.option, eJob?.key, 0, searchwrflag)
        .subscribe(() => {
          this.router.navigateByUrl('/flush-information/structure-information');
        });
    }
  }

  prevClick() {
    this.router.navigate(['flush-information', 'photos']);
  }

  // Show modal once per session
  takePhoto() {
    this.launchCameraModal.takePhoto();
  }
}
