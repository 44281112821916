import { Injectable, inject } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { FlushHttpRequestService } from 'src/app/services/requests/flush-http-request.service';
import { Priority } from 'src/app/interfaces/priority';
import { WorkRequestResponse } from 'src/app/models/work-request-response';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { StructureMenuOptions } from 'src/app/interfaces/structure-menu-options';
import { FacilityResponse } from 'src/app/models/ords/facility-response';
import { AuthService } from '../auth/auth.service';
import { FlushPhoto } from '../../models/flushPhoto';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { flushEndpoints } from '../../../environments/flush-endpoints';
import { FlushReview } from 'src/app/models/flush-review';
import { JobInfo } from 'src/app/models/construction-crew/job-info';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { AccessInfo } from 'src/app/models/construction-crew/access-info';
import { FlushInfo } from 'src/app/models/construction-crew/flush-info';
import { CCReviewInfo } from 'src/app/models/construction-crew/cc-review-info';
import { Photo } from 'src/app/models/photo';
import { FlushRequest } from 'src/app/models/flushRequest';
import { FlushSubtype } from 'src/app/interfaces/flush-subtype';
import { CCRequestDetails } from 'src/app/models/construction-crew/cc-request-details';
import { StructureInformation } from 'src/app/models/structureInformation';
import { DataServiceWorkRequestResponse } from 'src/app/models/data-service-workrequest-response';
import { PostEditStructureHistory, UpdateFlushComments } from 'src/app/models/edit-history';
import { StructureHistoryRequest, StructureHistoryResponse } from 'src/app/models/structure-history';
import { MasterData } from 'src/app/interfaces/master-data';
import { Feature } from 'src/app/interfaces/feature';
import { ParkingRestriction } from 'src/app/interfaces/parking-restriction';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AssociatedWork } from 'src/app/interfaces/associated-work';
import { AssociatedWorkBody } from 'src/app/interfaces/associated-work-body';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { AssignedWorkMetadata } from 'src/app/interfaces/assigned-work-metadata';
import { DashboardRequestsHistory } from 'src/app/interfaces/dashboard-requests-history';
import { UserSettings } from 'src/app/interfaces/user-settings';
import { LeadsAndCrewCodes } from 'src/app/interfaces/leads-and-crew-codes';
import { SurveyService } from '../survey/survey.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private authService = inject(AuthService);
  private flushHttpService = inject(FlushHttpRequestService);
  private surveyService = inject(SurveyService);

  username = new BehaviorSubject<string>(null);
  private settingsModal = new BehaviorSubject<boolean>(false);
  submissionTimeoutModal = new BehaviorSubject<boolean>(false);
  signin$ = new BehaviorSubject<boolean>(false);
  showCancelConfirmation$ = new BehaviorSubject(false);
  $settingsModal = this.settingsModal.asObservable();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {}

  public getUserInfo(): Observable<UserInfo> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<UserInfo>(environment.flushApiBaseUrl + environment.endpoints.users).pipe(
        map(({user, workAssignments, supRequestLists}) => {
          const bUser = user as any
          this.username.next(bUser.userName);
          return {
            workAssignments, 
            user: new User(
              user.flushUserId, 
              true, 
              user.name, 
              user.crewCode, 
              user.flushRoleType, 
              user.email, 
              user.flushRoleType, 
              user.isProvisioned, 
              user.isCrewCodeAssigned,
              user.department,
              user.flushRoleDescription,
              user.setting),
              supRequestLists: supRequestLists ?? []
          };
        })
      );
    }
    return of(null);
  }
  public saveUserSettings(settings: UserSettings) {
    return this.flushHttpService.postFlushRequest(`${environment.flushApiBaseUrl}${environment.endpoints.userpreferences}`, settings)
  }
  public getAssignedWork(body: AssociatedWorkBody): Observable<AssignedWorkMetadata> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.postFlushRequest<AssociatedWorkBody, AssignedWorkMetadata>(
        `${environment.flushApiBaseUrl}${flushEndpoints.assignedWork}`, body
      );
    }
  }

  public getCrewCodeWRs(crewCode: string, excludeCache: boolean = false): Observable<AssociatedWork[]> {
    if (this.authService.isAuthenticated()) {
      let isCache: string = (excludeCache) ? '&excludeCache=true' : '';
      return this.flushHttpService.getFlushData<AssociatedWork[]>(
        `${environment.flushApiBaseUrl }${environment.endpoints.workrequestbycrewcode}?crewCode=${crewCode}${isCache}`
      );
    }
  }
  
  public getSearchWorkrequest(sourceWR: string, role: string) : Observable<AssignedWorkMetadata> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<AssignedWorkMetadata>(
       `${environment.flushApiBaseUrl}${environment.endpoints.assignedWork}?sourcewr=${sourceWR}&role=${role}`
      )
    }
  }

  public getCCRequestHistory(skip: string, take: string, wrNo: string) : Observable<DashboardRequestsHistory> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<DashboardRequestsHistory>(
       `${environment.flushApiBaseUrl}${environment.endpoints.ccRequestHistory}${skip}/${take}/?wrNo=${wrNo}`
      )
    }
  }

  public getEORequestHistory(skip: string, take: string, crewCode: string, flushWrNo: string) : Observable<DashboardRequestsHistory> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<DashboardRequestsHistory>(
       `${environment.flushApiBaseUrl}${environment.endpoints.eoRequestHistory}${skip}/${take}/?crewCode=${crewCode}&&flushWrNo=${flushWrNo}`
      )
    }
  }

  //`${environment.flushApiBaseUrl}${environment.endpoints.submittedWorkrequest}/${skip}/${take}`
  
  public getContractorInfo(): Observable<User> {
    if (this.authService.isAuthenticated() && this.authService.accesstoken) {
      return this.flushHttpService
        .getFlushData<User>(environment.flushOktaApiBaseUrl + environment.endpoints.users)
        .pipe(
          map((user: any) => {
            this.username.next(user.name);
            return user;
          })
        );
    }
    // TO DO: change user provision if they are contractor
    return of(new User('', false, ''));
  }
  public getMasterData(): Observable<MasterData> {
    return this.flushHttpService
    .getFlushData<MasterData>(environment.flushApiBaseUrl + flushEndpoints.data)
  }
  public getUserRoleType(userId: string): Observable<string> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<string>(
        environment.flushApiBaseUrl + environment.endpoints.userRoleByCrewCode + userId
      );
    }
  }
  
  public getWorkRequestDetails(
    armWrNo: string,
    facilityGlobalId: string,
    flushRequestId: string | null,
    requestId: any,    
    workComponentGlobalID: string = ''
  ): Observable<WorkRequestDetail> {
    if (this.authService.isAuthenticated()) {
      if (flushRequestId) {
        return this.flushHttpService.getFlushData<WorkRequestDetail>(
          `${environment.flushApiBaseUrl}${flushEndpoints.ccJobDetails}?armWorkRequestId=${armWrNo}&facilityId=${facilityGlobalId}&flushRequestId=${flushRequestId}&workComponentGlobalID=${workComponentGlobalID}&requestId=${requestId}`
        );
      } else {
        return this.flushHttpService.getFlushData<WorkRequestDetail>(
          `${environment.flushApiBaseUrl}${flushEndpoints.ccJobDetails}?armWorkRequestId=${armWrNo}&facilityId=${facilityGlobalId}&workComponentGlobalID=${workComponentGlobalID}&requestId=${requestId}`
        );
      }
    }
  }

  public getWRInfo(workRequestNo: string): Observable<DataServiceWorkRequestResponse> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<DataServiceWorkRequestResponse>(
        environment.flushApiBaseUrl + environment.endpoints.workrequest + workRequestNo
      );
    }
  }

  public getOrdsFacility(assetTag, zip = null, wrNumber = '') {
    if (this.authService.isAuthenticated) {
      return this.flushHttpService.getFlushData<FacilityResponse>(
        `${environment.flushApiBaseUrl}${environment.endpoints.facilities}?assetTag=${assetTag}${
          zip || zip?.length > 0 ? `&zipcode=${zip}` : ''
        }&wrNumber=${wrNumber}`
      );
    }
  }

  deletePhotos(photos: FlushPhoto[]) {
    return this.flushHttpService.putFlushData<FlushPhoto[], FlushPhoto[]>(
      environment.flushApiBaseUrl + environment.endpoints.photos,
      photos
    );
  }

  public uploadPhotos(flushPhotos: FlushPhoto[]) {
    if (this.authService.isAuthenticated) {
      return this.flushHttpService.postFlushRequest<any, any>(
        environment.flushApiBaseUrl + environment.endpoints.photos,
        flushPhotos
      );
    }
  }

  deleteFlushVideo(imageID: string, mediaID: string) {
    return this.flushHttpService.deleteFlushData(`${environment.flushApiBaseUrl}${environment.endpoints.videos}?imageID=${imageID}&mediaID=${mediaID}`);
  }

  public uploadFlushVideo(flushVideo: FlushPhoto) {
    if (this.authService.isAuthenticated) {
      return this.flushHttpService.postFlushRequest<any, any>(
        environment.flushApiBaseUrl + environment.endpoints.videos,
        flushVideo
      );
    }
  }

  public uploadCCLVideo(flushVideo: FlushPhoto) {
    return this.flushHttpService.uploadCCLVideo(flushVideo);
  }

  public getVideo(id: string, retryResponse: boolean = false) {
    if (this.authService.isAuthenticated) {
      return this.flushHttpService.getFlushData<any>(
        `${environment.commonMediaApiUrl}${environment.endpoints.videos}/${id}?groupservice=flush`, retryResponse
      );
    }
  }

  public deleteVideo(id: string) {
    if (this.authService.isAuthenticated) {
      return this.flushHttpService.deleteFlushData(`${environment.commonMediaApiUrl}${environment.endpoints.videos}/${id}?groupservice=flush`)
    }
  }

  public saveFlushReviewRequest(flushReview: FlushReview) {
    return this.flushHttpService.postFlushRequest<FlushReview, FlushReview>(
      environment.flushApiBaseUrl + environment.endpoints.flushRequests,
      flushReview
    );
  }

  public updateFlushRequestComments(updateComments: UpdateFlushComments) {
    return this.flushHttpService.putFlushData<UpdateFlushComments, any>(
      environment.flushApiBaseUrl + environment.endpoints.flushRequests,
      updateComments
    )
  }

  public getStructureHistory(id: string, structureNumber: string) {
    return this.flushHttpService.getFlushData<StructureHistoryResponse[]>(
      `${environment.flushApiBaseUrl}${environment.endpoints.flushRequests}?structureNumber=${structureNumber}`
    );
  } 

  public getFlushRequest(wrNum: string, structureNumber: string, requestId: string ) {
    return this.flushHttpService.getFlushData<FlushRequest>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}?wrNumber=${wrNum}&structureNumber=${structureNumber}&requestId=${requestId}`
    );
  }
  /* 
    ConstructionCrewRequests Service Methods

    id: identifier for CC work requests that we previously stored in our db
    If id != null then we FIRST hit /ccrequest/{id} to get back FlushInfoId, StructureInfoId, AccessInfoId and jobdetailsId 
      these Id's help us obtain indivdual sections underneath a request of value id
  */
  public getCCRequests(id: string, workComponentGlobalId: string) {
    // HTTP Method: GET
    // Endpoint shape => baseApi/ccrequests/{id}
    /* Response shape => {  
        "FlushStatus": 0,
        "JobDetailsId": 0,
        "AccessInfoId": 0,
        "StructureInfoId": 0,
        "FlushInfoId": 0,
        "Id": 0,
        "CrewCode": "string",
        "WrNumber": "string",
        "WorkRequestId": "string",
        "CreatedOn": "2021-02-09T19:31:14.180Z",
        "UpdatedOn": "2021-02-09T19:31:14.181Z"
      } 
    */
    return this.flushHttpService.getFlushData<CCRequestDetails>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}/${id}?workComponentGlobalId=${workComponentGlobalId}`
    );
  }
  public saveCCRequest(ccReviewInfo: CCReviewInfo) {
    // HTTP Method: POST
    // Endpoint shape => baseApi/ccrequests
    // Body param => ccReviewInfo
    return this.flushHttpService.postFlushRequest<CCReviewInfo, CCReviewInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}`,
      ccReviewInfo
    );
  }
  public deleteCCRequest(id: string) {
    // HTTP Method: DELETE
    // Endpoint shape => baseApi/ccrequests/{id}
    return this.flushHttpService.deleteFlushData<string, Object>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}/${id}`,
      null
    );
  }
  public saveCCJobDetails(jobdetails: JobInfo, id: string, searchwrflag: boolean = false) {
    // HTTP Method: POST
    // Endpoint shape => baseApi/ccrequests/jobdetails
    /* Response shape => {
        "JobType": "string",
        "JobPriority": "string",
        "SubType": "string",
        "SubTypeSelectedIndex": 0,
        "SelectedIndex": 0,
        "JobDescription": "string",
        "CrewCode": "string",
        "WrNumber": "string",
        "WorkRequestId": "string",
        "CreatedOn": "2021-02-10T13:19:02.611Z",
        "UpdatedOn": "2021-02-10T13:19:02.611Z"
      }
    */
    let details = {
      ...jobdetails,
      ...(searchwrflag) && { searchwrflag: searchwrflag }
    }

    return this.flushHttpService.postFlushRequest<JobInfo, JobInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccJobDetails}`,
      details
    );
  }
  public saveCCStructureInfo(structureInfo: StructureInfo) {
    // HTTP Method: POST
    // Endpoint shape => baseApi/ccrequests/structures
    /* Response shape => {
        "FacilityId": "string",
        "FacilityType": "string",
        "Address": "string",
        "IsCustomerOwned": true,
        "StructureNumber": "string",
        "Borough": "string",
        "IsManuallyAdded": true,
        "ZipCode": "string",
        "CrewCode": "string",
        "WrNumber": "string",
        "WorkRequestId": "string",
        "CreatedOn": "2021-02-10T13:40:30.549Z",
        "UpdatedOn": "2021-02-10T13:40:30.549Z"
      }
    */
    return this.flushHttpService.postFlushRequest<StructureInfo, StructureInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccStructures}`,
      structureInfo
    );
  }
  public putCCStructureInfo(structureInfo: StructureInfo) {
    // HTTP Method: PUT
    // Endpoint shape => baseApi/ccrequests/structures?structureInfoId=structureInfoId
    return this.flushHttpService.putFlushData(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccStructures}?structureInfoId=${structureInfo.id}`,
      structureInfo
    );
  }
  public deleteCCStructureInfo(id: string, structureInfoId: number) {
    // HTTP Method: DELETE
    // Endpoint shape => baseApi/ccrequests/structures?structureInfoId=structureInfoId
    return this.flushHttpService.deleteFlushData<StructureInformation, StructureInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccStructures}?structureInfoId=${structureInfoId}`,
      null
    );
  }

  public saveCCAccessInfo(accessInfo: AccessInfo, id: string) {
    // HTTP Method: POST
    // Endpoint shape => baseApi/ccrequests/accessinformation
    /* Response shape => {
      "TruckHasSufficientAccess": true,
      "IsStructureOnCriticalRoadway": true,
      "AreConeHivesRequired": true,
      "FlaggersWorkSafety": true,
      "CustomerRequiredForAccess": true,
      "AnyParkingRestrictionsOnLocation": true,
      "AdditionalAccessDetails": "string",
      "ParkingRestrictions": [
        {
          "Monday": true,
          "Tuesday": true,
          "Wednesday": true,
          "Thursday": true,
          "Friday": true,
          "Saturday": true,
          "Sunday": true,
          "AssetTag": "string",
          "Restriction": "string",
          "ParkingRestrictionStartTime": "string",
          "ParkingRestrictionEndTime": "string"
        }
      ],
      "PointOfContact": [
        {
          "Name": "string",
          "Email": "string",
          "Phone": "string"
        }
      ],
      "CrewCode": "string",
      "WrNumber": "string",
      "WorkRequestId": "string",
      "CreatedOn": "2021-02-10T14:26:46.004Z",
      "UpdatedOn": "2021-02-10T14:26:46.004Z"
    }
    */
    return this.flushHttpService.postFlushRequest<AccessInfo, AccessInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}/${id}${environment.endpoints.ccAccessInfo}`,
      accessInfo
    );
  }

  public saveCCFlushInfo(flushInfo: FlushInfo, id: string) {
    // HTTP Method: POST
    // Endpoint shape => baseApi/ccrequests/flushinformation
    /* Response shape => {
      "CanSeeAllEquipment": true,
      "IsWaterFoundOnStructure": true,
      "CanDewaterStructureUsingPump": true,
      "StructurePumpRestriction": "string",
      "StructureWaterDescription": "string",
      "StructureWaterQuantity": "string",
      "IsDebrisEnvironmentConditionFound": true,
      "StructureDebrisQuantity": "string",
      "StructureInfestation": "string",
      "IsOilPresenseTested": true,
      "ArePearliteBagsFound": true,
      "ArePearliteBagsCompromised": true,
      "AdditionalDetails": "string",
      "StructureDebrisType": "string",
      "CrewCode": "string",
      "WrNumber": "string",
      "WorkRequestId": "string",
      "CreatedOn": "2021-02-10T14:39:58.316Z",
      "UpdatedOn": "2021-02-10T14:39:58.316Z"
    }
    */
    return this.flushHttpService.postFlushRequest<FlushInfo, FlushInfo>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}/${id}${environment.endpoints.CCFlushInfo}`,
      flushInfo
    );
  }

  public cancelCCRequest(wrNumber: string) {
    return this.flushHttpService.deleteFlushData<string, null>(
      `${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}/${wrNumber}`
    );
  }

  public confirmUserRoleByType(userRoleType: string): string {
    userRoleType = userRoleType?.toUpperCase();
    switch(userRoleType) {
      case 'SUP':
        return 'sup';
      case 'EO':
      case 'CN':
        return 'eo'
      case 'CC':
      default: 
        return 'cc';
    }
  }
  public controlSettingsModal(val?: boolean) {
    const prev = this.settingsModal.value;
    this.settingsModal.next(val != undefined ? val : !prev);
  }

  public getOverrides() {
    return this.flushHttpService.getFlushData(`${environment.flushApiBaseUrl}${environment.endpoints.ccRequests}`)
  }

  public getLeadNamesAndCrewCodes(appointmentID): Observable<LeadsAndCrewCodes[]> {
    return this.flushHttpService.getFlushData(`${environment.flushApiBaseUrl}${environment.endpoints.teamLeadsAndCrewcodes}/${appointmentID}`)
  }

  public putApproveOverride(appointmentID, crewCode) {
    return this.flushHttpService.putFlushData<any, any>(
      environment.flushApiBaseUrl + environment.endpoints.overrides  + environment.endpoints.approveOverride + `/${appointmentID}`
    )
  }

  public putRejectOverride(appointmentID, rejectReason) {
    return this.flushHttpService.putFlushData<any, any>(
      environment.flushApiBaseUrl + environment.endpoints.overrides + environment.endpoints.rejectOverride + `/${appointmentID}?comments=${rejectReason}`
    )
  }

  public putChangeCrew(appointmentID, crewCode) {
    return this.flushHttpService.putFlushData<any, any>(
      environment.flushApiBaseUrl + environment.endpoints.overrides + environment.endpoints.changeCrew + `/${appointmentID}?crewCode=${crewCode}`
    )
  }

  public putCloseAppointment(appointmentID) {
    return this.flushHttpService.putFlushData<any, any>(
      environment.flushApiBaseUrl + environment.endpoints.closeAppointment + `/${appointmentID}`
    )
  }

  
  public getReleaseNotes(): Observable<string> {
    if (this.authService.isAuthenticated()) {
      return this.flushHttpService.getFlushData<string>(environment.flushApiBaseUrl + environment.endpoints.releaseNotes, false, true).pipe(
        map((relNotes) => {
          return relNotes;
        })
      );
    }
    return of(null);
  }

  public putReleaseFlag() {
    return this.flushHttpService.putFlushData<any, any>(
      environment.flushApiBaseUrl + environment.endpoints.releaseFlag
    )
  }

  public getSurveyQuestions(email) {
    return this.surveyService.getSurveys(email);
  }  
  public submitSurveyResults(answers) {
    return this.surveyService.addAnswers(answers);
  }  
}
