import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { CardComponent } from './common/card/card.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { LogoutComponent } from './common/logout/logout.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { PhotosComponent } from './pages/photos/photos.component';
import { FlushInfoComponent } from './pages/flush-info/flush-info.component';
import { JobDetailsComponent } from './pages/flush-info/job-details/job-details.component';
import { StructureInfoComponent } from './pages/flush-info/structure-info/structure-info.component';
import { FlushProcessComponent } from './pages/flush-info/flush-process-nav/flush-process/flush-process.component';
import { AccessInfoComponent } from './pages/flush-info/access-info/access-info.component';
import { FlushLandingComponent } from './pages/flush-landing/flush-landing.component';
import { LinksDirectoryComponent } from './pages/links-directory/links-directory.component';
import { OktaLoginComponent } from './common/okta-login/okta-login.component';
import { OktaCallbackComponent } from './common/okta-callback/okta-callback.component';
import { FlushAuthGuard } from './guards/flush-auth.guard';
import { JobDetailsRequestComponent } from './pages/start-job/job-details-request/job-details-request.component';
import { StartJobComponent } from './pages/start-job/start-job.component';
import { ReviewComponent } from './pages/start-job/review/review.component';
import { FlushReviewComponent } from './pages/flush-info/review/review.component';
import { CrewCodeComponent } from './pages/crew-code/crew-code.component';
import { ContractorAuthGuard } from './guards/contractor-auth.guard';
import { FlushRequestComponent } from './pages/flush-request/flush-request.component';
import { FlushJobComponent } from './pages/flush-job/flush-job.component';
import { FlushProcessNavComponent } from './pages/flush-info/flush-process-nav/flush-process-nav.component';
import { JobEnvironmentComponent } from './pages/flush-info/job-environment/job-environment.component';
import { SupervisorHubComponent } from './pages/supervisor-hub/supervisor-hub.component';
import { EditCloseWrComponent } from './pages/supervisor-hub/edit-close-wr/edit-close-wr.component';
import { AxisGuard } from './guards/axis.guard';
import { CallbackGuard } from './guards/callback.guard';

const routerOptions: ExtraOptions = { onSameUrlNavigation: 'reload' };
export const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'contractor-sign-in',
    component: OktaLoginComponent,
  },
  {
    // Explicit handling of callback as defined in okta documentation for parsing tokens
    // https://developer.okta.com/docs/guides/sign-into-spa/angular/handle-callback/
    // microsoft redirect callback
    path: 'auth/login/aad/callback',
    component: OktaCallbackComponent,
    canActivate: [CallbackGuard]
  },
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [FlushAuthGuard, AxisGuard],
  },
  {
    path: 'era-axis',
    component: SupervisorHubComponent,
    canActivate: [FlushAuthGuard, AxisGuard],
  },
  {
    path: 'era-axis/close',
    component: CardComponent,
    canActivate: [FlushAuthGuard],
    children: [
      {
        path: '',
        component: EditCloseWrComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'crew-code',
    component: CardComponent,
    canActivate: [ContractorAuthGuard],
    children: [
      {
        path: '',
        component: CrewCodeComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'flush-information',
    canActivate: [FlushAuthGuard],
    component: FlushInfoComponent,
    children: [
      {
        path: 'job-environment',
        component: JobEnvironmentComponent
      },
      {
        path: 'photos',
        component: PhotosComponent,
      },
      {
        path: 'job-details',
        component: JobDetailsComponent,
      },
      {
        path: 'structure-information',
        component: StructureInfoComponent,
      },
      {
        path: 'flush-process-information',
        component: FlushProcessNavComponent,
      },
      {
        path: 'access-information',
        component: AccessInfoComponent,
      },
      {
        path: 'review',
        component: FlushReviewComponent,
      },
    ],
  },
  {
    path: 'start-job',
    component: StartJobComponent,
    canActivate: [FlushAuthGuard],
    children: [
      {
        path: 'job-details-request',
        component: JobDetailsRequestComponent,
      },
      {
        path: 'photos',
        component: PhotosComponent,
      },
      {
        path: 'review',
        component: ReviewComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'flush-request/:id',
    component: CardComponent,
    children: [
      {
        path: '',
        component: FlushRequestComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'flush-job/:id',
    component: CardComponent,
    children: [
      {
        path: '',
        component: FlushJobComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'style-guide',
    component: CardComponent,
    children: [
      {
        path: '',
        component: StyleGuideComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'flush-landing',
    component: CardComponent,
    canActivate: [FlushAuthGuard],
    children: [
      {
        path: '',
        component: FlushLandingComponent,
        outlet: 'card',
      },
    ],
  },
  {
    path: 'links-directory',
    component: LinksDirectoryComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    redirectTo: 'requests',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'requests',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
