<div class="padding-around">
  <div class="row">
    <div class="col">
      <h3 class="mb-4">Flush Performed</h3>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-2">
      <b>Source WR#</b>
      <p [ngClass]="{'dash-text': !wrData?.sourceWrNumber}">{{wrData?.sourceWrNumber | dash}}</p>
    </div>
    <div class="col-md-3">
      <b>Flush WR#</b>
      <p [ngClass]="{'dash-text': !wrData?.workRequestNo}">{{wrData?.workRequestNo | dash}}</p>
    </div>
    <div class="col-md-2">
      <b>Facility ID</b>
      <p [ngClass]="{'dash-text': !wrData?.facilityID}">{{wrData?.facilityID | dash}}</p>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-12">
      <b>Address History:</b>
      @if (addressHistoryData?.length > 0) {
        <app-flush-grid [isSelectable]="false"
          [isFlushGridSimple]="true" [allowVerticalDataTable]="true" [data]="addressHistoryData"
          [columns]="addressHistoryCols" [actions]="[]">
        </app-flush-grid>
      } @else {
        @if (wrData?.concatenatedWRAddress) {
          <p>{{wrData?.concatenatedWRAddress | dash}}</p>
        }
        @if (!wrData?.concatenatedWRAddress) {
          <p>No address documented for this job.</p>
        }
      }
      <ng-template #concatenatedWRAddress>
        @if (wrData?.concatenatedWRAddress) {
          <p>{{wrData?.concatenatedWRAddress | dash}}</p>
        }
        @if (!wrData?.concatenatedWRAddress) {
          <p>No address documented for this job.</p>
        }
      </ng-template>
    </div>
  </div>
  @if (wrData?.hasStrayVoltage !== null && wrData?.hasLeadStabilizerUsed !== null) {
    <div class="form-group row">
      <div class="col-12">
        <b>Has Stray Voltage been found?</b>
        <p>
          {{wrData?.hasStrayVoltage != null ? (wrData?.hasStrayVoltage? 'Yes' : 'No') : 'N/A'}}
        </p>
      </div>
      @if (wrData?.hasStrayVoltage === 'true') {
        <div>
          <div class="col-12">
            <b>Will the crew be conducting repairs?</b>
            <p>
              {{wrData?.isCrewConductingRepairs != null ? (wrData?.isCrewConductingRepairs ? 'Yes' : 'No') : "N/A"}}
            </p>
          </div>
          <div class="col-12">
            <b>Can the original work be performed?</b>
            <p>
              {{wrData?.canOriginalWorkContinue != null ? (wrData?.canOriginalWorkContinue ? 'Yes' : 'No') : "N/A"}}
            </p>
          </div>
        </div>
      }
      <div class="col-12">
        <b>Was a lead stabilizer used?</b>
        <p>
          {{wrData?.hasLeadStabilizerUsed != null ? (wrData?.hasLeadStabilizerUsed  ? 'Yes' : 'No') : "N/A"}}
        </p>
      </div>
      @if (wrData?.hasLeadStabilizerUsed === 'true') {
        <div>
          <div class="col-12">
            <b>How many lead stabilizer were used?</b>
            <p>
              {{wrData?.noOfStabilizers ?? "N/A"}}
            </p>
          </div>
        </div>
      }
    </div>
  }
  <div class="form-group row">
    <div class="col-12">
      <b>Comments History:</b>
      @if (commentHistoryData?.length > 0) {
        <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true"
          [allowVerticalDataTable]="true" [data]="commentHistoryData" [columns]="commentHistoryCols" [actions]="[]">
        </app-flush-grid>
      }
      @if (commentHistoryData?.length < 1 || commentHistoryData == undefined) {
        <p>No comment documented for this job.
        </p>
      }
      <!-- ngfor workRequestComments -->
      <!-- <p [ngClass]="{'dash-text': !wrData?.wR_DESC}">{{wrData?.wR_DESC | dash}}</p> -->
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-6 col-12">
      <p><b>Media</b></p>
    </div>
    <div class="col-lg-6 col-12" style="place-content: end; display: flex;">
      <ce-button label="View Associated Photos" type='primary' (clickEvent)="goToMIAStructure(structureNumber)" size='medium'
      icon="icon-images-multi" iconDirection="left"></ce-button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      @if (allPhotos?.length > 0) {
        <app-photos [cameraEnabled]="false" [isSummary]="true" [enableAfterPhotos]="true">
        </app-photos>
      }
    </div>
  </div>
</div>
