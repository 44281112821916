import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, filter, map, tap, take } from 'rxjs/operators';
import { AddFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { AppState } from 'src/app/app-state/app.state';
import { BaseComponent } from 'src/app/common/base/base.component';
import { CONFIG } from 'src/app/global/config';
import { FlushInformation } from 'src/app/models/flushInformation';
import { Structure } from 'src/app/models/structure.model';
import { StructureInformation } from 'src/app/models/structureInformation';
import { Banner } from 'src/app/services/banner/banner.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { StylerService } from 'src/app/services/styler/styler.service';
import { FlushProcessComponent } from './flush-process/flush-process.component';
import * as _ from 'lodash';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { Feature } from 'src/app/interfaces/feature';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { Photo } from 'src/app/models/photo';
import { BaseService } from 'src/app/services/base/base.service';
@Component({
  selector: 'app-flush-process-nav',
  templateUrl: './flush-process-nav.component.html'
})
export class FlushProcessNavComponent extends BaseComponent implements OnInit {
  private baseService = inject(BaseService);
  private router = inject(Router);
  private ccRequestsService = inject(CcRequestsService);
  private store = inject(Store);
  private styler: StylerService;
  private formValidationService = inject(FormValidationService);
  private activatedRoute = inject(ActivatedRoute);
  private flushReviewService = inject(FlushReviewService);
  private masterData = inject(MasterDataService);


  flushInformation : FlushInformation[];
  structures : Structure[];
  selectedIndex = 0;
  errorBanner$ : BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  isFlushProcessValid : boolean = false;
  isEdit = false;
  @ViewChild(FlushProcessComponent) flushProcessComponent: FlushProcessComponent;
  @Select(AppState.getStructureInformation) getStructureInfo$: Observable<StructureInformation>;
  @Select(AppState.getFlushInformation) getFlushInfo$: Observable<FlushInformation[]>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  location: any;
  allPhotos;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);
 
    super(styler);
    this.styler = styler;


    // In constructor so it runs before top-tracker
    this.checkIsEdit();
  }

  ngOnInit(): void {

    this.flushSubscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        // Fires empty store objects without conditional. (items have not loaded yet)
        if (!event.url.includes('flush-process-information')) {
          this.flushInformation[this.selectedIndex] = this.flushProcessComponent.getFormValues();
          this.store.dispatch(new AddFlushInformation(_.cloneDeep(this.flushInformation)));

          // Set isEdit to false if navigating away
          this.flushReviewService.$isEdit.next(false);
        }
      })
    );

    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.FEATURES)
      .subscribe((res: Feature[]) => {
        res.forEach((toggleObj) => {
          if (toggleObj.feature == CONFIG.API.FEATURE_TOGGLES.FLUSH_INFORMATION) {
            if (!toggleObj.isActive) {
              // when it is not active
              this.location.back();
            }
          }
        });
      })
      .unsubscribe();
      
    // Default empty banner
    this.errorBanner$.next({
      type: 'error-notification',
      show: false,
      showIcon: false,
      details: {
        header: null,
        body: null
      }
    });

    // Need to get structure to populate flush-proccess nav
    this.getStructureInfo$.pipe(
      map((structureInfo : StructureInformation) : Structure[] => {
        return structureInfo.structures
      }), 
      tap((structures : Structure[]) => {
        // Load ui elements depending on length
        this.structures = structures;
        if(this.structures?.length > 1) {
          // Populate next and previous buttons for flush-process pages

        } else if(this.structures?.length === 1) {
          // Single structure does not need next and previos
          
        } else {
          // Falsy or no structures, show error banner pointing to structure-information
          this.errorBanner$.next({
            type: 'error-notification',
            show: true,
            showIcon: true,
            details: {
              header: null,
              body: ["This step requires a structure to be associated to the job."]
            }
          });
        }
      }),
      concatMap((structures : Structure[]) => {
        return this.getFlushInfo$.pipe(
          tap((flushInfoArr : FlushInformation[]) => {
            // Should map flushInfo for structure with the same index
            this.flushInformation = [];
            structures?.forEach((structure : Structure) => {
              // Default
              let tempFlush = flushInfoArr?.find((flushInfo : FlushInformation) => {
                return flushInfo.structureNumber == structure.structureId;
              }) ?? new FlushInformation(null, null, null, null, null, null, null, [], null, null, null, null, null, null, structure.structureId, false); 
              // Push to array
              this.flushInformation.push(JSON.parse(JSON.stringify(tempFlush)));
            });
          })
        )
      })
    ).subscribe(() => {}).unsubscribe();

    this.getStartJobPhotos$.subscribe(photos => {
      this.allPhotos = photos.filter(photo => photo.mediaType != 'Video').map(p => p);
    });

    //Find the first index of empty flush information object for the newly addded structure. This will navigate to fill the newly
    // created flush information object rather than start from the first flush information object.
    const emptyFlushInfoIndex = this.flushInformation.findIndex((flushInfo) => flushInfo.canSeeAllEquipement === null);
    if( emptyFlushInfoIndex !== -1 ) {
      this.selectedIndex = emptyFlushInfoIndex;
    }
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }


  goToLink(url: string){
    this.router.navigate([url]);
  }

  nextClick() {
    this.saveFlushInfo().subscribe(() => {
      this.selectedIndex++;
      if(this.selectedIndex >= this.structures.length) {
        this.selectedIndex--;
        if(this.isEdit) {
          this.goToLink('/flush-information/review');
        } else {
          const isBTicket = ( this.store
            .selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket ?? '').length > 4;
          this.goToLink((this.isFlushProcessValid && this.allPhotos?.length > 0) || (this.isFlushProcessValid && isBTicket) ? '/flush-information/review' : '/flush-information/photos');
        }
      }
    }, (err) => {
      console.error(err);
    });
  }

  previousClick() {
    // Only Save to local state due to null values creating bad requests form incomplete forms
    // Update local refrence, save, then navigate
    this.flushInformation[this.selectedIndex] = this.flushProcessComponent.getFormValues();

    // Save to state 
    this.store.dispatch(new AddFlushInformation(_.cloneDeep(this.flushInformation)));

    this.selectedIndex--;
    if(this.selectedIndex < 0) {
      this.selectedIndex++;
      this.goToLink('/flush-information/access-information');
    }
  }

  saveFlushInfo() {
    // Update local refrence, save, then navigate
    this.flushInformation[this.selectedIndex] = this.flushProcessComponent.getFormValues();

    // Save to state and call api
    this.store.dispatch(new AddFlushInformation(_.cloneDeep(this.flushInformation)));
    return this.ccRequestsService.saveFlushInfo(this.flushProcessComponent.getFormValues())
  }

  setFlushValidity($event) {
    this.flushInformation[this.selectedIndex].isValid = $event;
    this.isFlushProcessValid = this.flushInformation.every((flushInfo) => flushInfo.isValid);
    this.formValidationService.setValidity(
      this.isFlushProcessValid,
      CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.FLUSH,
      CONFIG.FORMS.PARENT_FORMS.INFORMATION
    );
  }

  checkIsEdit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.isEdit === 'true') {
        this.flushReviewService.$isEdit.next(true);
        this.isEdit = true;
        this.isFlushProcessValid = true;
      } else {
        this.flushReviewService.$isEdit.next(false);
        this.isEdit = false;
      }
    });
  }
}
