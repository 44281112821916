<div class="image-flex"
  id="media-container"
[ngStyle]="{
    position: 'absolute', 
    top: '0', 
    bottom: '0', 
    left: '0', 
    right: '0',
    display: isImageLandscape ? 'flex' : 'inherit',
    'justify-content': isImageLandscape ? 'center' : 'initial',
    'flex-direction': isImageLandscape ? 'column' : 'initial'
}">
  @if (!(image?.mediaType === mediaTypes.VIDEO)) {
    <div style="width: 100%; height: 100%;" #photoContainer [ngClass]="{ 'disableElement' : !taggingEnabled}">
      @if (image?.src?.length > 0) {
        <div class="photo-section">
                <img #imageTag [src]="image?.src" alt="image" (click)="addTag($event)" (load)="loadedImage($event)" [ngStyle]="{
                    width: (!isMobile || (isMobile && !isImageLandscape)) ? 'initial': '100%',
                    height: (!isMobile || (isMobile && !isImageLandscape)) ? '100%' : 'initial'
                }">
          </div>
        }
        @if (!(image?.src?.length > 0)) {
          <div #noContent>
            <div>No Content</div>
          </div>
        }
      </div>
    }
  </div>
<div class="tags-overlay" [ngStyle]="{
    'position': showLabels ? 'relative': 'initial',
    'z-index': showLabels ? '100001': '0'
    }">
    @if (showTags) {
      <div class="tags-wrapper">
        @for (tag of renderedTags; track tag; let i = $index) {
<div @fade class="tag" [ngStyle]="{
            'left': tag.posX,
            'top': tag.posY
            }"
            (click)="showTagDetails(i)"
            >
            <span class="sequence">{{i + 1}}</span>
          </div>
        }
        <div class="small-card" [ngClass]="{'hide': !showLabels}" >
          <div class="small-card-header">
            <h4>Labels</h4>
            <span class="labels-close icon-close" (click)="closeLabels()"></span>
          </div>
          <div class="small-card-body">
            @for (label of labels; track label; let j = $index) {
              <span class="badge" (click)="handleLabelSelect(label)"
                [ngClass]="calculateLabelClass(label)"
                >{{label}} <span class="icon icon-close" [ngClass]="calculateLabelClass(label)"></span></span>
              }
            </div>
            <div class="close-small-card">
              <a (click)="closeLabels()">Done</a>
            </div>
          </div>
        </div>
      }
    </div>
    @if (taggingEnabled && !hideCTA && !(image?.mediaType === mediaTypes.VIDEO)) {
      <div class="cta" (click)="handleCTA()">
        <p>Tap anywhere on the photo to add a label</p>
        <p class="body2">(click prompt to close)</p>
      </div>
    }
