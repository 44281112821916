import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreCacheService {

  constructor() { }

  ngAfterviewInit() {
    // For precaching images from workrequests
    document.body.appendChild(this.cache);
  }

  cache = document.createElement("CACHE");

  preloadImage(url) {
    let img = new Image();
    img.src = url;
    img.setAttribute("style", "position:absolute");
    this.cache.appendChild(img);
  }
}
