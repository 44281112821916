<app-banner [(banner)]="errorBanner$">
  <ce-button size="large" type="secondary" label="Add Structure" (clickEvent)="goToLink('/flush-information/structure-information')"></ce-button>
</app-banner>
@if (structures?.length >= 1) {
  <div>
    <app-flush-process [selectedStructure]="structures[selectedIndex]" [selectedFlushProcess]="flushInformation[selectedIndex]" (outputValidity)="setFlushValidity($event)"></app-flush-process>
  </div>
}
@if (structures?.length >= 1) {
  <div class="row">
    <div class="col-auto style-guide-close-modal-btn">
      @if ((selectedIndex == 0) && !isEdit) {
        <ce-button size='large' (clickEvent)="previousClick()" label="Previous" type="secondary"></ce-button>
      }
      @if ((selectedIndex != 0)) {
        <ce-button size='large' (clickEvent)="previousClick()" label="Previous Structure" type="secondary"></ce-button>
      }
    </div>
    <div class="col-auto">
      @if ((selectedIndex != structures.length - 1)) {
        <ce-button size='large' (clickEvent)="nextClick()" label="Next Structure" type="primary" [disabled]="!flushInformation[selectedIndex].isValid"></ce-button>
      }
      @if ((selectedIndex == structures.length - 1) && !isEdit) {
        <ce-button size='large' data-custom-id="CCFlushProcessPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Next" type="primary" [disabled]="!isFlushProcessValid"></ce-button>
      }
      @if ((selectedIndex == structures.length - 1) && isEdit) {
        <ce-button size='large' data-custom-id="CCFlushProcessPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Save" type="primary" [disabled]="!isFlushProcessValid"></ce-button>
      }
      <!-- <ce-button *ngIf="isEdit" (clickEvent)="saveClick()" text="Save" type="primary" [disabled]="!flushInfoFormIsValid"></ce-button> -->
    </div>
  </div>
}
<div class="form-group row">
  <div class="col-12 col-lg-4">
    <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
  </div>
</div>