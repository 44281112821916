import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, inject } from '@angular/core';
import { from, Observable, of, Subscription, throwError } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { MapMarker } from 'src/app/interfaces/map-marker';
import Layer from '@arcgis/core/layers/Layer';
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Widget from "@arcgis/core/widgets/Widget.js";
import Legend from '@arcgis/core/widgets/Legend';
import LayerList from '@arcgis/core/widgets/LayerList';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import config from "@arcgis/core/config.js";
import { catchError, take, tap } from 'rxjs/operators';
import { CONFIG } from 'src/app/global/config';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import Point from "@arcgis/core/geometry/Point";
import { environment } from 'src/environments/environment';
import { MapLayers } from 'src/app/app-state/actions/map.actions';
import { Store } from '@ngxs/store';
import { LoggingService } from '../logging/logging.service';

export interface EsriMap {
  baseMap: string,
  toggleMap?: string,
  mapViewConfig: any
}
export interface PublishedEsriServer {
  name: string,
  type: string, // "Feature" | "Annotation"
  id: number,
  url: string,
  visible: boolean
}
export enum WidgetPosition {
  bottomLeading = 'bottom-leading',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  bottomTrailing = 'bottom-trailing',
  topLeading = 'top-leading',
  topLeft = 'top-left',
  topRight = 'top-right',
  topTrailing = 'top-trailing',
  manual = 'manual',
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private http = inject(HttpClient);
  private store = inject(Store);
  private logger = inject(LoggingService);

  loadDefaultWidgets() {
    // const legend = new Legend({
    //   view: this.mapView
    // })
    const layerList = new LayerList({
      id: 'layers',
      view: this.mapView,
      visible: false
    });
    const baseMapToggle = new  BasemapToggle({
      id: 'basemapToggle',
      view: this.mapView,
      nextBasemap: "satellite"
    });
    // this.addUiWidget(legend, WidgetPosition.bottomRight);
    this.addUiWidget(layerList, WidgetPosition.topLeft);
    this.addUiWidget(baseMapToggle, WidgetPosition.topRight);
  }
  private markers: MapMarker[] = []; // Map Markers as array
  private mapMarkers: Set<string> = new Set(); // Map markers as a set for computation
  private map: Map;
  public mapView: MapView;
  public logicSubscriptions$: Subscription[] = [];
  graphicsLayer: GraphicsLayer;

  $moveTo = new Subject<number[]>();
  $markers = new Subject<MapMarker[]>();
  $removeMarkers = new Subject<MapMarker[]>();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() { }

  initMap(mapContainer: ElementRef,  mapConfig: EsriMap): Observable<boolean | void> {
    // Set path to assets 
    config.assetsPath = "/esri-assets";
    this.map = new Map({ basemap: mapConfig.baseMap });
    //TODO: Make this configurable using mapConfig object
    this.mapView = new MapView({
      center: [-74.006042, 40.745234],
      constraints: {
        minScale: 577790.554289, // z-10
        maxScale: 141.062147 //z-22
      },
      container: mapContainer.nativeElement,
      map: this.map,
      popup: {
        defaultPopupTemplateEnabled: false,
        dockOptions: {
          buttonEnabled: true,
          breakpoint: false
        },
        collapseEnabled: false
      },
      scale: 577790.554289, // 144447.638572, //z-12
      zoom: 10,
      ui: {
        components: ["attribution"]
      }
    });

    return from(this.mapView.when(() => {})
      .then(() => true).catch(function(err) {
        // A rejected view indicates a fatal error making it unable to display.
        // Use the errback function to handle when the view doesn't load properly
        console.error("MapView rejected:", err);
      })).pipe(take(1));
  }
  createEsriInterceptor(layers: PublishedEsriServer[]): Observable<any> {
    return this.getEsriToken()
        .pipe(
          tap(token => {
            config.request.interceptors.push({
              // set the `urls` property to the URL of the FeatureLayer so that this
              // interceptor only applies to requests made to the FeatureLayer URL
              urls: layers.map(l => l?.url),
              // use the BeforeInterceptorCallback to add token to query
              before: function (params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = token['access_token']
              },
            });
          }),
          catchError(err => {
            this.logger.logException(err);
            return null
          })
        )
  }
  loadLayers(layers: PublishedEsriServer[]) {
    // 
    for (let i = 0; i < layers.length; i++) {
      let layer = null;
      const asset = layers[i];
      if (asset?.type === "Feature") {
        layer = new FeatureLayer({
          id: asset.name,
          url: asset.url,
          visible: asset.visible
        });
      } else if (asset?.type === "Annotation") {
        layer = new MapImageLayer({
          id: asset.name,
          url: asset.url,
          sublayers: [
            {
              id: 0,
              visible: true,
              sublayers: [
                {
                  id: 639,
                  visible: asset.visible,
                },
                {
                  id: 622,
                  visible: asset.visible,
                },
                {
                  id: 606,
                  visible: asset.visible,
                },
                {
                  id: 567,
                  visible: asset.visible,
                },
              ],
              labelsVisible: true
            }
          ],
          visible: asset.visible
        })
      }
      if(layer !== null)
        this.map.add(layer);
    }
  }
  loadMapLogic() {
    if (this.mapMarkers && this.mapMarkers.size < 1) {
      this.graphicsLayer = new GraphicsLayer({
        id: 'appts',
        title: "Flush Requests",
        visible: true
      });
      this.map.add(this.graphicsLayer)
    }
    this.logicSubscriptions$.push(
      this.$moveTo.subscribe(([long,lat]) => {
        this.panToCoordinates(lat,long)
        .then(i => {})
        .catch(err => { this.logger.logException(err); });
      }),
      this.$markers.subscribe(markers => {
        for (const marker of markers) {
          let point: Point;
          point =  new Point({ 
            longitude: marker?.longitude,
            latitude: marker?.latitude
          });
          const simpleMarkerSymbol = {
              type: "simple-marker",
              color: marker.color, 
              outline: {
                  color: [255, 255, 255],
                  width: 1
              }
          }
          // Title needed if markers are overlapping
          const popupTemplateDetails = new PopupTemplate(this.createPopupTemplate(marker));
    
          const pointGraphic = new Graphic({
            attributes: {
              name: marker.id
            },
            geometry: point,
            symbol: simpleMarkerSymbol,
            popupTemplate: popupTemplateDetails,
          });
          if (!this.mapMarkers.has(marker.id)) {
            this.addMarker(marker, pointGraphic)
          } else {
            // Point already exists on map, determine if we update the point or keep moving
            if (marker.id === 'CURRENT_LOCATION') {
              // Update location on map
              this.removeMarker(marker);
              this.addMarker(marker,pointGraphic)
            }
          }
        } 
      }),
      this.$removeMarkers.subscribe(markers => {
        markers.forEach(marker => {
          this.removeMarker(marker)
        })
      }))

  }
  addCustomLayer(layer: Layer) {
    this.map.add(layer);
  }
  addUiWidget(widget: Widget, pos: WidgetPosition){
    this.mapView.ui.add(widget, pos);
  }
  removeMarker(marker: MapMarker) {
    const graphic = this.graphicsLayer.graphics.find(g => g.attributes.name === marker.id);
    if (!!graphic) {
      this.mapMarkers.delete(marker.id); // Delete component set 
      this.graphicsLayer.remove(graphic); // Remove from esri map itself
    }
  }
  addMarker(marker: MapMarker, pointGraphic) {
    if(marker.latitude != 0.000000 && marker.latitude){
      this.mapMarkers.add(marker.id)
      this.graphicsLayer.add(pointGraphic);
    }    
  }
  queueNewMarkers(markers: MapMarker[]) {
    let newMarkers = [];
    const s = new Set<string>();
    // Add new markers to set so we can check against existing markers
    for (let i = 0; i < markers.length; i++) {
      s.add(markers[i].id)
    }
    // Loop through existing markers and avoid adding dups into result arr
    for (let i = 0; i < this.markers.length; i++) {
      let newMarker;
      const el = this.markers[i];
      if (s.has(el.id)) {  
        // Existing marker exists in set of new markers, take incoming as the "fresher" one
        newMarker = markers.find(i => i.id === el.id);
        s.delete(el.id); // Delete so we can loop through remaining entries in set
      } else {
        newMarker = { ...el };
      }
      newMarkers.push(newMarker)
    }
    // Add remnaining markers still left in set
    for (const item of s) {
      newMarkers.push(markers.find(i => i.id === item))
    }
    this.markers = newMarkers;    
    this.$markers.next(newMarkers);
  }
  clearMarkers(markers:string[] = []) {
    if (markers?.length  > 0) {
      let removedMarkers: MapMarker[] = [];
      for (let i = 0; i < markers.length; i++) {
        const el = markers[i];
        const idx = this.markers.findIndex(m => m.id === el)

        // Only remove what exists
        if (idx > -1) {
          const marker = this.markers.splice(idx, 1);
          removedMarkers.push(marker[0]); // always removing 1 element at a time
        }
      }
      this.$removeMarkers.next(removedMarkers);
    } else {
      this.$removeMarkers.next(this.markers?.map(i => i));
      this.markers = [];
    }
  }
  queueNewPan(long: number, lat: number) {
    this.$moveTo.next([long,lat]);
  }
  createIconColumnForSortable(obj, iconCount = 2) {
    return {
      header: '',
      value: Array.apply(null, Array(iconCount)).map(() => (obj))
    };
  }
  createPopupTemplate(marker: MapMarker) {
    if (!!marker.wr) {
      return ({
        title: marker?.wr?.wrNo,
        content: `
        <div class="row justify-content-center" style="width: 130px;">
          <div class="col-auto" style="padding-top: 5px; padding-bottom: 5px;">
            <p class="my-1">Flush WR#</p>
            <h5 class="normal">${marker.wr.wrNo}</h5>
            <p class="my-1">Structure ID</p>
            <h5 class="normal">${(marker.wr.structureId?.length > 0) ? marker.wr.structureId : '-'}</h5>
          </div>
        </div>
        `
      })
    } else {
      return {
        title: 'Current Location',
        content: `<div>Current Location</div>`
      }
    }
  }
  postMarker = (items) => {
    if (items.length > 0) {
      this.queueNewMarkers(
        items.map((i, index) => ({
            id: i.itemId,
            longitude: Number.isNaN(i.data[0]['value'][0].longitude) ? NaN : i.data[0]['value'][0].longitude,
            latitude: Number.isNaN(i.data[0]['value'][0].latitude) ? NaN : i.data[0]['value'][0].latitude,
            color: i.data[0]['value'][0].iconColor,
            wr: i["wr"]
        }))
      )
    }
  }
  panToCoordinates(lat: number, long: number) {
    return this.mapView.goTo({
      center: [long, lat],
      zoom: 18
    })
  }
  //App Registration
  // App ID:XluR213Y3YX6fS7l
  // App Secret:bcfe955003ec47cfac5d849407a6897d
  getEsriToken(): Observable<any> {
    const url = new URL('https://arcgisportalgstmob-consolidatededison.msappproxy.net/portal/sharing/rest/oauth2/token');
    url.searchParams.append('client_id',environment.arcFmClientId)
    url.searchParams.append('client_secret', environment.arcFmClientSecret)
    url.searchParams.append('grant_type', 'client_credentials')

    return this.http.get<any>(url.toString())
  }
  toggleConEdData(layers: PublishedEsriServer[], layersToUpdate: PublishedEsriServer[]) {
    const layerIds = layers.map(l => l.name);
    if (layersToUpdate.length === 0) {
      // Toggling back to base map - make all layers invisible
      for (let index = 0; index < layerIds.length; index++) {
        const id = layerIds[index];
        try {
          const currLayer = this.map.findLayerById(id);
          currLayer.visible = false;
        } catch (error) {
          this.logger.logException(
            error,
            `Error trying to set all layers to false`
          );
        }
      }
    } else {
      // Toggling on layers that were saved
      for (let index = 0; index < layerIds.length; index++) {
        const id = layerIds[index];
        try {
          const currLayer = this.map.findLayerById(id);
          currLayer.visible = layersToUpdate.find(l => l.name === id).visible;
        } catch (error) {
          
        }
      }
    }
  }
  toggleLegendWidget(override?: boolean) {
    const widget  = this.mapView.ui.find('layers') as Widget;        
    widget.visible = override === undefined ? !widget.visible : override;

    return widget.visible;
  }
  saveSelectedLayers(layers: PublishedEsriServer[]): Observable<any> {
    // Set the layers that will be seen based on LayerList selections
    const selectionsFromList = layers.map(l => {
      const layer = this.map.findLayerById(l.name);

      return {...l, visible: layer.visible } as PublishedEsriServer;
    })
    return this.store.dispatch(new MapLayers.Set(selectionsFromList));
  }
}
