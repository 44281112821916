import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MapLayers } from 'src/app/app-state/actions/map.actions';
import { CONFIG } from 'src/app/global/config';
import { MapService, PublishedEsriServer } from 'src/app/services/map/map.service';

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html'  
})
export class EsriMapComponent implements OnInit, AfterViewInit, OnDestroy {
  private mapService = inject(MapService);
  private store = inject(Store);

  @Input() layers = CONFIG.ESRI_MAP_SERVERS.ELECTRIC_ASSETS.filter(s => s.visible === true).concat(CONFIG.ESRI_MAP_SERVERS.ELECTRIC_ANNOTATIONS.visible === true ? CONFIG.ESRI_MAP_SERVERS.ELECTRIC_ANNOTATIONS : null ) as PublishedEsriServer[];
  @Input() isSupervisor: boolean = true;
  @ViewChild('mapViewNode', { static: false }) public mapViewEl: ElementRef;
  @Output() mapLoaded = new EventEmitter<boolean>();

  toggle = 'coned'; // base, coned, legend
  isLoading = true;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.mapService.logicSubscriptions$.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.mapService.initMap(this.mapViewEl, {toggleMap: 'satellite', baseMap: 'gray-vector', mapViewConfig: null})
    .pipe(
      take(1),
      tap(init => {
        this.store.dispatch(new MapLayers.Set(this.layers));
        this.mapService.loadMapLogic();
        this.mapService.loadDefaultWidgets();
      }),
      switchMap(layers => this.mapService.createEsriInterceptor(this.layers)),
      tap((layers) => {
        this.isLoading = false;
        this.mapLoaded.emit(true);
      }),
      switchMap(() => this.store.selectOnce(store => store.AppState.mapLayers as PublishedEsriServer[]))
    ).subscribe((r) => {
      this.mapService.loadLayers(this.layers);
    });
  }

  toggleConEdData() {
    if (this.toggle === 'base') {
      // Toggle on con ed data
      this.toggle = 'coned';
      this.store.selectOnce(store => store.AppState.mapLayers as PublishedEsriServer[]).subscribe(layers => {
        // Toggle back selected layers
        this.mapService.toggleConEdData(this.layers, layers);
      });
    } else {
      // Toggling back to basemap
      this.toggle = 'base';
      this.mapService.saveSelectedLayers(this.layers).subscribe(() => {
        // Toggle off widget itself
        this.mapService.toggleLegendWidget(false);
        this.mapService.toggleConEdData(this.layers, []);
      });
    }
  }

  toggleLegendWidget() {
    const current = this.toggle;
    if (current === 'coned' || current === 'base') {
      // Showing coned map data, toggle on legend
      this.mapService.toggleLegendWidget();
      this.toggle = 'legend';
    } else if (current === 'legend') {
      // Toggle off legend
      this.toggle = 'coned';
      this.mapService.saveSelectedLayers(this.layers).subscribe(() => {
        // Toggle off widget itself
        this.mapService.toggleLegendWidget();
      });
    }
  }
}
