import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { trigger, state, style, transition, group, query, animateChild, animate } from '@angular/animations';
import { BaseComponent } from '../base/base.component';
import { filter } from 'rxjs/operators';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { StylerService } from 'src/app/services/styler/styler.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { Store } from '@ngxs/store';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { CONFIG } from 'src/app/global/config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ position: 'fixed', height: '100%', backgroundColor: '#EDEDED' })),
      state('false', style({ height: '0px' })),
      transition('false => true', [group([query('@fade', animateChild()), animate('300ms 75ms ease-in')])]),
      transition('true => false', [
        group([query('@fade', animateChild()), animate('300ms 200ms ease-out', style({ height: '0px' }))]),
      ]),
    ]),
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('500ms 200ms ease-out', style({ opacity: 1 }))]),
      transition('* => void', [animate('500ms 100ms ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SidebarComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private styler: StylerService;
  private formValidationService = inject(FormValidationService);
  private route = inject(ActivatedRoute);
  private store = inject(Store);

  @Input() wrNum = ''; // wrNum will either be a source or flush WR, depending on user role that is logged in in that moment
  @Input() wcDesc = '';
  @Input() bTicket = '';
  @Input() isFlushRequest = false; // if false, then the sidebar is tracking a flush JOB
  @Input() disableAll = false;
  @Input() showFirst = 'job-details';
  isShowingJobDetails = true;
  isShowingStructurePhotos = false;
  isShowingReviewAndSubmit = false;
  isJobDetailsValid = false;
  isStructurePhotosValid = false;
  isFlushRequestValid = false;
  isFlushRequestBTicket = false;
  showingConfirmSubmit = false;
  showingSubmitResult = false;
  submitResult = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    const router = this.router;
    this.styler = styler;

    this.flushSubscriptions.push(
      router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((e: ActivationEnd) => {
        const path = e.snapshot.routeConfig.path;
        if (path === 'photos') {
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = true;
          this.isShowingReviewAndSubmit = false;
          if (!this.isFlushRequestBTicket) {
            this.enterMiddleItem();
          }
        } else if (path.includes('flush-process-information') || path.includes('job-details') || path.includes('access-information') ) {
          this.isShowingJobDetails = true;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = false;
        } else if (path === 'review') {
          this.isShowingJobDetails = false;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = true;
        } else if (path === 'job-environment') {
          this.enterMiddleItem();
          this.isShowingJobDetails = true;
          this.isShowingStructurePhotos = false;
          this.isShowingReviewAndSubmit = false;
        }
      }),
      this.formValidationService.listenToValidityOfRequest().subscribe(([infoValid, photosValid]) => {
        this.isJobDetailsValid = infoValid;
        this.isStructurePhotosValid = photosValid;
      }),
      this.formValidationService.$flushRequestValid.subscribe((isValid) => {
        this.isFlushRequestValid = isValid;
      }),
      this.formValidationService.$flushSummary.subscribe((isSummary) => {
        this.disableAll = isSummary;

        // If completed and on summary set all to valid (Submitted B-Tickets not returning value and can have 0 photos, handles for scenario and others)
        if (isSummary === true) {
          this.isJobDetailsValid = true;
          this.isFlushRequestValid = true;
          this.isStructurePhotosValid = true;
        }
      })
    );
  }

  ngOnInit(): void {
    // Check if request is bticket or regular flow 
    const isCorrectRole = this.store.selectSnapshot(store => store.AppState.flushUserRole) != 'eo';
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail).bTicket;
    this.isFlushRequestBTicket = isCorrectRole && !!bTicket && bTicket.length > 4;
    if (this.showFirst === 'photos') {
      this.isShowingJobDetails = false;
      this.isShowingStructurePhotos = true;
      this.isShowingReviewAndSubmit = false;
      this.enterMiddleItem();
    } else if (this.showFirst === 'job-details') {
      this.isShowingJobDetails = true;
      this.isShowingStructurePhotos = false;
      this.isShowingReviewAndSubmit = false;
    } else if (this.showFirst === 'review') {
      this.isShowingJobDetails = false;
      this.isShowingStructurePhotos = false;
      this.isShowingReviewAndSubmit = true;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  handleNavigation(route: string[]) {
    if (route[0].includes('review') && (!this.isJobDetailsValid || !this.isStructurePhotosValid)) {
      // if we try to go to review page without filling out the other two forms, dont go navigate
    } else {
      window.scrollTo(0, 0);
      if (route[0].includes('job-details')){
        this.router.navigate(['/flush-information/job-details', { isEdit: true }]);
      }else{
        this.router.navigate(route, { relativeTo: this.route });
      }
    }
  }
  enterLastItem() {
    if (!this.isShowingReviewAndSubmit && this.isJobDetailsValid && this.isStructurePhotosValid) {
      document.querySelector('.side-nav-item.middle-item').classList.add('remove-border');
    } else {
      document.querySelector('.side-nav-item.middle-item').classList.remove('remove-border');
    }
  }
  leaveLastItem() {
    if (!this.isShowingReviewAndSubmit && this.isJobDetailsValid && this.isStructurePhotosValid) {
      document.querySelector('.side-nav-item.middle-item').classList.remove('remove-border');
    }
  }
  enterFirstItem() {
    if (this.isShowingReviewAndSubmit) {
      document.querySelector('.side-nav-item.first-item').classList.add('remove-border');
      document.querySelector('.side-nav-item.middle-item').classList.add('hovering');
    } else {
      document.querySelector('.side-nav-item.first-item').classList.remove('remove-border');
      document.querySelector('.side-nav-item.middle-item').classList.remove('hovering');
    }
  }
  leaveFirstItem() {
    if (this.isShowingReviewAndSubmit) {
      document.querySelector('.side-nav-item.first-item').classList.remove('remove-border');
      document.querySelector('.side-nav-item.middle-item').classList.remove('hovering');
    }
  }
  enterMiddleItem() {
    if (this.isShowingReviewAndSubmit) {
      document.querySelector('.side-nav-item.middle-item').classList.add('add-border');
      document.querySelector('.side-nav-item.middle-item').classList.add('hovering');
    } else {
      document.querySelector('.side-nav-item.middle-item')?.classList.remove('add-border');
      document.querySelector('.side-nav-item.middle-item')?.classList.remove('hovering');
    }
  }
  leaveMiddleItem() {
    if (this.isShowingReviewAndSubmit) {
      document.querySelector('.side-nav-item.middle-item').classList.remove('add-border');
      document.querySelector('.side-nav-item.middle-item').classList.remove('hovering');
    }
  }
}
