@if (showModal) {
  <div @modalTrigger class="modal-container">
    <div class="modal-background"></div>
    <!-- <div class="modal" #modal [ngStyle]="getTopStyle()">-->
    <div class="modal" #modal >
      <div class="modal-content-items">
        @if (showClose) {
          <div class="modal-close">
            <span class="icon icon-close modal-close" (click)="closeDialogClick.emit()"></span>
          </div>
        }
        <div class="container">
          <div class="row" [ngClass]="{'no-gutters': showErrorIcon || showSuccessIcon}">
            <div class="col">
              <h4>
                @if (showErrorIcon) {
                  <span class='icon-error modal-heading-icon modal-heading-error'></span>
                }
                @if (showSuccessIcon) {
                  <span class='icon-check modal-heading-icon modal-heading-success'></span>
                }
                @if (showBellIcon) {
                  <span class='icon-bell modal-heading-icon modal-heading-error'></span>
                }
                {{modalHeading}}
              </h4>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <ng-content></ng-content>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
}
