import { Injectable, inject } from '@angular/core';
import { DataServiceWorkRequestResponse } from 'src/app/models/data-service-workrequest-response';
import { WorkRequestEntity } from 'src/app/models/work-request-entity';
import { PhotoService } from '../photo/photo.service';

@Injectable({
  providedIn: 'root'
})
export class EoRequestsService {
  private photoService = inject(PhotoService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() { }

  MapDataServiceWorkRequestResponseToWorkRequestEntity(dsWR: DataServiceWorkRequestResponse) {
    let wrE : WorkRequestEntity = {
      ...dsWR?.entity,
      beforeImages: dsWR?.entity?.images.map((img) => this.photoService.MapFlushPhotoToPhoto(img)).filter((img) => this.photoService.mapPhotoClassificationToStatus(img?.classification) === 'Before') ?? [],
      afterImages: dsWR?.entity?.images.map((img) => this.photoService.MapFlushPhotoToPhoto(img)).filter((img) => this.photoService.mapPhotoClassificationToStatus(img?.classification) === 'After') ?? [],
    };
    return wrE;
  }
}
