import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContractorAuthService } from '../services/auth/contractor-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorAuthGuard  {
  private contractorAuthService = inject(ContractorAuthService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.contractorAuthService.isAuthenticated()) {
      return true;
    } else {
      // this.contractorAuthService.authenticate().subscribe((res) => {
      //   window.location.replace(res.toString());
      // });
      return false;
    }
  }
}
