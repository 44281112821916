import { Injectable, ViewChild, ElementRef, HostListener, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StylerService {
  private router = inject(Router);

  isOverflowed: Subject<boolean> = new Subject<boolean>();
  isMobile: Subject<boolean> = new Subject<boolean>();
  isPhotoReview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.isPhotoReview.subscribe((val) => {
      this.isOverflowed.next(true);
    });
  }
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile.next(innerWidth <= 768 ? true : false);
  }
}
