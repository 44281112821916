import { AfterViewInit, Component, ContentChild, Input, OnInit, TemplateRef, inject } from '@angular/core';
import { LoggingService } from 'src/app/services/logging/logging.service';

export interface SortableItemData { header: string, value: any[] }
export interface SortableItem {
  data: SortableItemData[] | any[] | string;
  itemId: string;
  itemType: string;
  titleName: string;
  titleValue: string;
  itemStatus?: string;
  itemWorkStatus?: string;
}

@Component({
  selector: 'app-sortable',
  templateUrl: './sortable.component.html',
})

export class SortableComponent implements OnInit, AfterViewInit {
  private logger = inject(LoggingService);

  @Input() items: SortableItem[] = [];
  @Input() isNavigable: boolean = true;
  @Input() isAnimationOn: boolean = true;
  @Input() id = '';
  @Input() zones = [];
  @ContentChild('title') title!: TemplateRef<any>;
  @ContentChild('content') content!: TemplateRef<any>;
  @ContentChild('helpMessage') helpMessage!: TemplateRef<any>;
  allowDrag = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { 
    this.id = this.id.length > 0 ? this.id : self.crypto.randomUUID();
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    try {
      document.querySelector(`#${this.id} .sortable`).removeAttribute('style');
    } catch (error) {
      this.logger.logException(error);
    }
  }
  onDragOver(event) {
    // Fired when you move selected tile to a different position
    // console.log(event);
  }

  onDragStart(event) {
    // Fired when item is dragged
    
    if (!this.allowDrag) {
      event.preventDefault();
      return;
    }
  }
  onDragEnd(event) {
    // console.log(event);
  }
  onTouchOfItem(event) { 
    if (this.allowDrag) {
      document.querySelector(`#${this.id} .sortable`).setAttribute('style', 'touch-action: none;');
    } else {
      document.querySelector(`#${this.id} .sortable`).removeAttribute('style');
    }
  }
  dragTargetOnTouchStart(event) {
    this.allowDrag = true;
  }
  dragTargetOnTouchEnd(event) {
    this.allowDrag = false;
  }
  onMouseover(event) {
    this.allowDrag = true;    
  }
  onMouseleave(event) {
    this.allowDrag = false;    
  }
}
