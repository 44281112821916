import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/services/base/base.service';
import { Store, Select } from '@ngxs/store';
import { StylerService } from 'src/app/services/styler/styler.service';
import { AppState } from 'src/app/app-state/app.state';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AssociatedWork } from 'src/app/interfaces/associated-work';
import { AssociatedWorkBody } from 'src/app/interfaces/associated-work-body';
import { Dashboard } from 'src/app/app-state/actions/dashboard-work-requests.actions';
import { User } from 'src/app/models/user.model';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AddUserInfo } from 'src/app/app-state/actions/user-info.actions';
import { AddCrewCode } from 'src/app/app-state/actions/work-request.actions';
import { LoggingService } from 'src/app/services/logging/logging.service';

@Component({
  selector: 'app-crew-code',
  templateUrl: './crew-code.component.html',
})
export class CrewCodeComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private baseService = inject(BaseService);
  private store = inject(Store);
  private styler: StylerService;
  private logger = inject(LoggingService);

  crewCode: string;
  crewCodeForm: FormGroup;
  submitting = false;
  @Select(AppState.getCrewCode) getCrewCode$: Observable<string>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

  }

  ngOnInit(): void {
    this.crewCodeForm = new FormGroup({
      crewCode: new FormControl(
        '',
        [Validators.required, Validators.minLength(1)],
        // async validator
        this.crewCodeValidator(this.baseService)
      ),
    });
    this.getCrewCode$
      .subscribe(
        (res) => {
          if (res?.length > 0) {
            this.crewCode = res;
          }
        },
        (err) => {
          this.logger.logException(
            err,
            `Error occured in crew-code page @ getCrewCode$.subscribe\n${JSON.stringify(
              err
            )}`
          );
        }
      )
      .unsubscribe();
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  submit() {
    // INSERT API SERVICE CALL HERE
    // SET STATE VARIABLES AS WELL
    const crewCode: string = this.crewCodeForm.get('crewCode').value.trim().toUpperCase();

    if (this.crewCodeForm.valid) {
      this.store.select(store => store.AppState.userInfo as UserInfo)
      .pipe(
        filter(userInfo => !!userInfo),
        tap(userInfo => {
          this.getCrewcodeWorkAssignments(crewCode, userInfo.user, true);
        }),
        take(1)
      ).subscribe(()=>{});
    }
  }

  // Get CrewcodeWR's then navigate to dashboard
  getCrewcodeWorkAssignments(crewCode: string, user: User, excludeCache: boolean = false) {
    this.baseService.getCrewCodeWRs(crewCode, excludeCache).pipe(
      tap((workAssignments: AssociatedWork[]) => {
        this.store.dispatch([
          new AddUserInfo({
            user: {...user, crewCode: crewCode},  
            workAssignments: workAssignments
          }), 
          new AddCrewCode(crewCode)
        ]);
      }),
      map((workAssignments: AssociatedWork[]) : AssociatedWorkBody[] => {
        return workAssignments.map(assignment => ({
          role: user.flushRoleType,
          email: user.email,
          crewCode: crewCode,
          workRequestGlobalID: assignment.workRequestGlobalID,
          workComponentGlobalID: assignment.workComponentGlobalID,
          excludeCache: true
        } as AssociatedWorkBody)
      )
      }),
      take(1)
    ).subscribe((assignedWork) => {
      this.store.dispatch(new Dashboard.AddAssignedWork(assignedWork));
      this.router.navigate(['requests']);
    });
  }

  crewCodeValidator(service: BaseService) {
    return (control: FormControl) => {
      // Trim both ends of control value and match any chars in the set
      const inputCrewCode = control.value.trim() as string;
      if (inputCrewCode.length < 1) {
        return null;
      }
      if (/^[A-Z]_[A-Z]+_([0-9]+).*/.test(inputCrewCode.toUpperCase())) {
        return service.getUserRoleType(inputCrewCode).pipe(
          take(1),
          map((res) =>
            res != null
              ? null
              : {
                  notContractorCrewCode: {
                    actualName: control.value.trim(),
                    responseError: 'Not a valid contractor crew code.',
                  },
                }
          )
        );
      } else {
        return of({
          invalidCrewCodeName: {
            actualName: control.value.toUpperCase().trim(),
            responseError: 'Crew code is not in a correct format. (REGION_CREW_ID)',
          },
        });
      }
    };
  }
  getErrorMessages(): string {
    if (!this.crewCodeForm.get('crewCode')?.errors) {
      return '';
    } else if (Object.keys(this.crewCodeForm.get('crewCode')?.errors).length > 1) {
      return 'You must enter your crew code in order to view jobs.';
    } else {
      const [err] = Object.keys(this.crewCodeForm.get('crewCode')?.errors);
      switch (err) {
        case 'notContractorCrewCode':
          return this.crewCodeForm.get('crewCode')?.errors?.notContractorCrewCode?.responseError;
        case 'invalidCrewCodeName':
          return this.crewCodeForm.get('crewCode')?.errors?.invalidCrewCodeName?.responseError;
        default:
          return 'Some error occured. Enter a different crew code.';
      }
    }
  }
}
