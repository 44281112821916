import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlushSubtype } from 'src/app/interfaces/flush-subtype';
import { OptionModel } from 'src/app/common/selectbox/selectbox.component';
import { Observable, BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from '../../../services/base/base.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { NavigationStart } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { AddJobDetails } from 'src/app/app-state/actions/job-details.actions';
import { JobDetail } from 'src/app/models/jobDetail';
import { AppState } from 'src/app/app-state/app.state';
import { StylerService } from 'src/app/services/styler/styler.service';
import { Location } from '@angular/common';
import { CONFIG } from 'src/app/global/config';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { WorkRequestEntity } from 'src/app/models/work-request-entity';
import { Feature } from 'src/app/interfaces/feature';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { PriorityItem } from 'src/app/interfaces/priority-item';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { LoggingService } from 'src/app/services/logging/logging.service';
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private baseService = inject(BaseService);
  private ccService = inject(CcRequestsService);
  private store = inject(Store);
  private styler: StylerService;
  private location = inject(Location);
  private formValidationService = inject(FormValidationService);
  private activatedRoute = inject(ActivatedRoute);
  private flushReviewService = inject(FlushReviewService);
  private masterData = inject(MasterDataService);
  private logger = inject(LoggingService);

  workRequestGlobalId: string;
  jobDetailsForm: FormGroup;
  electricJobs: BehaviorSubject<any>;
  workRequest: WorkRequestEntity;
  jobDetail: JobDetail;
  jobDetails;
  isEdit = false;
  isPrevious = false;
  // Select jobDetails
  @Select(AppState.getJobDetails) getJobDetails$: Observable<JobDetail>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;


    // In constructor so it runs before top-tracker
    this.checkIsEdit();
  }

  ngOnInit(): void {
    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.FEATURES)
      .subscribe((res: Feature[]) => {
        res.forEach((toggleObj) => {
          if (toggleObj.feature == CONFIG.API.FEATURE_TOGGLES.JOB_DETAILS) {
            if (!toggleObj.isActive) {
              // when it is not active
              this.location.back();
            }
          }
        });
      })
      .unsubscribe();

    // Options must be loaded regardless of result of api call below.
    this.initialize();

    // Fetch the job details from application state (Local) only for edit scenario
    if(this.isEdit){
      this.getJobDetails$ .subscribe(
        (res) => {
          this.jobDetail = res;
        },
        (err) => {
          this.logger.logException(err);
        },
        () => {
        }
      );
  
    }


  }
  initialize() {
    this.storeStateWhenNavigate();

    this.masterData.getCacheItem(CONFIG.MASTER_DATA.PRIORITY_ITEMS).pipe(
      tap((priorities) => {
        // BehaviorSubject listens to optionArr which sends the options and associative values to the selectbox
        const sortedPriorities = [...priorities]?.sort((a,b) => a.flushPriorityName.localeCompare(b.flushPriorityName));
        const eArr = new Array<OptionModel>();
        const sArr = new Array<OptionModel>();
        this.electricJobs = new BehaviorSubject<any>(eArr);
        for (const opt of sortedPriorities) {
          const tempOption = new OptionModel();
          tempOption.option = opt.flushPriorityName;
          tempOption.value = `${opt.priorityItemID}`;
          tempOption.key = `P${opt.flushPriorityID}`;
          eArr.push(tempOption);
        }
        // Initialize form
        this.jobDetailsForm = new FormGroup({
          electricConst: new FormControl('', Validators.required),
          subtype: new FormControl(''),
          areaOfWork: new FormControl(''),
        });
      }),
      switchMap(() => {
        return combineLatest([this.getJobDetails$, this.getWorkRequestDetail$])
      }),
      tap(([val, wrDetails]) => {
        const jobOption = this.isEdit || this.isPrevious ? val?.jobPriorityDesc : (wrDetails?.priorityItem || val?.jobPriorityDesc || '');
        let eJob = this.electricJobs.getValue()?.find((job) => job.option == jobOption.toUpperCase());
        // Patch Form with current job details
        this.jobDetailsForm.patchValue({
          electricConst: eJob ?? null,
          areaOfWork: val?.jobDescription ?? '',
        });
      }),
      take(1)
    ).subscribe(jobDetails => {
      this.jobDetails = jobDetails;
      this.formValidationService.setValidity(
        this.jobDetailsForm.valid,
        CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB,
        CONFIG.FORMS.PARENT_FORMS.INFORMATION
      );
      this.flushSubscriptions.push(
        this.jobDetailsForm.valueChanges.subscribe(() => {
          this.formValidationService.setValidity(
            this.jobDetailsForm.valid,
            CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB,
            CONFIG.FORMS.PARENT_FORMS.INFORMATION
          );
        })
      );
    })
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }
  storeStateWhenNavigate() {
    // Subscribe to router to send form values on change
    this.flushSubscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (!event.url.includes('job-details')) {
            let eJob = this.jobDetailsForm.controls.electricConst.value

            this.store.dispatch(
              new AddJobDetails(
                new JobDetail(
                  eJob?.key,
                  this.jobDetailsForm.controls.electricConst.status === 'VALID'
                    ? eJob?.option
                    : null,
                  null,
                  null,
                  null,
                  null,
                  this.jobDetailsForm.controls.areaOfWork.value,
                  this.jobDetailsForm.valid,
                  this.jobDetails.id
                )
              )
            );

            // Set isEdit to false if navigating away
            this.flushReviewService.$isEdit.next(false);
          }
        })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private saveJobDetails(navigateUrl: string): void {
    const eJob = this.jobDetailsForm.controls.electricConst.value;
    const searchwrflag = this.store.selectSnapshot(state => state.AppState.searchwrflag);

    const areaOfWork = this.jobDetailsForm.controls.areaOfWork.value;
    const jobDetailsId = this.isEdit ? this.jobDetails.id : this.jobDetails?.id;

    this.ccService.saveJobDetails(areaOfWork, eJob?.option, eJob?.key, jobDetailsId, searchwrflag)
      .subscribe(() => {
        this.router.navigateByUrl(navigateUrl);
      });
  }

  nextClick() {
    if (!this.jobDetailsForm.valid) return;
    this.saveJobDetails('/flush-information/structure-information');
  }

  saveClick() {
    if (!this.jobDetailsForm.valid) return;
    this.saveJobDetails('/flush-information/review');
  }

  checkIsEdit() {
    this.activatedRoute.params.subscribe((params) => {
      //Flag used to keep track the source (Review & Submit)
      this.isEdit = params.isEdit === 'true';
      this.flushReviewService.$isEdit.next(this.isEdit);

      //Flag used to keep track the source
      this.isPrevious = params.isPrevious === 'true';

    });
  }
}
