<div class="panelbar-wrapper">
  <kendo-panelbar>
    <kendo-panelbar-item [disabled]="true" class="panel-title" title="">
      <ng-template kendoPanelBarItemTitle>
        <div class="container-fluid">
          <div class="row">
            <div class="col-4 pr-0"><b>Source WR#</b></div>
            <div class="col-8"><b>Work Component</b></div>
          </div>
        </div>
      </ng-template>
    </kendo-panelbar-item>
    @for (dataItem of flushData; track dataItem) {
      <kendo-panelbar-item title="">
        <ng-template kendoPanelBarItemTitle>
          <div class="container-fluid">
            <div class="row panel-bar-content">
              <div class="col-4">{{dataItem.wrNo}}</div>
              <div class="col-8">{{dataItem.workComponentDescription | dash}}</div>
            </div>
          </div>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <div class="custom-template">
            <div class="mobile-border-style">
            </div>
            <div class="container-fluid">
              <div class="row panel-bar-content-title">
                <div class="col-4">
                  Source WR#
                </div>
                <div class="col-8">
                  Work Component
                </div>
              </div>
              <div class="row panel-bar-content">
                <div class="col-4">
                  {{dataItem.wrNo}}
                </div>
                {{dataItem.workComponentDescription}}
                <div class="col-8">
                  {{dataItem.workComponentDescription | dash}}
                </div>
              </div>
              <br />
              <div class="row panel-bar-content-title">
                <div class="col-12">
                  Address
                </div>
              </div>
              <div class="row panel-bar-content">
                <div class="col-12">
                  {{dataItem.address}}
                </div>
              </div>
              <br />
              <div class="row panel-bar-content-title">
                <div class="col-4">
                  Date
                </div>
                <div class="col-4">
                  Asset Tag
                </div>
                <div class="col-4">
                  Flush Status
                </div>
              </div>
              <div class="row panel-bar-content">
                <div class="col-4">
                  {{dataItem.date}}
                </div>
                @if (dataItem?.structureId) {
                  <div class="col-4" >
                    @if (userTypeRole.toLowerCase() !== 'cn') {
                      <a (click) = "openMap(dataItem)">{{dataItem.structureId}} </a>
                    } @else {
                      {{dataItem.structureId}}
                    }
                  </div>
                }
                <div class="col-4">
                  {{dataItem.status}}
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-10 panel-bar-actions-wrapper">
                  <a (click) = "navigateToCCInfo(dataItem)">{{dataItem.action.text}}
                    <i class="flush-grid-right-arrow-icon material-icons">chevron_right</i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    }
  </kendo-panelbar>
</div>
