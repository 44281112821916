<div class="select-box-wrapper" (mouseover)="arrowsBtnPressed = false">
  <div class="select-box" [ngClass]="{'disabled': isReadOnly, 'focused': openDropdown, 'errorState': !isValid, 'completeState': isCompleted}"
    (click)="toggleDropDown()" tabindex="0" [attr.aria-placeholder]="placeholder"  appSelectDirective>
    @if (selectedValues &&(selectedValues.length > 0)) {
      <span class="top-select-label"> {{placeholder}} </span>
    }
    @if ((selectedValues.length == 0)) {
      <span class="select-label" [ngClass]="{'focused': openDropdown}">{{ placeholder }}</span>
    }
    @if (selectedValues) {
      <span class="select-label-after" [ngClass]="{'focused': openDropdown}">{{ selectedOptions.join(', ') }}</span>
    }
    <i class="icon icon-caret-button select-box-caret" [ngClass]="{'focused': openDropdown}"></i>
  </div>
  <!-- <small class="form-text small error-msg" [ngClass]="{'hide-selectbox-error': openDropdown}" *ngIf="!isValid && (errorMsg.length > 0)"><i class="icon icon-error"></i>{{ errorMsg }}</small> -->
  <div class="selectbox-relative-selection">
    <div class="options" [ngClass]="{'hidden': !openDropdown}" appSelectDirective>
      <span class="disabled px-3 py-2">-Select all that apply-</span>
      @for (option of options; track option; let i = $index) {
        <span
          class="option px-3 py-2"
          [ngClass]="{'hover' : ((selectedIndexes.includes(i)) || arrowsBtnPressed), 'noHover': arrowsBtnPressed}"
          (click)="onOptionClick(option.value, option.option, i)"
          ><small class="form-text option-icon"><i class="icon icon-check" [ngStyle]="{'opacity' : (selectedIndexes.includes(i))? '1': '0'}"></i></small>{{option.option}}
        </span>
      }
    </div>
  </div>
  <div class="row no-gutters form-text-row">
    <div class="col-auto">
      @if (!isValid && (errorMsg.length > 0)) {
        <i class="icon icon-error error-icon"></i>
      }
    </div>
    <div class="col">
      @if (!isValid && (errorMsg.length > 0)) {
        <small class="form-text small error-msg">{{ errorMsg }}</small>
      }
    </div>
  </div>
</div>
