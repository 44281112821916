import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, ChangeDetectorRef, inject } from '@angular/core';
import { tracker } from 'src/app/common/top-tracker/top-tracker.component';
import { Router, ActivationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { StructureInformation } from 'src/app/models/structureInformation';
import { Photo } from 'src/app/models/photo';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
@Component({
  selector: 'app-flush-info',
  templateUrl: './flush-info.component.html',
  styleUrls: ['./flush-info.component.scss'],
})
export class FlushInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private styler: StylerService;
  private formValidationService = inject(FormValidationService);
  private flushReviewService = inject(FlushReviewService);
  private cdRef = inject(ChangeDetectorRef);
  private store = inject(Store);

  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getWorkComponentGlobalId)
  getWorkComponentGlobalId$: Observable<string>;
  @Select(AppState.getStructureInformation)
  getStructureInformation$: Observable<StructureInformation>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @Select(AppState.getDashboardWorkRequests)
  getDashboardWorkRequests$: Observable<WorkRequestsResponse>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;

  topTracker: Array<tracker> = [];
  isShowingFlushInfo = true;
  isShowingPhotos = false;
  isShowingReview = false;
  isMobile = false;
  showNavItems = false;
  sideNavHeight = 0;
  isEdit = true;
  isSummary = false;
  $router: Subscription;
  @ViewChild('sideNav') sideNav: ElementRef;
  dashboardDetailsEntity: WorkRequestRow;

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }
  isJobDetailsValid = false;
  isStructureInformationValid = false;
  isAccessInformationValid = false;
  isFlushProcessValid = false;
  isPhotoReview = false;
  showing = 'job-details';
  dashboardSideBarDetails = {
    wrNo: '',
    workComponentDescription: '',
    bTicket: ''
  };

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;


    this.flushSubscriptions.push(
      this.styler.isPhotoReview.subscribe((val) => {
        this.isPhotoReview = val;
      }),
      this.router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((e: ActivationEnd) => {
        const path = e.snapshot.routeConfig.path;

        if (path === 'photos') {
          this.isShowingFlushInfo = false;
          this.isShowingPhotos = true;
          this.isShowingReview = false;
          this.showing = 'photos';
        } else if (path === 'job-details' || path === 'job-environment') {
          this.SetTopTrackerRouteActive(path);
          this.isShowingFlushInfo = true;
          this.isShowingPhotos = false;
          this.isShowingReview = false;
          this.showing = path;
        } else if (path === 'review') {
          this.isShowingReview = true;
          this.isShowingFlushInfo = false;
          this.isShowingPhotos = false;
          this.showing = 'review';
        } else if (path === 'access-information') {
          this.SetTopTrackerRouteActive('access-information');
          this.isShowingFlushInfo = true;
          this.isShowingPhotos = false;
          this.isShowingReview = false;
          this.showing = 'access-information';
        } else if (path === 'flush-process-information') {
          this.SetTopTrackerRouteActive('flush-process-information');
          this.isShowingFlushInfo = true;
          this.isShowingPhotos = false;
          this.isShowingReview = false;
          this.showing = 'flush-process-information';
        } else if (path === 'structure-information') {
          this.SetTopTrackerRouteActive('structure-information');
          this.isShowingFlushInfo = true;
          this.isShowingPhotos = false;
          this.isShowingReview = false;
          this.showing = 'structure-information';
        }
      })
    );
  }

  /**
   * Set the status of the link to active based on link user is visiting
   * @param name: path of the link
   */
  private SetTopTrackerRouteActive(name: string) {
    this.topTracker.forEach((x) => {
      if (x.url.includes(name)) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
    });
  }

  ngOnInit(): void {
    // Check if request is bticket or regular flow 
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail).bTicket;
    this.topTracker = [
      !!bTicket && bTicket.length > 4 ? {
        text: 'Job Details',
        isActive: true,
        url: '/flush-information/job-environment',
      } : {
        text: 'Job Details',
        isActive: true,
        url: '/flush-information/job-details',
      },
      {
        text: 'Structure Information',
        isActive: false,
        url: '/flush-information/structure-information',
      },
      {
        text: 'Access Information',
        isActive: false,
        url: '/flush-information/access-information',
      },
      {
        text: 'Flush Information',
        isActive: false,
        url: '/flush-information/flush-process-information',
      },
    ];

    this.isMobile = window.innerWidth <= 768 ? true : false;
    combineLatest([this.getWorkRequestGlobalId$, this.getDashboardWorkRequests$, this.getWorkComponentGlobalId$])
      .pipe(
        take(1)
      ).subscribe(([wrId, dashboardWorkRequests, wcId]) => {
        if(!this.isSummary) {
          this.dashboardDetailsEntity = dashboardWorkRequests?.entities?.find((x) => (x.workRequestGlobalId == wrId) && x.workComponentGlobalID.includes(wcId));
        }        
      })

      this.formValidationService.$flushSummary.subscribe({
        next: (isSummary) => {
          this.isSummary = isSummary;
          this.getWorkRequestDetail$.pipe(take(1)).subscribe((d) => {
            if(this.isSummary) {
              this.dashboardSideBarDetails.wrNo = d.workRequestNo;
              this.dashboardSideBarDetails.workComponentDescription = d.workRequestId;
              this.dashboardSideBarDetails.bTicket = d.bTicket;
            }            
          });
        }
       });
  }

  ngAfterContentInit() {
    this.flushSubscriptions.push(
      this.flushReviewService.$isEdit.subscribe((val) => {
        this.isEdit = val;
      })
    );
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  handleMobileNav() {
    this.showNavItems = !this.showNavItems;
  }
}
