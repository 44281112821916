<div class="side-nav">
  <ul class="side-nav-items">
    <li class="side-nav-header">
      <span><b class="heading">Flush Request</b></span>
      <span>{{isFlushRequest? 'Source WR:' : 'Flush WR:'}} <span>{{wrNum}}</span></span>
      @if (wcDesc) {
        <span>WC: <span>{{wcDesc}}</span></span>
      }
      @if (bTicket) {
        <span>B-Ticket: <span>{{bTicket}}</span></span>
      }
    </li>
    @if (isFlushRequestBTicket) {
      <li class="side-nav-item first-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])" (mouseover)="enterFirstItem()" (mouseleave)="leaveFirstItem()"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])" (mouseover)="enterFirstItem()" (mouseleave)="leaveFirstItem()">Environment Photo</a>@if (isStructurePhotosValid) {
      <span class="icon icon-check-circle"></span>
    }</li>
    <li class="side-nav-item middle-item flex" [ngClass]="{'active': isShowingJobDetails, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['job-environment'])" (mouseover)="enterMiddleItem()" (mouseleave)="leaveMiddleItem()"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(['job-environment'])"  (mouseover)="enterMiddleItem()" (mouseleave)="leaveMiddleItem()">Flush Details</a>@if (isJobDetailsValid) {
    <span class="icon icon-check-circle"></span>
  }</li>
} @else {
  <li class="side-nav-item first-item flex" [ngClass]="{'active': isShowingJobDetails, 'disableElement' : disableAll}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])" (mouseover)="enterFirstItem()" (mouseleave)="leaveFirstItem()"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])"  (mouseover)="enterFirstItem()" (mouseleave)="leaveFirstItem()">Flush Details</a>@if (isJobDetailsValid) {
  <span class="icon icon-check-circle"></span>
}</li>
<li class="side-nav-item middle-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])" (mouseover)="enterMiddleItem()" (mouseleave)="leaveMiddleItem()"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])" (mouseover)="enterMiddleItem()" (mouseleave)="leaveMiddleItem()">Structure Photos</a>@if (isStructurePhotosValid) {
<span class="icon icon-check-circle"></span>
}</li>
}
<li class="side-nav-item flex" [ngClass]="{'active': isShowingReviewAndSubmit, 'disabled': !(isJobDetailsValid && isStructurePhotosValid)}" (click)="handleNavigation(['review'])" (mouseover)="enterLastItem()" (mouseleave)="leaveLastItem()"><a [routerLink]="" class="router-link" (click)="handleNavigation(['review'])" (mouseover)="enterLastItem()" (mouseleave)="leaveLastItem()">Review and Submit</a>@if (isFlushRequestValid) {
<span class="icon icon-check-circle"></span>
}</li>
</ul>
</div>
