import { Component, OnInit, OnDestroy, HostListener, inject } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BaseComponent } from '../base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { trigger, transition, style, animate, group, animateChild, query } from '@angular/animations';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { Observable, pipe } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { BaseService } from 'src/app/services/base/base.service';
import { filter, take, tap } from 'rxjs/operators';
import { UserInfo } from 'src/app/interfaces/user-info';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  animations: [
    trigger('subItems', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
    trigger('mobileSubItems', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('250ms ease-in', style({ transform: 'translateY(0)' })),
        group([query('@logoAnimation', animateChild()), query('@options', animateChild())]),
      ]),
      transition(':leave', [
        group([query('@logoAnimation', animateChild()), query('@options', animateChild())]),
        animate('200ms 100ms cubic-bezier(.26,.94,.59,1.08)', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
    trigger('logoAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms 200ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms 100ms ease', style({ opacity: 0 }))]),
    ]),
    trigger('options', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms 250ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms 50ms ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private userInfo = inject(BaseService);

  userName = null;
  buttonText: string;
  route: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
      )
      .subscribe((e: ActivationEnd) => {
        this.route = e.snapshot.routeConfig.path;
      });
  }
  role: string;
  crewCode: string;
  abbreviateNav = true;
  isSubNavVisible = false;
  showCrewCodeOption = false;
  email: string;
  @Select(AppState.getCrewCode) getCrewCode$: Observable<string>;
  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(window.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.abbreviateNav = innerWidth >= 1200 ? false : true;
  }

  ngOnInit() {
    this.adjustToScreenSize(window.innerWidth);
    this.flushSubscriptions.push(
      this.getUserInfo$
        .subscribe(userInfo => {
          try {
            const { user, workAssignments } = userInfo;
            this.role = user.flushRoleType;
            this.buttonText = this.getHomeButtonText(user.flushRoleType);
            this.email = user.email;
            if (this.email?.includes('@coned.com')) {
              this.showCrewCodeOption = false;
            } else {
              this.showCrewCodeOption = true;
            }
            this.userName = user.name;
          } catch {
            this.role = '';
            this.email = '';
            this.showCrewCodeOption = false;
            this.userName = '';
          }
        }),
      this.getCrewCode$.subscribe((crewCode) => {
        this.crewCode = crewCode;
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  miaPhotoLibraryClick() {
    let url = `https://myinspectionapplication${environment.apimEnvironment === 'prod' ? '' : environment.apimEnvironment}.coned.com/photo-library/era?autoLogin=true`;
    window.open(url, '_blank')
  }

  iconClick() {
    if (this.buttonText === 'Settings') {
      this.userInfo.controlSettingsModal(true);
    } else if (this.buttonText === 'My Requests') {
      this.router.navigateByUrl('/requests?topCrewTracker=my-requests');
    }
    else if (this.buttonText === 'My Jobs') {
      this.router.navigateByUrl('/requests?topTracker=my-jobs');
    }
  };

  handleHover() {
    this.changeValue(!this.abbreviateNav, true);
  }
  handleLeave() {
    this.changeValue(!this.abbreviateNav, false);
  }
  handleClick() {
    this.changeValue(this.abbreviateNav, true);
  }
  changeValue(checkValue: boolean, setValue: boolean) {
    if (checkValue) {
      this.isSubNavVisible = setValue;
    }
  }
  logOut() {
    this.router.navigate(['logout']);
    this.changeValue(this.abbreviateNav, false);
  }
  crewCodeClick() {
    this.router.navigate(['crew-code']);
    this.changeValue(this.abbreviateNav, false);
  }
  support() {
    try {
      this.getUserInfo$
        .pipe(take(1))
        .subscribe(userInfo => {
          const { user } = userInfo;
          let url;
          switch (user.role) {
            case 'sup':
              url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/ERA-AXIS.aspx')
              break;
            case 'eo':
              url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/tutorials.aspx')
              break;
            case 'cc':
            default:
              url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/ERA-Tutorials%282%29.aspx')
              break;
          }
          window.open(url.toString(), '_blank');
        });

    } catch {
      const url = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/Frequently-Asked-Questions.aspx')
      window.open(url.toString(), '_blank');
    }
  }
  getHomeButtonText(role: string) {
    switch (this.userInfo.confirmUserRoleByType(role)) {
      case 'cc':
        return 'My Requests';
      case 'eo':
        return 'My Jobs';
      default:
        return 'Settings';
    }
  }
}
