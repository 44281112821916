@if (isMobile) {
  <app-sidebar-mobile
    [wrNum]="isSummary ? this.dashboardSideBarDetails?.wrNo : dashboardDetailsEntity?.wrNo"
    [wcDesc]="isSummary ? this.dashboardSideBarDetails?.workComponentDescription : dashboardDetailsEntity?.workComponentDescription"
    [sourceWrNo]="''"
  isFlushRequest="true" [showFirst]="showing"></app-sidebar-mobile>
}
<div class="request-wrapper">
  <div class="request-card container-fluid card">
    @if (isShowingFlushInfo && !isEdit) {
      <app-top-tracker [trackerArr]="topTracker"></app-top-tracker>
    }
    <div class="row no-gutters">
      <div class="col" [ngStyle]="!isPhotoReview && {'min-height': '550px'}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  @if (!isMobile) {
    <app-sidebar [wrNum]="isSummary ? this.dashboardSideBarDetails?.wrNo : dashboardDetailsEntity?.wrNo"
      [wcDesc]="isSummary ? this.dashboardSideBarDetails?.workComponentDescription : dashboardDetailsEntity?.workComponentDescription"
    [bTicket]="isSummary ? this.dashboardSideBarDetails?.bTicket : dashboardDetailsEntity?.bTicket ?? ''" isFlushRequest="true" [showFirst]="showing"></app-sidebar>
  }
</div>
