<ce-header logo='ce' [title]="'Environmental Resources App'" id="main-navigation">
  @if (role != 'CN') {
    <ce-header-menu label="Photo Library" (clickEvent)="miaPhotoLibraryClick()">
    </ce-header-menu>
  }
  @if (!!userName) {
    <ce-header-menu [label]="buttonText" (clickEvent)="iconClick()">
    </ce-header-menu>
  }
  @if (!!userName) {
    <ce-header-menu-standout [label]="userName" iconName="icon-logged-in">
      <ce-header-menu-item label="Need Help?" (clickEvent)="support()">
      </ce-header-menu-item>
      @if (showCrewCodeOption) {
        <ce-header-menu-item label="Crew Code" (clickEvent)="crewCodeClick()">
        </ce-header-menu-item>
      }
      <ce-header-menu-item label="Log Out" (clickEvent)="logOut()">
      </ce-header-menu-item>
    </ce-header-menu-standout>
  }
</ce-header>