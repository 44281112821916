import { Component, OnInit, inject } from '@angular/core';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service'
import { CarouselModel } from 'src/app/common/carousel/carousel.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ContractorAuthService } from 'src/app/services/auth/contractor-auth.service';
import { BaseService } from 'src/app/services/base/base.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BannerService } from 'src/app/services/banner/banner.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EventType } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { UserInfo } from 'src/app/interfaces/user-info';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {
  private msalBroadcastService = inject(MsalBroadcastService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private employeeauth = inject(MsalService);
  private contractorAuthService = inject(ContractorAuthService);
  private authService = inject(AuthService);
  private baseService = inject(BaseService);
  private internetCheckerService = inject(InternetCheckerService);
  private banner = inject(BannerService);
  private store = inject(Store);

  crewCodeCheckPassed = true;
  isOffline = false;
  carouselItems: Array<CarouselModel> = new Array<CarouselModel>();
  banner$;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.msalBroadcastService.msalSubject$
    .pipe(
      tap(evt => {}),
      filter(event => event.eventType === EventType.LOGIN_START || event.eventType === EventType.LOGIN_SUCCESS ),
      switchMap(() => this.store.select(store => store.AppState.userInfo as UserInfo).pipe(filter((user) => !!user))),
    ).subscribe(({user}) => {
      this.router.navigate(['']); // if logging in as flush, user, force redirect to requests component
    });
    this.activatedRoute.queryParams
    .pipe(
      tap(params => {
        if (params.error != null) {
          this.crewCodeCheckPassed = false;
          this.authService.removeSessionIfCrewCodesUnassigned();
        }
      })
    )
    .subscribe((params) => {
      if (this.crewCodeCheckPassed) {
        // Redirect users to specific page if already authenticated
        this.signInRedirect();
      }
    });
  }

  ngOnInit(): void {
    this.internetCheckerService.getStatus.subscribe((status) => {
      this.isOffline = !status;
    });

    this.createCarouselItems();
    this.banner$ = this.banner.banner$;

    this.baseService.signin$.next(true);
  }

  private signInRedirect() {
    if (this.employeeauth.instance.getAllAccounts()?.length > 0)  {
      this.router.navigate(['requests']);
    } else if (this.contractorAuthService.isAuthenticated()) {
      this.baseService.getContractorInfo().subscribe();
      this.router.navigate(['crew-code']);
    }
  }

  createCarouselItems() {
    const truck = new CarouselModel();
    truck.image = '../../../assets/flush_truck.jpg';

    const structure = new CarouselModel();
    structure.image = '../../../assets/flush_structure.jpg';

    const pipes = new CarouselModel();
    pipes.image = '../../../assets/flush_pipes.jpg';
    
    this.carouselItems.push(truck);
    this.carouselItems.push(structure);
    this.carouselItems.push(pipes);
  }

  employeeSignIn() {
    // use MSAL service to login users for employee signin
    this.employeeauth.instance.loginRedirect({scopes: [environment.aadScopes]})
  }

  contractorSignIn() {
    this.authService.updateSessionStatus(true);
    this.router.navigate(['', 'contractor-sign-in']);
  }
  refreshPage() {
    window.location.reload();
  }
  closeOfflineModal() {
    this.isOffline = false;
  }
  goToSharePointFAQ() {
    const baseUrl = new URL('https://consolidatededison.sharepoint.com/sites/EO-Flush/SitePages/Frequently-Asked-Questions.aspx')
    window.open(baseUrl.toString(), '_blank');
  }
}
