import { Injectable, Renderer2 } from "@angular/core";
export type DataUrl = string;
/**
 * EXIF tag standard reference
 *
 * Tag Name: Orientation
 * Tag ID: 0x0112
 * Writable: int16u
 * Group: IFD0
 * Values:
 1 = Horizontal (normal)
 2 = Mirror horizontal
 3 = Rotate 180
 4 = Mirror vertical
 5 = Mirror horizontal and rotate 270 CW
 6 = Rotate 90 CW
 7 = Mirror horizontal and rotate 90 CW
 8 = Rotate 270 CW
 */
export declare enum DOC_ORIENTATION {
  Up = 1,
  Down = 3,
  Right = 6,
  Left = 8,
  UpMirrored = 2,
  DownMirrored = 4,
  LeftMirrored = 5,
  RightMirrored = 7,
  Default = 0,
  NotJpeg = -1,
  NotDefined = -2,
}

@Injectable({
  providedIn: "root",
})
export class ImageCompressService {
  constructor() {}

  byteCount = (imgString: DataUrl): number =>
    encodeURI(imgString).split(/%..|./).length - 1;

  compressImage(
    imageDataUrlSource: DataUrl,
    orientation: DOC_ORIENTATION,
    render: Renderer2,
    ratio: number = 50,
    quality: number = 50,
    maxwidth: number = 0,
    maxheight: number = 0
  ): Promise<DataUrl> {
    return new Promise((resolve, reject) => {
      quality = quality / 100;
      ratio = ratio / 100;
      const sourceImage = new Image();

      // important for safari: we need to wait for onload event
      sourceImage.onload = () => {
        const canvas: HTMLCanvasElement = render.createElement("canvas");
        const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

        if (!ctx) {
          return reject(`No canvas context available`);
        }

        let w = sourceImage.naturalWidth;
        let h = sourceImage.naturalHeight;

        if (!CSS.supports("image-orientation", "from-image")) {
          if (
            orientation === DOC_ORIENTATION.Right ||
            orientation === DOC_ORIENTATION.Left
          ) {
            const t = w;
            w = h;
            h = t;
          }
        }

        let xratio = maxwidth ? maxwidth / w : 1;
        let yratio = maxheight ? maxheight / h : 1;
        ratio = Math.min(ratio, xratio, yratio);
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        const TO_RADIANS = Math.PI / 180;

        if (
          CSS.supports("image-orientation", "from-image") ||
          orientation === DOC_ORIENTATION.Up
        ) {
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        } else if (orientation === DOC_ORIENTATION.Right) {
          ctx.save();
          ctx.rotate(90 * TO_RADIANS);
          ctx.translate(0, -canvas.width);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === DOC_ORIENTATION.Left) {
          ctx.save();
          ctx.rotate(-90 * TO_RADIANS);
          ctx.translate(-canvas.width, 0);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === DOC_ORIENTATION.Down) {
          ctx.save();
          ctx.rotate(180 * TO_RADIANS);
          ctx.translate(-canvas.width, -canvas.height);
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
          ctx.restore();
        } else {
          // no orientation value found - same as default UP
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        }

        // const mime = imageDataUrlSource?.split(';')[0]?.split(':')[1];
        const mime = "image/jpeg";

        const result = canvas.toDataURL(mime, quality);

        resolve(result);
      };

      sourceImage.onerror = (e) => reject(e);
      sourceImage.src = imageDataUrlSource;
    });
  }
}
