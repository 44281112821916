import { Component, ViewChild, Input, inject } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModel } from './carousel.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
})
export class CarouselComponent {
  @Input() carouselItems: Array<CarouselModel> = [];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const config = inject(NgbCarouselConfig);

    config.interval = 7000;
    config.wrap = true;
    config.keyboard = false;
  }
}
