/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import { cast as s } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { getBasemapThumbnailUrl as o } from "../support/basemapUtils.js";
import a from "./Widget.js";
import l from "./BasemapToggle/BasemapToggleViewModel.js";
import { css as r } from "./BasemapToggle/css.js";
import { loadCalciteComponents as n } from "./support/componentsUtils.js";
import { globalCss as c } from "./support/globalCss.js";
import "./support/widgetUtils.js";
import { messageBundle as p } from "./support/decorators/messageBundle.js";
import { tsx as m } from "./support/jsxFactory.js";
function d(e) {
  const t = o(e);
  return t ? {
    backgroundImage: "url(" + t + ")"
  } : {
    backgroundImage: ""
  };
}
const u = {
  title: !1
};
let g = class extends a {
  constructor(e, t) {
    super(e, t), this.messages = null, this.viewModel = new l(), this.visibleElements = {
      ...u
    }, this.toggle = () => this.viewModel.toggle();
  }
  loadDependencies() {
    return n({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js"),
      icon: () => import("@esri/calcite-components/dist/components/calcite-icon.js"),
      scrim: () => import("@esri/calcite-components/dist/components/calcite-scrim.js")
    });
  }
  get activeBasemap() {
    return this.viewModel.activeBasemap;
  }
  get icon() {
    return "layer-basemap";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get nextBasemap() {
    return this.viewModel.nextBasemap;
  }
  set nextBasemap(e) {
    this.viewModel.nextBasemap = e;
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  castVisibleElements(e) {
    return {
      ...u,
      ...e
    };
  }
  render() {
    const e = this.viewModel,
      t = "disabled" === e.state ? null : e.activeBasemap,
      s = "disabled" === e.state ? null : e.nextBasemap,
      i = "loading" === e.state,
      o = "incompatible-next-basemap" === e.state,
      a = s?.title ?? "",
      l = s && "loaded" !== s.loadStatus;
    let n;
    const p = this.visibleElements.title && a,
      u = o;
    return (p || u) && (n = m("div", {
      class: r.overlay,
      key: "overlay"
    }, p ? m("span", {
      class: r.title,
      title: a
    }, a) : null, u ? m("calcite-scrim", {
      class: r.overlayScrim,
      title: this.messages.incompatibleSpatialReference
    }, m("calcite-icon", {
      icon: "exclamation-mark-triangle"
    })) : null)), m("div", {
      class: this.classes(r.base, c.widget)
    }, m("calcite-button", {
      appearance: "transparent",
      "data-basemap-id": s ? s.id : "",
      disabled: o,
      kind: "neutral",
      label: this.label,
      onclick: () => {
        this.toggle();
      },
      title: this.label
    }, m("div", {
      class: this.classes(r.container, r.secondaryBasemapImage)
    }, m("div", {
      class: r.image,
      styles: d(t)
    })), m("div", {
      class: r.container
    }, m("div", {
      class: this.classes(r.image, l ? r.imageLoading : null),
      styles: d(s)
    }, l || i ? m("calcite-scrim", null, m("span", {
      "aria-hidden": "true",
      class: c.loaderAnimation,
      role: "presentation"
    })) : null), n)));
  }
};
e([t({
  readOnly: !0
})], g.prototype, "activeBasemap", null), e([t()], g.prototype, "icon", null), e([t()], g.prototype, "label", null), e([t(), p("esri/widgets/BasemapToggle/t9n/BasemapToggle")], g.prototype, "messages", void 0), e([t()], g.prototype, "nextBasemap", null), e([t()], g.prototype, "view", null), e([t({
  type: l
})], g.prototype, "viewModel", void 0), e([t()], g.prototype, "visibleElements", void 0), e([s("visibleElements")], g.prototype, "castVisibleElements", null), g = e([i("esri.widgets.BasemapToggle")], g);
const v = g;
export { v as default };