import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { LoaderService } from '../services/loader/loader.service';
import { LoggingService } from '../services/logging/logging.service';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngxs/store';
import { AddUserInfo } from '../app-state/actions/user-info.actions';
import { UserInfo } from '../interfaces/user-info';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private loaderService = inject(LoaderService);
  private logger = inject(LoggingService);
  private authService = inject(AuthService);
  private store = inject(Store);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const blacklisted = request.url.includes('ce-common-video-service') || request.url.includes('arcgis')
    const url = request.url.split('/');
    const offline = !window.navigator.onLine && !url.includes('environment.json');
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;
    // get timestamp
    const startTimestamp = new Date().getTime();

    // enable the loader if online
    if (!offline && !(blacklisted && request.method === 'GET')) {
      this.loaderService.addRequest();
    }
    // Set the loader to false
    return next.handle(request).pipe(
      tap((evt) => {
        lastResponse = evt;
        if (evt instanceof HttpResponse) {
           // End timestamp
           const endTimestamp: number = new Date().getTime();
           // get the difference
           const responseTimes = endTimestamp - startTimestamp;
           this.logger.logTrace(request.url.replace(environment.flushApiBaseUrl, '') + " responseTimes(sec):" + (responseTimes/1000).toLocaleString(), {
            requesturl : request.url,
            requestBody: request.body,
            requestMethod: request.method,
            requestHeaders: request.headers
           });
          this.loaderService.completeRequest();
        }
      }),
      catchError((err: any) => {
        error = err;
        this.loaderService.completeRequest();
        if (err.status === 401) {
          // ID Token no longer valid, even if Access Token is valid
          const userInfo = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo);
          this.store.dispatch(new AddUserInfo({...userInfo, user: {...userInfo.user, isLoggedin: false}}));
        }
        return throwError(err);
      }),
      finalize(() => {
        if (!this.authService.isAuthenticated()) {
          // No longer a valid session, must sign back into app
          this.authService.updateSessionStatus(false);
        }
        if (lastResponse.type === HttpEventType.Sent && !error) {
          this.loaderService.completeRequest();
        }
      })
    );
  }
}
