import { Component, OnInit, OnChanges, HostListener, EventEmitter, Input, Output, ElementRef, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export class OptionModel {
  value: string;
  option: string;
  key: string;
}
@Component({
  selector: 'app-selectbox',
  templateUrl: './selectbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectboxComponent),
      multi: true,
    },
  ],
})
export class SelectboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  private _eref = inject(ElementRef);

  @Input() placeholder = 'Selection';
  @Input() options: Array<OptionModel>;
  @Input() defaultIndex: number;
  @Input() className: string;
  @Input() isReadOnly = false;
  //Error indicator
  @Input() isValid = true;
  @Input() errorMsg = '';
  @Input() isCompleted = false;
  // Default to error icon
  @Input() icon = '';

  // Additional variables
  openDropdown = false;
  selectedValue = '';
  selectedOption = '';
  selectedIndex = 0;
  arrowsBtnPressed = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {}

  // In order to connect this to a form, we need to implement ControlValueAccessor

  // Function to call when the value of the input changes
  onChange(value: string) {

  }
  // Function to call when the input is touched (onBlur)
  onTouched() {}

  // Implementation of ControlValueAccessor
  // Allows ng to update the input
  writeValue(value: string) {
    if(this.options) {
      if (this.options.filter(opt => opt.value === value).length === 0) {
        //Reset values
        this.selectedValue = null;
        this.selectedOption = null;
        this.selectedIndex = null;
        this.defaultIndex = null;
      } else {
        const item = this.options.find(i => i.value === value);
        const idx = this.options.findIndex(i => i.value === value)
        this.selectedValue = item.value;
        this.selectedOption = item.option;
        this.selectedIndex = idx;
        this.defaultIndex = null;
      }
    }
  }
  // Allows ng to register a function to call when input changes
  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }
  // Allows ng to register a function to call on input's blur event
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.defaultIndex != null && this.defaultIndex >= 0 && this.options != null) {
      this.selectedValue = this.options[this.defaultIndex]?.value;
      this.selectedOption = this.options[this.defaultIndex]?.option;
      this.selectedIndex = this.defaultIndex;
    } else if(this.defaultIndex === -1) {
      //Reset values
      this.selectedValue = null;
      this.selectedOption = null;
      this.selectedIndex = null;
      this.defaultIndex = null;
    } else {
      this.defaultIndex == null;
    }
  }

  toggleDropDown() {
    if (!this.isReadOnly) {
      this.openDropdown = !this.openDropdown;
    }
  }

  onOptionClick(value, option, index) {
    this.selectedValue = value;
    this.selectedOption = option;
    this.selectedIndex = index;
    this.openDropdown = false;
    this.writeValue(value);
    this.onChange(value);
  }

  @HostListener('keydown', ['$event']) onkeydown($event) {
    $event.preventDefault();
    this.arrowsBtnPressed = true;
    if ($event.key === 'ArrowDown') {
      if (this.selectedIndex !== this.options.length - 1) {
        this.selectedIndex++;
      }
    }

    if ($event.key === 'ArrowUp') {
      if (this.selectedIndex !== 0) {
        this.selectedIndex--;
      }
    }

    if (this.openDropdown) {
      if ($event.key === 'Enter') {
        this.selectedValue = this.options[this.selectedIndex].value;
        this.writeValue(this.selectedValue);
        this.onChange(this.selectedValue);
        this.openDropdown = false;
        this._eref.nativeElement.blur();
      }
    }
    this.onTouched();
  }

  @HostListener('document:mousedown', ['$event']) onDocumentClick($event) {
    if (!this._eref.nativeElement.contains($event.target)) {
      this.openDropdown = false;
    }
    this.onTouched();
  }
}
