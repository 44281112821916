import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy, Input, ChangeDetectorRef, inject } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Photo } from '../../models/photo';
import { PhotoService } from '../../services/photo/photo.service';
import { CONFIG } from 'src/app/global/config';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { AppState } from 'src/app/app-state/app.state';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { BaseService } from 'src/app/services/base/base.service';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PhotoTag } from '../../models/photo-tag';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';
import { AddPausedJob, AddStartJobPhotos, DeletePausedJob } from 'src/app/app-state/actions/start-job.actions';
import { StructureInformation } from 'src/app/models/structureInformation';
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Banner } from 'src/app/services/banner/banner.service';
import { OptionModel } from 'src/app/common/selectbox/selectbox.component';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { Feature } from 'src/app/interfaces/feature';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { UserInfo } from 'src/app/interfaces/user-info';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { LoggingService } from 'src/app/services/logging/logging.service';


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent extends BaseComponent implements OnInit, OnDestroy {
  private baseService = inject(BaseService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private photoService = inject(PhotoService);
  private location = inject(Location);
  private service = inject(BaseService);
  private styler: StylerService;
  private store = inject(Store);
  private formValidationService = inject(FormValidationService);
  private internetCheckerService = inject(InternetCheckerService);
  private cdr = inject(ChangeDetectorRef);
  private masterData = inject(MasterDataService);
  private logger = inject(LoggingService);

  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Input() cameraEnabled = true;
  @Input() savedPhotos = [];
  @Input() isSummary = false;
  @Input() enableAfterPhotos = false;
  allPhotos: Photo[] = [];
  beforePhotos: Photo[] = [];
  afterPhotos: Photo[] = [];
  flushUserRole: string;
  showCamera = false;
  _showReview = false;
  set showReview(val) {
    this._showReview = val;
    this.styler.isPhotoReview.next(val);
  }
  get showReview() {
    return this._showReview;
  }
  get mediaTypes() {
    return CONFIG.MEDIA_TYPE;
  }
  showDelete = false;
  selectedPhoto: any;
  isBeforePhoto = true;
  isShowingLabelWindow = false;
  isShowingFlushInfo = false;
  isShowingPhotos = true;
  isMobile = false;
  showNavItems = false;
  sideNavHeight = 0;
  isNextDisabled = true;
  isOffline = true;
  isAfterDisabled = true;
  isFlushDetailsValid = false;
  isStructurePhotosValid = false;
  assetTagDefaultIndex = -1;
  errorBanner$: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  photoPageValidationErrorBanner$: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  structureOptions: Array<OptionModel> = [];
  photoDetails = new FormGroup({
    assetTag: new FormControl(''),
    comments: new FormControl(''),
  });
  photoDetailsSubscription: Subscription;
  @ViewChild('imageContainer') outputImage: ElementRef;
  isEnvironmentalOperations = true;
  isConstructionCrew: boolean;
  navigateBackTo = '';
  showingIndex = 0;
  willBeLastPhoto = false;
  isImageLandscape = false;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @Select(AppState.getPausedJob) getPausedJob$: Observable<boolean>;
  @Select(AppState.getFlushUserRole) getFlushUserRole$: Observable<string>;
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  isBTicket = false;
  @ViewChild('sideNav') sideNav: ElementRef;

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const styler = inject(StylerService);

    super(styler);
    this.styler = styler;

    // Subscribe to offline disable next button interaction if offline
    this.flushSubscriptions.push(
      this.internetCheckerService.getStatus.subscribe((val) => {
        if (!val) {
          this.isOffline = true;
        } else {
          this.isOffline = false;
        }
      })
    );
    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.FEATURES)
      .subscribe((res: Feature[]) => {
        res.forEach((toggleObj) => {
          if (toggleObj.feature == CONFIG.API.FEATURE_TOGGLES.PHOTO_ACCOUNTABILITY) {
            if (!toggleObj.isActive) {
              // when it is not active
              this.location.back();
            }
          }
        });
      })
      .unsubscribe();
    this.flushSubscriptions.push(
      this.formValidationService.$flushInformationValid.subscribe((isValid) => {
        this.isFlushDetailsValid = isValid;
      }),
      this.formValidationService.$flushPhotos.subscribe((isValid) => {
        this.isStructurePhotosValid = isValid;
      }),
      this.activatedRoute.queryParamMap.pipe(take(1)).subscribe(s => {
        if(s.has('navigateBackTo')) {
          this.navigateBackTo = s.get('navigateBackTo');
        }
      })
    );
  }
  ngOnInit(): void {
    this.isBTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket ? true : false;
    this.isMobile = window.innerWidth <= 768 ? true : false;
    // If eo flush member load current state for startJobPhotos into photos array
    this.getStartJobPhotos$
    .pipe(
      tap(photos => {
        const updatedPhotos = photos.map(photo => {    
          const img = _.cloneDeep(photo);      
          if(photo.mediaType === this.mediaTypes.VIDEO && photo.src){
            img.streamingUrls = photo.src.indexOf('streamingUrls') === -1 ? photo.src : JSON.parse(photo.src).streamingUrls;
            //commented for testing thumbnail testing
            img.thumbnailUrl = photo.src.indexOf('streamingUrls') === -1 ? photo.thumbnailUrl : JSON.parse(photo.src).thumbnailUrl;
          } else if (photo.src === undefined) {
            img.src = '';            
          }
          return img;
        });
        this.beforePhotos = this.photoService.sortImages(updatedPhotos.filter(p => p.status === 'Before').map(p => p));
        this.afterPhotos = this.photoService.sortImages(updatedPhotos.filter(p => p.status === 'After').map(p => p));
        this.allPhotos = updatedPhotos.map(p => p);
      }),
      take(1)
    )
    .subscribe((val) => {
      this.isAfterDisabled = !(this.beforePhotos.length > 0);
      this.checkNextDisabled();
    })
    this.getFlushUserRole$.subscribe((res) => {
      this.flushUserRole = res;
      const isEO = this.service.confirmUserRoleByType(this.flushUserRole) === 'eo' || this.service.confirmUserRoleByType(this.flushUserRole) === 'sup';
      if (isEO) {
        this.isEnvironmentalOperations = true;
        this.isConstructionCrew = false;
      } else if (this.service.confirmUserRoleByType(this.flushUserRole) === 'cc') {
        this.isEnvironmentalOperations = false;
        this.isConstructionCrew = true;
      }
    });
    this.isConstructionCrew = !this.isEnvironmentalOperations;
    this.flushSubscriptions.push(
      this.store.selectOnce(store => store.AppState.userInfo as UserInfo)
      .pipe(
        filter(info => !!info), 
        map(info => this.service.confirmUserRoleByType(info.user.flushRoleType))
      ).subscribe((res) => {
        this.flushUserRole = res;
        const isEO = this.service.confirmUserRoleByType(this.flushUserRole) === 'eo' || this.service.confirmUserRoleByType(this.flushUserRole) === 'sup';
        if (isEO) {
          this.isEnvironmentalOperations = true;
          this.isConstructionCrew = false;
          this.getPausedJob$.pipe(
            mergeMap(isPaused => iif(() => isPaused, of(true), of((this.beforePhotos.length > 0) && (this.afterPhotos.length > 0)) )),
            take(1)
          ).subscribe(isValid => {
            this.isNextDisabled = !isValid;
            this.formValidationService.setValidity(
              isValid,
              CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
              CONFIG.FORMS.PARENT_FORMS.PHOTOS
            );
          })
        } else if (this.service.confirmUserRoleByType(this.flushUserRole) === 'cc') {
          this.isEnvironmentalOperations = false;
          this.isConstructionCrew = true;
        }
      }),
    );
    const isEO = this.service.confirmUserRoleByType(this.flushUserRole) === 'eo' || this.service.confirmUserRoleByType(this.flushUserRole) === 'sup';

    this.isEnvironmentalOperations = isEO;

    //Setup error-notification banner
    this.errorBanner$.next({
      type: 'error-notification',
      show: true,
      showIcon: true,
      details: {
        header: null,
        body: ["None. You'll have a chance to add a structure during the Flush Details flow."]
      }
    });

    this.photoPageValidationErrorBanner$.next({
      type: 'error-notification',
      show: false,
      showIcon: false,
      details: {
        header: null,
        body: null
      }
    });

    //Get existing structures for selectbox
    this.getStructureInfo$.pipe(take(1)).subscribe((info) => {
        if(info?.structures) {
          this.structureOptions = [];
          info?.structures.forEach(structure => {
            let option = {
              option: structure?.structureId,
              value: structure?.structureId
            } as OptionModel;
            this.structureOptions.push(option);
          });
        }
      }
    );
    this.checkPhotoStructureError(this.allPhotos);
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }

  setImages(images: any[]) {
    let allPhotos: Photo[] = [];

    for (let i = 0; i < images.length; i++) {
      let img = {} as Photo;
      img.tags = [] as PhotoTag[];
      //img.thumbnailUrl = images[i].imageData.indexOf('streamingUrls') === -1 ? images[i].imageData : JSON.parse(images[i].imageData).thumbnailUrl;
      //img.streamingUrls = images[i].imageData.indexOf('streamingUrls') === -1 ? images[i].imageData : JSON.parse(images[i].imageData).streamingUrls;
      img.additionalComments = images[i].comments;
      img.facing = this.photoService.mapPhotoClassificationToStatus(images[i].classification);
      img.status = this.photoService.mapPhotoClassificationToStatus(images[i].classification);
      img.timestamp = new Date(images[i].timestamp);
      img.id = images[i].id;
      img.structureId = images[i].structureId;
      if (images[i].tags !== undefined) {
        for (let v = 0; v < images[i].tags.length; v++) {
          let tag = {} as PhotoTag;
          tag.labels = images[i].tags[v].labels;
          tag.h = images[i].tags[v].h;
          tag.posX = images[i].tags[v].posY;
          tag.posY = images[i].tags[v].posX;
          tag.w = images[i].tags[v].w;
          tag.y = images[i].tags[v].y;
          tag.x = images[i].tags[v].x;
          img.tags.push(tag);
        }
      }
      allPhotos = this.photoService.sortImages([...allPhotos, _.cloneDeep(img)]);
    }

    return allPhotos;
  }
  ngAfterViewInit() {
    if (this.isEnvironmentalOperations) {
      this.getPausedJob$.pipe(
        mergeMap(isPaused => iif(() => isPaused, of(true), of((this.beforePhotos.length > 0) && (this.afterPhotos.length > 0)) )),
        take(1)
      ).subscribe(isValid => {
        this.isNextDisabled = !isValid;
        this.formValidationService.setValidity(
          isValid,
          CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
          CONFIG.FORMS.PARENT_FORMS.PHOTOS
        );
      })
    } else if (this.isConstructionCrew) {
      this.formValidationService.setValidity(
        this.beforePhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show,
        CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
        CONFIG.FORMS.PARENT_FORMS.PHOTOS
      );
      this.isNextDisabled = !(this.beforePhotos.length > 0);
    }
    this.photoDetails.valueChanges
    .pipe(
      map(details => {
        if (this.structureOptions.length === 1) {
          return {
            comments: details.comments ?? '',
            assetTag: this.structureOptions[0].value
          }
        }
        return {
          comments: details.comments ?? '',
          assetTag: this.photoDetails.get('assetTag').value ?? ''
        }
      })
    )
    .subscribe((val) => {
      this.emitPhotoDetails(Object.assign({}, val))
    });
    this.cdr.detectChanges();
  }

  // Must take all photos as parameter
  checkPhotoStructureError(photos : Photo[]) {
    // Only check for errors if it is NOT summary. This will overwrite the photo structureId
    if (!this.isSummary) {
      let structureIds = new Array<string>();
      this.getStructureInfo$
      .pipe(
        take(1)
      )
      .subscribe((info) => {
          if(info?.structures) {
            for(let structure of info?.structures) {
              structureIds.push(structure.structureId);
            }
          }
        }
      );
      if (structureIds.length === 1) {
        // If one structure is coming into our state, then the entire photos array should have the same value for structureId
        this.allPhotos = photos.map(p => {
          // Make sure that state has valid assetTag tied for each photo
          return {...p, structureId: structureIds[0] }
        });
        this.beforePhotos = this.beforePhotos.map(p => {
          // Make sure that state has valid assetTag tied for each photo
          return {...p, structureId: structureIds[0] }
        });
        this.afterPhotos = this.afterPhotos.map(p => {
          // Make sure that state has valid assetTag tied for each photo
          return {...p, structureId: structureIds[0] }
        });
        this.store.dispatch(new AddStartJobPhotos(_.cloneDeep(this.allPhotos)));
      } else {
        for(let photo of photos) {
          if(!photo.structureId && !this.isBTicket) {
            // Setup error-notification banner for no structureId on photo
            this.photoPageValidationErrorBanner$.next({
              type: 'error-notification',
              show: true,
              showIcon: true,
              details: {
                header: null,
                body: ["Your photos are not associated to a structure. Please review and make necessary adjustments."]
              }
            });
            break;
          } else if(structureIds.findIndex(id => { return id == photo.structureId }) < 0 && !this.isBTicket) {
            //Setup error-notification banner for no existing structureId in structures on photo
            this.photoPageValidationErrorBanner$.next({
              type: 'error-notification',
              show: true,
              showIcon: true,
              details: {
                header: null,
                body: ["One of your photos has a structure that no longer exists. Please review and make necessary adjustments."]
              }
            });
            break;
          } else {
            this.photoPageValidationErrorBanner$.next({
              type: 'error-notification',
              show: false,
              showIcon: false,
              details: {
                header: null,
                body: null
              }
            });
          }
        }
        // Check if there is error as to not overwrite it
        if(!this.photoPageValidationErrorBanner$.value.show && !this.isBTicket) {
          if(!structureIds.every((structureId) => photos.find((photo) => photo.structureId == structureId))) {
            // Photos do not cover all structures
            this.photoPageValidationErrorBanner$.next({
              type: 'error-notification',
              show: true,
              showIcon: true,
              details: {
                header: null,
                body: ["Each structure needs to be associated to atleast one photo. Please review and make necessary adjustments."]
              }
            });
          } 
        }
      } 
      this.checkNextDisabled();
    }    
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.styler.isPhotoReview.next(false);
  }
  selectPhoto(selectedPhoto, add: number) {
    const p =  _.cloneDeep(selectedPhoto);
    const i = p.index + add;
    switch (p.arr) {
      case "Before":
        if (i > this.beforePhotos.length - 1 || i < 0) {
          this.showingIndex = i > this.beforePhotos.length - 1  ? -1 : 0;
          this.selectedPhoto = _.cloneDeep({
            dims: p.dims.map(d => d),
            photo: _.cloneDeep(this.beforePhotos[0]),
            index: 0,
            arr: "Before",
          })
        }
         else {
          this.showingIndex = i;
          this.willBeLastPhoto = i >= this.beforePhotos.length - 1;    
          this.selectedPhoto = _.cloneDeep({
            dims: p.dims.map(d => d),
            photo: _.cloneDeep(this.beforePhotos[i]),
            index: i,
            arr: "Before",
          })
         }
        break;
      case "After":
        if (i > this.afterPhotos.length - 1 || i < 0) {
          this.showingIndex = i > this.afterPhotos.length - 1  ? -1 : 0;
          this.selectedPhoto = _.cloneDeep({
            dims: p.dims.map(d => d),
            photo: _.cloneDeep(this.afterPhotos[0]),
            index: 0,
            arr: "After",
          })
        }
         else {
          this.showingIndex = i;
          this.willBeLastPhoto = i >= this.afterPhotos.length - 1;
          this.selectedPhoto = _.cloneDeep({
            dims: p.dims.map(d => d),
            photo: _.cloneDeep(this.afterPhotos[i]),
            index: i,
            arr: "After",
          })
         }
        break;
    }
    
    this.photoDetails.get('comments').setValue(this.selectedPhoto.photo.additionalComments, { emitEvent: false });
    if(this.structureOptions) {
      let index = this.structureOptions.length === 1 ? 0 :
        this.structureOptions.findIndex(option => { return option.value == this.selectedPhoto.photo.structureId ?? ''});
      this.assetTagDefaultIndex = index;
      
      
      this.photoDetails.get('assetTag').setValue((index > -1) ? this.selectedPhoto.photo.structureId : null, { emitEvent: false });
    }
  }
  reviewPhoto(photo, index, arr) {
    this.showingIndex = index;
    const p = new Promise(async (resolve) => {
      this.selectedPhoto = await _.cloneDeep({
        dims: [this.outputImage.nativeElement.offsetWidth, this.outputImage.nativeElement.offsetHeight],
        photo: Object.assign({}, _.cloneDeep(photo)),
        index,
        arr,
      });
      resolve('photo loaded');
    });
    p.then(() => {
      this.selectedPhoto.photo.streamingUrls = photo.streamingUrls?.indexOf('data:video') === -1 ? photo.streamingUrls : '';
      //this.selectedPhoto.photo.thumbnailUrl = photo.streamingUrls?.indexOf('data:video') === -1 ? photo.thumbnailUrl : '';

      this.photoDetails.get('comments').setValue(this.selectedPhoto.photo.additionalComments, {emitEvent: false});
      if(this.structureOptions) {
        let index = this.structureOptions.findIndex(option => { return option.value == this.selectedPhoto.photo.structureId});
        this.assetTagDefaultIndex = index;
        this.photoDetails.get('assetTag').setValue((index >= 0)? this.selectedPhoto.photo.structureId : "", {emitEvent: false});
      }
      this.showReview = true;
    });
  }
  async onPhotosAdded($event: Photo[]) {
    const photosTaken = (await _.cloneDeep($event)) as Photo[];
    this.store.dispatch(DeletePausedJob);
    await photosTaken.forEach((photo) => {
      if (photo.status === 'Before') {
        this.beforePhotos.push(_.cloneDeep(photo));
        this.isAfterDisabled = false;
        if (this.isConstructionCrew) {
          this.isNextDisabled = !(this.beforePhotos.length > 0);
        } else {
          this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
        }
      } else {
        this.afterPhotos.push(_.cloneDeep(photo));
        this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
      }
    });
    this.allPhotos = this
      .photoService.sortImages([...this.beforePhotos, ...this.afterPhotos])
      .map(p => { return { ..._.cloneDeep(p) as Photo } });
      const { user } = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo)  
      this.logger.logEvent({
        name: 'Media Component'
      }, {
        action: 'Media - Media Added',
        userName: user.name,
        userEmail: user.email,
        userRole: this.flushUserRole,
        userFlushRoleType: this.service.confirmUserRoleByType(this.flushUserRole),
        structure: 'Check media prop', // Each photo added might have a diff structure tied to it
        eventDate: new Date(),
        media: photosTaken
      });
    this.store.dispatch(new AddStartJobPhotos(this.allPhotos.map(p => p)));
    this.checkPhotoStructureError(this.allPhotos);
    if (this.isEnvironmentalOperations && this.beforePhotos.length > 0 && this.afterPhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show) {
      await this.formValidationService.setValidity(
        true,
        CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
        CONFIG.FORMS.PARENT_FORMS.PHOTOS
      );
    } else if (this.isConstructionCrew && this.beforePhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show) {
      await this.formValidationService.setValidity(
        true,
        CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
        CONFIG.FORMS.PARENT_FORMS.PHOTOS
      );
    }
  }
  emitPhotoDetails(photoDetailsObj) {
    if (this.selectedPhoto.arr === 'Before') {
      this.beforePhotos = this.beforePhotos.map((photo,index) => {
        // Map back unique copy photo, and only update the tags of photo that is currently showing on the pane
        this.selectedPhoto.photo.additionalComments = photoDetailsObj?.comments ?? '';
        this.selectedPhoto.photo.structureId = photoDetailsObj?.assetTag ?? '';
        return {
          ...photo, 
          additionalComments: index === this.selectedPhoto.index ?  photoDetailsObj?.comments ?? '' : photo.additionalComments ?? '', 
          structureId: index === this.selectedPhoto.index ? photoDetailsObj?.assetTag ?? '' : photo.structureId ?? ''
        }
      }); 
    } else {
      this.afterPhotos = this.afterPhotos.map((photo,index) => {
        // Map back unique copy photo, and only update the tags of photo that is currently showing on the pane
        this.selectedPhoto.photo.additionalComments = photoDetailsObj?.comments ?? '';
        this.selectedPhoto.photo.structureId = photoDetailsObj?.assetTag ?? '';
        return {
          ...photo, 
          additionalComments: index === this.selectedPhoto.index ?  photoDetailsObj?.comments ?? '' : photo.additionalComments ?? '', 
          structureId: index === this.selectedPhoto.index ? photoDetailsObj?.assetTag ?? '' : photo.structureId ?? ''
        }
      });
    }
  }

  updateVideoAndImageRequests(changed: Photo[]) {
    const updateVideos = changed.filter((photo) => photo.mediaType === this.mediaTypes.VIDEO);
    const updateImages = changed.filter((photo) => photo.mediaType != this.mediaTypes.VIDEO);

    let videoObservable = (updateVideos?.length > 0) ? forkJoin(updateVideos?.map((video) => this.photoService.uploadFlushVideo(this.photoService.MapToFlushPhotos([video])[0]))) : of([]);
    let imageObservable = (updateImages?.length > 0) ? this.photoService.uploadPhotos(updateImages.map(p => p)) : of([]);
    
    let forkJoined = forkJoin([imageObservable, videoObservable]).pipe(
      map(([images, videos]) => {
        return [...images, ...videos];
      })
    );
    return forkJoined;
  }

  closeReview() {
    // Find allPhotos index and remove element dispatch to state in case of navigation or other actions
    this.allPhotos = this.photoService.sortImages([...this.beforePhotos, ...this.afterPhotos]);
    this.getStartJobPhotos$.pipe(
      map(photos => {
        let changed = [];
        const hasChanged = (p1: Photo, p2: Photo) => {
          if (p1.additionalComments != p2.additionalComments) {
            return true;
          }
          if(p1.structureId != p2.structureId) {
            return true;
          }
          if (p1.tags.length !== p2.tags.length) {
            return true;
          }
          for (let j = 0; j < p1.tags.length; j++) {
            if (p1.tags[j].labels.length !== p2.tags[j].labels.length) {
              return true
            }
            // Leveraging set to push all unique values on union of p1.labels & p2.labels
            const checker = new Set([...p1.tags[j].labels.map(lbl => lbl), ...p2.tags[j].labels.map(lbl => lbl)])
            
            // In one set of combine choices, if nothing has changed, then both counts will be equal, otherwise labels have been added/removed
            for (const val of checker) {
              const l1 = p1.tags[j].labels.filter(l => l === val).length;
              const l2 = p2.tags[j].labels.filter(l => l === val).length;
              
              if (l1 !== l2) {
                return true
              }
            }
            
            // Otherwise nothing has changed
            return false;
          }
        }
        for (let i = 0; i < photos.length; i++) {
          const photo = Object.assign({}, photos[i]);
          const compare = Object.assign({}, this.allPhotos.filter(p => p.id === photo.id)[0]);
          
          if (hasChanged(photo, compare)) {
            // Push photo with new changes
            changed.push(Object.assign({}, _.cloneDeep(compare)))
          }
        }
        for (let i = 0; i < changed.length; i++) {
          changed[i].isUploaded = true;
        }
        return changed;
      }),
      concatMap(changed => iif(() => changed.length > 0, this.updateVideoAndImageRequests(changed), of([] as FlushPhoto[]))),
      tap(photos => {
        // If return is nonempty, then need to update state
        if (photos.length > 0) {
          this.store.dispatch(new AddStartJobPhotos(this.allPhotos.map(p => p)));
          this.checkPhotoStructureError(this.allPhotos);
          if (this.isEnvironmentalOperations) {
            this.formValidationService.setValidity(
              this.beforePhotos.length > 0 && this.afterPhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show,
              CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
              CONFIG.FORMS.PARENT_FORMS.PHOTOS
            );
            if (!(this.beforePhotos.length > 0 && this.afterPhotos.length > 0)) {
              this.router.navigate(['../photos'], {
                relativeTo: this.activatedRoute,
              });
            }
          } else if (this.isConstructionCrew) {
            this.formValidationService.setValidity(
              this.beforePhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show,
              CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
              CONFIG.FORMS.PARENT_FORMS.PHOTOS
            );
            if (!(this.beforePhotos.length > 0)) {
              this.router.navigate(['../photos'], {
                relativeTo: this.activatedRoute,
              });
            }
          }
        }
      })
    ).subscribe(photos => {
      // Once processing is done, clear form and close review modal
      this.photoDetails.get('assetTag').reset(null, {emitEvent: false});
      this.photoDetails.get('comments').reset(null, {emitEvent: false});
      this.showReview = false;
    })
  }

  deleteVideo(video) {
    let removePhoto = null;
    if (this.selectedPhoto.arr === 'Before') {
      const photos = this.getPhotosByStructure(this.beforePhotos, this.selectedPhoto.photo.structureId);
      this.beforePhotos = this.beforePhotos.filter((photo)=> photo.id !== this.selectedPhoto.photo.id);
      removePhoto = photos.splice(this.selectedPhoto.index, 1);
      //removePhoto = this.beforePhotos.splice(this.selectedPhoto.index, 1);
      if (this.isConstructionCrew) {
        this.isNextDisabled = !(this.beforePhotos.length > 0);
      } else {
        this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
      }
      this.isAfterDisabled = this.beforePhotos.length < 1 && this.afterPhotos.length < 1;
    } else {
      const photos = this.getPhotosByStructure(this.afterPhotos, this.selectedPhoto.photo.structureId);
      this.beforePhotos = this.afterPhotos.filter((photo)=> photo.id !== this.selectedPhoto.photo.id);
      removePhoto = photos.splice(this.selectedPhoto.index, 1);
      this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
    }
    this.photoService.deleteFlushVideo(video?.id, video?.mediaId).subscribe({
      complete: () => {
        this.setDeleteValidity();
      },
      error: (err) => {
        this.logger.logException(
          err,
          `unable to delete images due to ${JSON.stringify(err)}`
        );
        if (this.selectedPhoto.arr === 'Before') {
          this.beforePhotos.splice(this.selectedPhoto.index, 1, removePhoto);
        } else {
          this.afterPhotos.splice(this.selectedPhoto.index, 1, removePhoto);
        }
      }
    });
  }

  deletePhoto() {
    let removePhoto = null;
    if (this.selectedPhoto.arr === 'Before') {
      const photos = this.getPhotosByStructure(this.beforePhotos, this.selectedPhoto.photo.structureId);
      this.beforePhotos = this.beforePhotos.filter((photo)=> photo.id !== this.selectedPhoto.photo.id);
      removePhoto = photos.splice(this.selectedPhoto.index, 1);
      if (this.isConstructionCrew) {
        this.isNextDisabled = !(this.beforePhotos.length > 0);
      } else {
        this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
      }
      this.isAfterDisabled = this.beforePhotos.length < 1 && this.afterPhotos.length < 1;
    } else {
      const photos = this.getPhotosByStructure(this.afterPhotos, this.selectedPhoto.photo.structureId);
      this.beforePhotos = this.afterPhotos.filter((photo)=> photo.id !== this.selectedPhoto.photo.id);
      removePhoto = photos.splice(this.selectedPhoto.index, 1);
      this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0);
    }
    this.deletePhotos(removePhoto).pipe(take(1)).subscribe(
      (res) => {
        this.setDeleteValidity();
        const { user } = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo)
        
        this.logger.logEvent({
          name: 'Media Component'
        }, {
          action: 'Media - Media Deleted',
          userName: user.name,
          userEmail: user.email,
          userRole: this.flushUserRole,
          userFlushRoleType: this.service.confirmUserRoleByType(this.flushUserRole),
          structure: Object.keys(removePhoto[0]).length < 1 ? '' : removePhoto[0].structureId,
          eventDate: new Date(),
          media: removePhoto
        });
      },
      (err) => {
        this.logger.logException(
          err,
          `unable to delete images due to ${JSON.stringify(err)}`
        );
        if (this.selectedPhoto.arr === 'Before') {
          this.beforePhotos.splice(this.selectedPhoto.index, 1, removePhoto);
        } else {
          this.afterPhotos.splice(this.selectedPhoto.index, 1, removePhoto);
        }
      }
    );
  }

  setDeleteValidity() {
        // Find allPhotos index and remove element dispatch to state in case of navigation or other actions
        this.allPhotos = this.photoService.sortImages([...this.beforePhotos, ...this.afterPhotos]);
        this.store.dispatch(new AddStartJobPhotos(this.allPhotos));
        this.checkPhotoStructureError(this.allPhotos);
        if (this.isEnvironmentalOperations) {
          this.formValidationService.setValidity(
            this.beforePhotos.length > 0 && this.afterPhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show,
            CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
            CONFIG.FORMS.PARENT_FORMS.PHOTOS
          );
          if (!(this.beforePhotos.length > 0 && this.afterPhotos.length > 0)) {
            this.router.navigate(['../photos'], {
              relativeTo: this.activatedRoute,
            });
          }
        } else if (this.isConstructionCrew) {
          this.formValidationService.setValidity(
            this.beforePhotos.length > 0 && !this.photoPageValidationErrorBanner$?.value?.show,
            CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
            CONFIG.FORMS.PARENT_FORMS.PHOTOS
          );
          if (!(this.beforePhotos.length > 0)) {
            this.router.navigate(['../photos'], {
              relativeTo: this.activatedRoute,
            });
          }
        }
        // Must include emit event false, otherwise it will perform side effects, clearing photo comment and asset tag
        this.photoDetails.reset({}, { emitEvent: false });
        this.showReview = false;
        }

  deletePhotos(photo: Photo[]) {
    return this.photoService.deletePhotos(photo)
  }
  handleNewLabelSelections(labels: PhotoTag[]) {
    if (this.selectedPhoto.arr === 'Before') {
      this.beforePhotos[this.selectedPhoto.index] = {
        ...this.beforePhotos[this.selectedPhoto.index],
        structureId: this.structureOptions.length === 1 ? this.structureOptions[0].value : this.beforePhotos[this.selectedPhoto.index].structureId ?? '',
        tags: _.cloneDeep(labels),
      };
    } else {
      this.afterPhotos[this.selectedPhoto.index] = {
        ...this.afterPhotos[this.selectedPhoto.index],
        structureId: this.structureOptions.length === 1 ? this.structureOptions[0].value : this.afterPhotos[this.selectedPhoto.index].structureId ?? '',
        tags: _.cloneDeep(labels),
      };
    }
    this.selectedPhoto.photo.tags = _.cloneDeep(labels);

    // Find allPhotos index and remove element dispatch to state in case of navigation or other actions
    this.allPhotos = this.photoService.sortImages([...this.beforePhotos, ...this.afterPhotos]);
  }

  navigateToFlushInfo() {
    if (this.router.url.split('/').some((url) => url === 'start-job')) {
      this.router.navigate(['', 'start-job', 'review']);
    } else if (this.navigateBackTo?.length > 0) {
      this.router.navigate([`../${this.navigateBackTo}`], {
        relativeTo: this.activatedRoute
      })
    } else {
      if (this.isFlushDetailsValid && this.isStructurePhotosValid) {
        this.router.navigate(['', 'flush-information', 'review']);
      } else {
        const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket
        this.router.navigate(['', 'flush-information', !!bTicket && bTicket.length > 4 ?  'job-environment' : 'job-details']);
      }
    }
  }

  IDForAppInfo() {
    if (this.router.url.split('/').some((url) => url === 'start-job')) {
      return 'FMPhotosPage';
    } else {
      return 'CCPhotosPage';
    }
  }

  handleMobileNav() {
    this.showNavItems = !this.showNavItems;
  }

  handleNavigation(route) {
    window.scrollTo(0, 0);
    this.router.navigate(route);
    this.showNavItems = false;
  }
  checkBeforePhotos() {
    if (this.beforePhotos.length > 0 && this.afterPhotos.length < 10) {
      this.showCamera = true;
      this.isBeforePhoto = false;
      this.styler.isPhotoReview.next(true);
    }
  }
  checkIfCanAddPhoto() {
    if (this.beforePhotos.length < 10) {
      this.showCamera = true;
      this.isBeforePhoto = true;
      this.styler.isPhotoReview.next(true);
    }
  }
  disableShutter() {
    if (this.isBeforePhoto) {
      return this.beforePhotos.length >= 10;
    } else {
      return this.afterPhotos.length >= 10;
    }
  }

  refreshPage() {
    window.location.reload();
  }

  checkNextDisabled() {
    if (this.isEnvironmentalOperations) {
      this.isNextDisabled = !(this.beforePhotos.length > 0 && this.afterPhotos.length > 0) || this.photoPageValidationErrorBanner$?.value?.show;
    } else {
      this.isNextDisabled = !(this.beforePhotos.length > 0) || this.photoPageValidationErrorBanner$?.value?.show;
    }
    this.formValidationService.setValidity(
      !this.isNextDisabled,
      CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
      CONFIG.FORMS.PARENT_FORMS.PHOTOS
    );
  }

  goToIncompleteReview() {
    // Delete uploaded photos
    this.photoService
    .deletePhotos(this.afterPhotos)
    .pipe(
      catchError(err => throwError(err)),
      switchMap(deleted => this.photoService.uploadPhotos([{
        id: null,
        src: '',
        isUploaded: false,
        structureId: (this.structureOptions) ? this.structureOptions[0]?.value ?? '' : '',
        tags: [],
        additionalComments:'',
        timestamp: moment()
      } as Photo])),
      tap(uploaded => {
        this.formValidationService.setValidity(
          true,
          CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
          CONFIG.FORMS.PARENT_FORMS.PHOTOS
        );
        this.store.dispatch(new AddStartJobPhotos(this.beforePhotos));
        this.store.dispatch(new AddPausedJob(true))
      })
    ).subscribe(deleted => {
      this.router.navigate(['', 'start-job', 'review']);
    },
    err => {
      this.logger.logException(err);
    })
  }

  isDisabledNext(page) {
    if(page === 'CCPhotosPage') {
      if(this.isBTicket) {
        return false;
      }
      else {
        return this.isNextDisabled || this.isOffline || this.photoPageValidationErrorBanner$?.value?.show;
      } 
    } else {
      return this.isNextDisabled || this.photoPageValidationErrorBanner$?.value?.show;
    }
  }

  getPhotosByStructure(photos, structureId) {
    return photos.filter((photo)=> photo.structureId === structureId);
  }
}
