import { Component, OnInit, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-links-directory',
  templateUrl: './links-directory.component.html',
  styleUrls: ['./links-directory.component.scss'],
})
export class LinksDirectoryComponent implements OnInit {
  private httpClient = inject(HttpClient);

  links: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {}

  ngOnInit(): void {
    this.httpClient.get<any>('/assets/links.json').subscribe((data) => {
      this.links = data.links;
    });
  }
}
