<div class="photos" [ngClass]="{'camera': showCamera}" [ngStyle]="showReview && {'display': 'none'}">
  @if (cameraEnabled) {
    <div class="row">
      <div class="col-9">
        <h3>Media</h3>
      </div>
      <div class="col-3" style="place-content: end; display: flex;">
        <i class="request-refresh icon icon-refresh" (click)="refreshPage();"></i>
      </div>
    </div>
  }
  @if (!isSummary) {
    <app-banner [(banner)]="photoPageValidationErrorBanner$"></app-banner>
  }
  @if (!cameraEnabled && allPhotos?.length > 0) {
    <p>Tap or click on thumbnails to view media and details.</p>
  }
  @if (cameraEnabled) {
    <p>
      Please add or edit media of the structure. This media will help identify the effort and type of environmental work to be
      performed. Please take media from above the structure and the surrounding area including parking, special street access
      for Environmental Operations, as well as inside the structure if it can be entered safely. You may upload up to 10
      photos.
      <br>
        <br>
          Videos can take up to two minutes to process. You can submit the request even if the videos aren’t processed.
          <br>
            <a (click)="refreshPage();" class="pageRefreshLink">Refresh this page</a> to see the processing status.
            <br>
            </p>
          }
          @if (isEnvironmentalOperations || enableAfterPhotos) {
            <p><b>Before flush is performed</b></p>
          }
          <div class="form-group row beforeflush">
            @for (structrue of structureOptions; track structrue; let j = $index) {
              <div class="col-12" style="border-bottom: 1px solid #979797;">
                <p style="padding-left: 10px;"><b>Structure: </b>{{structrue.value}}</p>
                @for (photo of getPhotosByStructure(beforePhotos, structrue.value); track photo; let i = $index) {
                  <div class="col-6 col-lg-3 col-xl-2">
                    <div class="row">
                      <div class="col">
                        <div class="photo">
                          @if (photo?.src?.includes('stlrsdeveusitpdfacflush') || photo?.mediaType != mediaTypes.VIDEO) {
                            <img class="img" [src]="photo.src" alt="image" (click)="reviewPhoto(photo,i,'Before')">
                          }
                          <div class="img-caption" (click)="reviewPhoto(photo,i,'before')"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="body2 img-caption-1" style="font-weight: bold;">{{photo.structureId}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="body2 img-caption-2">{{photo.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="body2 img-caption-2"><span class="icon icon-tag"></span>{{ photo | labelsCount }}</p>
                      </div>
                    </div>
                  </div>
                }
                @if (cameraEnabled) {
                  <div class="col-6 col-lg-3 col-xl-2">
                    <div [ngClass]="beforePhotos?.length > 9 ? 'take-photo disabled-border':'take-photo'" (click)="checkIfCanAddPhoto()">
                      <span [ngClass]="beforePhotos?.length > 9 ? 'icon icon-camera-photo disabled':'icon icon-camera-photo'"></span>
                      <p [ngClass]="beforePhotos?.length > 9 ? 'take-photo-text disabled':'take-photo-text'">Capture Media</p>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="body2 img-caption-1" style="font-weight: bold;">{{structrue.value}}</p>
                      </div>
                    </div>
                  </div>
                }
                @if (!cameraEnabled && beforePhotos?.length < 1) {
                  <div class="col-6 col-lg-3 col-xl-2">
                    <div class="take-photo">
                      <span class="icon icon-question-circle"></span>
                      <p class="take-photo-text">No Photos</p>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          <div class="camera-wrapper" [ngClass]="{'camera': showCamera}">
            <!-- Bypass eo user role if afterphotos are enabled -->
            @if (isEnvironmentalOperations || enableAfterPhotos) {
              <div >
                <p><b>After flush is performed</b></p>
                <div class="form-group row afterflush">
                  @for (photo of afterPhotos; track photo; let j = $index) {
                    <div class="col-6 col-lg-3 col-xl-2">
                      <div class="row">
                        <div class="col">
                          <div class="photo">
                            @if (photo?.src?.includes('stlrsdeveusitpdfacflush') || photo?.mediaType != mediaTypes.VIDEO) {
                              <img class="img" [src]="photo.src" alt="image" (click)="reviewPhoto(photo,j,'After')">
                            }
                            <div class="img-caption" (click)="reviewPhoto(photo,j,'After')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p class="body2 img-caption-1" style="font-weight: bold;">{{photo.structureId}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p class="body2 img-caption-2">{{photo.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p class="body2 img-caption-2"><span class="icon icon-tag"></span>{{ photo | labelsCount }}</p>
                        </div>
                      </div>
                    </div>
                  }
                  @if (cameraEnabled) {
                    <div class="col-6 col-lg-3 col-xl-2">
                    <div [ngClass]="
                    {
                        'take-photo disabled-border': isAfterDisabled || afterPhotos?.length > 9 , 
                        'take-photo': !isAfterDisabled || afterPhotos?.length < 10 
                    }" (click)="checkBeforePhotos()">
            <span [ngClass]="{
                            'icon icon-camera-photo disabled': isAfterDisabled || afterPhotos?.length > 9 , 
                            'icon icon-camera-photo': !isAfterDisabled || afterPhotos?.length < 10 
                        }"></span>
            <p [ngClass]="
                        {
                            'take-photo-text disabled': isAfterDisabled || afterPhotos?.length > 9 , 
                            'take-photo-text': !isAfterDisabled || afterPhotos?.length < 10 
                        }">Capture Media</p>
                      </div>
                    </div>
                  }
                  @if (!cameraEnabled && afterPhotos?.length < 1) {
                    <div class="col-6 col-lg-3 col-xl-2">
                      <div class="take-photo">
                        <span class="icon icon-question-circle"></span>
                        <p class="take-photo-text">No Photos</p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
            @if (showCamera) {
              <app-camera (emitPhotosAdded)="onPhotosAdded($event)" (emitCloseCamera)="showCamera = false;"
                photoFacing="Above Structure" [photoBefore]="isBeforePhoto" [capacityReached]="disableShutter()">
              </app-camera>
            }
            @if (cameraEnabled && flushUserRole != 'sup') {
              <div class="form-group row">
                @if (isEnvironmentalOperations || enableAfterPhotos) {
                  <div class="col-12 col-md-6 col-lg-4">
                    <ce-button size='large' type="secondary" label="Unable To Complete"
                    (clickEvent)="goToIncompleteReview()"></ce-button>
                  </div>
                }
                @if (IDForAppInfo() == 'FMPhotosPage') {
                  <div class="col-12 col-md-3 col-lg-1">
                    <ce-button size='large' [label]="navigateBackTo?.length > 0 ? 'Go back' : 'Next' " data-custom-id="FMPhotosPage"
                      data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="navigateToFlushInfo()"
                    [disabled]="isDisabledNext('FMPhotosPage')"></ce-button>
                  </div>
                }
                @if (IDForAppInfo() == 'CCPhotosPage') {
                  <div class="col-12 col-md-3 col-lg-1">
                    <ce-button size='large' [label]="navigateBackTo?.length > 0 ? 'Go back' : 'Next' " data-custom-id="CCPhotosPage"
                      data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="navigateToFlushInfo()"
                    [disabled]="isDisabledNext('CCPhotosPage')"></ce-button>
                  </div>
                }
              </div>
            }
            @if (cameraEnabled && flushUserRole != 'sup') {
              <div class="form-group row">
                <div class="col-12 col-lg-4">
                  <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
                </div>
              </div>
            }
          </div>
        </div>
        <div class="photos" [ngClass]="{'camera': showCamera}" [ngStyle]="!showReview && { 'display':'none'}">
          <div class="large-card-wrapper" [ngStyle]="{'z-index': showReview ? 10000 : -1}">
            <div class="large-card" [ngClass]="{'unhide': showReview}">
              <div #imageContainer class="large-card-header">
                <app-photo [image]="selectedPhoto ? selectedPhoto.photo : null"
                  [inputPhoto]="selectedPhoto ? selectedPhoto : {}" [taggingEnabled]="!isSummary"
          (labelsAdded)="handleNewLabelSelections($event)" [ngStyle]="{
                    position: 'absolute', 
                    top: '0', 
                    bottom: '0', 
                    left: '0', 
                    right: '0',
                    background: '#2a2a2a'
                }"></app-photo>
                @if (showingIndex - 1 > -1) {
                  <div class="carousel-left-btn" (click)="selectPhoto(selectedPhoto,-1)">
                    <span>&#8249;</span></div>
                  }
                  @if (selectedPhoto?.index < (selectedPhoto?.arr === 'Before' ? beforePhotos?.length - 1 : afterPhotos?.length - 1)) {
                    <div class="carousel-right-btn"
                      (click)="selectPhoto(selectedPhoto, 1)"><span>&#8250;</span></div>
                    }
                    <div class="close-card">
                      <span class="just-icon icon-close" (click)="closeReview()"></span>
                    </div>
                  </div>
                  <div class="large-card-body">
                    <form [formGroup]="photoDetails">
                      <h4 class="mb-2">Media Details</h4>
                      @if (structureOptions?.length < 1 && !isSummary) {
                        <app-banner [(banner)]="errorBanner$"></app-banner>
                      }
                      @if (structureOptions?.length === 1 && !isSummary) {
                        <p class="mb-0 border">
                          <strong>{{structureOptions[0].value}}</strong></p>
                        }
                        @if (isSummary) {
                          <p class="mb-0 border"><strong>{{ selectedPhoto?.photo?.structureId }}</strong></p>
                        }
                        @if (structureOptions?.length > 1) {
                          <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-8 col-12">
                              @if (!isSummary) {
                                <app-selectbox formControlName="assetTag" placeholder="Asset Tag"
                                [defaultIndex]="assetTagDefaultIndex" [options]="structureOptions"></app-selectbox>
                              }
                            </div>
                          </div>
                        }
                        <p class="border">{{selectedPhoto?.photo.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
                        <p><b>Labels</b></p>
                        @for (tag of selectedPhoto?.photo.tags; track tag; let i = $index) {
                          <div class="form-group no-gutters row light-border"
                            >
                            <div class="col-2 col-lg-1">
                              <div class="label-header">
                                <p>{{i + 1}}</p>
                              </div>
                            </div>
                            <div class="col-10 col-lg-11 badges">
                              @for (label of tag.labels; track label) {
                                <span class="badge-solid">{{label}}</span>
                              }
                            </div>
                          </div>
                        }
                        <p><b>Additional comments</b></p>
                        @if (isSummary) {
                          <div class="form-group row border">
                            <div class="col-12">
                              <p>{{selectedPhoto?.photo?.additionalComments}}</p>
                            </div>
                          </div>
                        }
                        @if (!isSummary) {
                          <div class="form-group row border">
                            <div class="col-12">
                              <ce-input inputType="textarea" class="photo-comments" formControlName="comments" name="comments"
                              id="comments"></ce-input>
                            </div>
                          </div>
                        }
                        @if (!isSummary) {
                          <div class="form-group row">
                            <div class="col-12 col-lg-4">
                              <a class='btn-danger-link' (click)="showDelete = true;">
                                Delete Media
                              </a>
                            </div>
                          </div>
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <app-modal [showModal]="showDelete" (closeDialogClick)="showDelete = false"
                modalHeading="Are You Sure You Want to Delete This Photo?" [showClose]="false">
                <div class="container">
                  <div class="row">
                    <div class="col mb-5">
                      <p>Once you delete this media, the action cannot be undone.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto style-guide-close-modal-btn">
                      <a class='btn-danger-link' (click)="deletePhoto(); showDelete = false;">
                        Delete Photo
                      </a>
                    </div>
                    <div class="col-auto">
                      <ce-button size='large' label="Cancel" (clickEvent)="showDelete = false;"></ce-button>
                    </div>
                  </div>
                </div>
              </app-modal>