<div class="row top-tracker-row" [ngClass]="{ 'top-tracker-row-dashboard': isDashboard }">
  @for (tracker of trackerArr; track tracker; let i = $index) {
<div [ngClass]="{
    'top-tracker-inactive' : !trackerArr[i].isActive,
    'top-tracker-active' : trackerArr[i].isActive,
    'col-auto' : isDashboard,
    'dashboard-col' : isDashboard,
    'col' : !isDashboard
  }" (click)=selectTopCol(i)>
      @if (!isDashboard) {
        <label class="body2"><b>{{i+1}}. </b></label>
      }
      @if (!isDashboard) {
        <label class="body2 tracker-text"><b>{{tracker.text}}</b></label>
      }
      @if (isDashboard) {
        <h4 class="dashboard-tracker">{{tracker.text}}</h4>
      }
    </div>
  }
</div>
